/**
 * Created by osirvent on 14/07/2016.
 */
angular.module('annexaApp')
    .directive('expirationBox', ['$compile', 'TramFactory', function ($compile, TramFactory) {
        var getTemplate = function (definition) {
            var template = '';

            var col1 = 'col-sm-6';
            var col2 = 'col-sm-6';

            if (definition.type == 'transaction') {
                col1 = 'col-sm-4';
                col2 = 'col-sm-8';
            }

            var classStart = 'expiration-box-' + definition.type + '-';

            if (definition.type == 'dossier') {
                if (definition.days < 0) {
                    template += '   <div class="expiration-box-box text-white eb-box1 danger">';
                } else if (definition.days < 5) {
                    template += '   <div class="expiration-box-box text-white eb-box1 amber">';
                } else {
                    template += '   <div class="expiration-box-box text-white eb-box1 primary">';
                }
                
                if (definition.days < 0) {
                	if(definition.expiretype == 'BUSINESS'){
                		template += '       <span class=" eb-number">{{days * -1}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.daysexpired\' | translate}}</span>';
                	} else if(definition.expiretype == 'NATURAL'){
                		template += '       <span class=" eb-number">{{days * -1}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.naturaldaysexpired\' | translate}}</span>';
                	} else if(definition.expiretype == 'MONTHS'){
                		var months;
                        var d1 = new Date();
                        var d2 = new Date(definition.date);
                        months = (d2.getFullYear() - d1.getFullYear()) * 12;
                        months -= d1.getMonth();
                        months += d2.getMonth();
                        months <= 0 ? 0 : months;
                        definition.months = months;
                        template += '       <div><span class=" eb-number">{{months * -1}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.monthsexpired\' | translate}}</span></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else if(definition.expiretype == 'YEARS'){
                		var date1 = new Date();
                        var date2 = new Date(definition.date);
                        definition.yearsDiff =  date2.getFullYear() - date1.getFullYear();
                        template += '       <div><span class=" eb-number">{{yearsDiff * -1}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.yearsexpired\' | translate}}</span></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else {
                		template += '       <span class=" eb-number">{{days * -1}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.daysexpired\' | translate}}</span>';
                	}
                } else {
                	if(definition.expiretype == 'BUSINESS'){
                		template += '       <span class=" eb-number">{{days}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.daysexpire\' | translate}}</span>';
                	} else if(definition.expiretype == 'NATURAL'){
                		template += '       <span class=" eb-number">{{days}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.naturaldaysexpire\' | translate}}</span>';
                	} else if(definition.expiretype == 'MONTHS'){
                		var months;
                        var d1 = new Date();
                        var d2 = new Date(definition.date);
                        months = (d2.getFullYear() - d1.getFullYear()) * 12;
                        months -= d1.getMonth();
                        months += d2.getMonth();
                        months <= 0 ? 0 : months;
                        definition.months = months;
                        template += '       <div><span class=" eb-number">{{months}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.monthsexpire\' | translate}}</span></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else if(definition.expiretype == 'YEARS'){
                		var date1 = new Date();
                        var date2 = new Date(definition.date);
                        definition.yearsDiff =  date2.getFullYear() - date1.getFullYear();
                        template += '       <div><span class=" eb-number">{{yearsDiff}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.yearsexpire\' | translate}}</span></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else {
                		template += '       <span class=" eb-number">{{days}}</span>';
                        template += '       <span class=" eb-days">{{ \'global.tram.literals.daysexpire\' | translate}}</span>';
                	}
                }  
                template += '   </div>';
            } else {
            	//definition.transactionType.expireType
                if (definition.days < 0) {
                    template += '   <div class="expiration-box-box eb-box1 text-white danger" >';
                } else if (definition.days < 5) {
                    template += '   <div class="expiration-box-box eb-box1 text-white amber" >';
                } else {
                    template += '   <div class="expiration-box-box eb-box1 text-white primary" >';
                }
                
                var expiretype;
                if(definition.expiretype && definition.expiretype != null){
                	expiretype = definition.expiretype;
                } else {
                	expiretype = definition.transactionType.expireType;
                }
                
                if (definition.days < 0) {
                	if(expiretype == 'BUSINESS'){
                		template += '       <h4 class=" eb-number">{{days * -1}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.daysexpired\' | translate}}</span></h4>';
                	} else if(expiretype == 'NATURAL'){
                		template += '       <h4 class=" eb-number">{{days * -1}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.naturaldaysexpired\' | translate}}</span></h4>';
                	} else if(expiretype == 'MONTHS'){
                		var months;
                        var d1 = new Date();
                        var d2 = new Date(definition.date);
                        months = (d2.getFullYear() - d1.getFullYear()) * 12;
                        months -= d1.getMonth();
                        months += d2.getMonth();
                        months <= 0 ? 0 : months;
                        definition.months = months;
                		template += '       <div><h4 class=" eb-number">{{months * -1}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.monthsexpired\' | translate}}</span></h4></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else if(expiretype == 'YEARS'){
                		var date1 = new Date();
                        var date2 = new Date(definition.date);
                        definition.yearsDiff =  date2.getFullYear() - date1.getFullYear();
                		template += '       <div><h4 class=" eb-number">{{yearsDiff * -1}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.yearsexpired\' | translate}}</span></h4></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else {
                		template += '       <h4 class=" eb-number">{{days * -1}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.daysexpired\' | translate}}</span></h4>';
                	}
                } else {
                	if(expiretype == 'BUSINESS'){
                		template += '       <h4 class=" eb-number">{{days}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.daysexpire\' | translate}}</span></h4>';
                	} else if(expiretype == 'NATURAL'){
                		template += '       <h4 class=" eb-number">{{days}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.naturaldaysexpire\' | translate}}</span></h4>';
                	} else if(expiretype == 'MONTHS'){
                		var months;
                        var d1 = new Date();
                        var d2 = new Date(definition.date);
                        months = (d2.getFullYear() - d1.getFullYear()) * 12;
                        months -= d1.getMonth();
                        months += d2.getMonth();
                        months <= 0 ? 0 : months;
                        definition.months = months;
                        template += '       <div><h4 class=" eb-number">{{months}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.monthsexpire\' | translate}}</span></h4></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else if(expiretype == 'YEARS'){
                		var date1 = new Date();
                        var date2 = new Date(definition.date);
                        definition.yearsDiff =  date2.getFullYear() - date1.getFullYear();
                        template += '       <div><h4 class=" eb-number">{{yearsDiff}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.yearsexpire\' | translate}}</span></h4></div>';
                        template += '       <div><span class="text-xs">({{days}} {{ \'global.literals.NATURAL\' | translate}})</span></div>';
                	} else {
                		template += '       <h4 class=" eb-number">{{days}}';
                        template += '       <span class="eb-days">{{ \'global.tram.literals.daysexpire\' | translate}}</span></h4>';
                	}
                }
                
                template += '   </div>';
            }
            
            template += '   <div class="expiration-box-box eb-date eb-box2 ">';
            if (definition.type == 'dossier') {
                template += '   {{label | translate}}:';
                template += '   <br/>';
                template += '   {{date | date: \'dd/MM/yyyy\'}}';
            } else { /* it's a transaction! */
                template += '   <div class="" >';
                template += '       <div class=" m-b m-t">';
                template += '           {{label | translate}}: {{date | date: \'dd/MM/yyyy\'}}';
                template += '       </div>';
                template += '   </div>';
                template += '   <div class=" ">';
                template += '           <button class="btn primary text-white" ng-click="auditDossierTransaction()"  permission permission-only="\'admin_audit\'">';
                template += '               <i class="fa fa-eye"></i>';
                template += '               <span translate="global.documents.new.audit">Audit</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="reopen()"  permission permission-only="\'reopen_dossier_transaction\'" ng-if="!guided && !actual && isUpdateDossier && !isArchivedDossier && !isProposalTransaction && !isAgreementTransaction && !isAmendmentTransaction && !isTransferTransaction && !isInspectionIntervention && !isInspectionDepartment && !isStartInspectionFree  && !isAgreementTransactionFree && !isInspectionInterventionFree && !isInspectionDepartmentFree && !isOmission && !isReparation && !isOmissionFree && !isReparationFree">';
                template += '               <i class="fa fa-share"></i>';
                template += '               <span translate="global.literals.reopen">Reopen</span>';
                template += '           </button>';
                template += '           <button class="btn grey-500 text-white" ng-click="back()" permission permission-only="\'back_dossier\'" ng-if="!guided && actual && isUpdateDossier && !isAgreementTransaction && !isTransferTransaction && !isInspectionIntervention && !isInspectionDepartment && !isAgreementTransactionFree && !isInspectionInterventionFree && !isInspectionDepartmentFree && !isOmission && !isReparation && !isOmissionFree && !isReparationFree">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.literals.anular">Cancel</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="complete()" ng-if="!isAgreementTransaction && !((isInspectionIntervention || isInspectionDepartment || isInspectionInterventionFree || isInspectionDepartmentFree) && isInternalControl) && !((isInspectionIntervention || isInspectionInterventionFree) && guided && existOmission)" ng-disabled="!isUpdateDossier || !actual">';
                template += '               <i ng-if="!isProposalTransaction && !isAmendmentTransaction && !isInspectionIntervention && !isInspectionDepartment && !isStartInspectionFree && !isInspectionInterventionFree && !isInspectionDepartmentFree" class="fa fa-check"></i>';
                template += '               <span ng-if="!isProposalTransaction && !isAmendmentTransaction && !isInspectionIntervention && !isInspectionDepartment && !isStartInspectionFree && !isInspectionInterventionFree && !isInspectionDepartmentFree" translate="global.tram.literals.completetransaction">Complete</span>';
                template += '               <i ng-if="isProposalTransaction || isAmendmentTransaction || isInspectionIntervention || isInspectionDepartment  || isStartInspectionFree || isInspectionDepartmentFree" class="fa fa-remove"></i>';
                template += '               <span ng-if="isProposalTransaction || isAmendmentTransaction || ((isInspectionIntervention || isInspectionDepartment) && !isInternalControl )" translate="global.tram.literals.cancelproposal">Cancel proposal</span>';
                template += '               <span ng-if="isStartInspectionFree || ((isInspectionInterventionFree || isInspectionDepartmentFree) && !isInternalControl )" translate="global.tram.literals.cancelInspectionproposal">Cancel inspection proposal</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="sendProposalDossierTransaction()" ng-if="isProposalTransaction || isAmendmentTransaction || ((isInspectionIntervention || isInspectionDepartment) && !isInternalControl)" ng-disabled="!isUpdateDossier || !actual">';
                template += '               <i class="fa fa-send-o fa-fw"></i>';
                template += '               <span translate="global.tram.literals.sendProposal">Send proposal</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="sendInspectionProposalDossierTransaction()" ng-if="isStartInspectionFree || ((isInspectionInterventionFree || isInspectionDepartmentFree) && !isInternalControl)" ng-disabled="!isUpdateDossier || !actual">';
                template += '               <i class="fa fa-send-o fa-fw"></i>';
                template += '               <span ng-if="!isInspectionInterventionFree" translate="global.tram.literals.sendInspectionProposal">Send inspection proposal</span>';
                template += '               <span ng-if="isInspectionInterventionFree" translate="global.tram.literals.finishInspectionProposal">Finish inspection proposal</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="ammendmentProposal()" ng-if="((isInspectionIntervention || isInspectionDepartment) && !isInternalControl) && !((isInspectionIntervention || isInspectionInterventionFree) && guided && existOmission)" ng-disabled="!isUpdateDossier || !actual">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.sec.streamline.amendmentProposal">Amendment proposal</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="createOmission()" ng-if="((isInspectionInterventionFree || isInspectionIntervention) && !isInternalControl && canCreateOmissionReparation) && !((isInspectionIntervention || isInspectionInterventionFree) && guided && existOmission)" ng-disabled="!canShowCIButtons || !actual">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.literals.omissionLit">Omission</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="createReparation()" ng-if="((isInspectionInterventionFree || isInspectionIntervention) && !isInternalControl && canCreateOmissionReparation) && !((isInspectionIntervention || isInspectionInterventionFree) && guided && existOmission)" ng-disabled="!canShowCIButtons || !actual">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.literals.reparationLit">Reparation</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="openInternalControl()" ng-if="((isInspectionDepartment || isInspectionDepartmentFree) && isInternalControl)" ng-disabled="!canShowCIButtons || !actual">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.tram.literals.viewCI">View CI</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="openInternalControlRequest()" ng-if="((isInspectionIntervention || isInspectionInterventionFree) && isInternalControl)" ng-disabled="!canShowCIButtons || !actual">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.tram.literals.viewCIRequest">View CI</span>';
                template += '           </button>';
                template += '           <button class="btn primary text-white" ng-click="openInternalControlReparation()" ng-if="((isReparation || isReparationFree) && isInternalControl)" ng-disabled="!canShowCIButtons || !actual">';
                template += '               <i class="fa fa-mail-reply"></i>';
                template += '               <span translate="global.tram.literals.viewCIReparation">View CI Reparation</span>';
                template += '           </button>';
                template += '   </div>';
            }
            template += '   </div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                type: '@',
                label: '@',
                days: '=',
                date: '=',
                expiretype: '=',
                back: '&',
                reopen: '&',
                sendProposalDossierTransaction: '&',
                sendInspectionProposalDossierTransaction: '&',
                complete: '&',
                createReparation: '&',
                createOmission: '&',
                backbutton: '=',
                isUpdateDossier: '=',
                isProposalTransaction: '=',
                isAmendmentTransaction: '=',
                isAgreementTransaction: '=',
                isTransferTransaction: '=',
                isInspectionIntervention: '=',
                isInspectionDepartment: '=',
                isStartInspectionFree: '=',
                isAgreementTransactionFree: '=',
                isInspectionInterventionFree: '=',
                isInspectionDepartmentFree: '=',
                canCreateOmissionReparation: '=',
                isOmission: '=',
                isReparation: '=',
                isOmissionFree: '=',
                isReparationFree: '=',
                isInternalControl: '=',
                actual: '=',
                guided: '=',
                transactionType: '=',
                auditDossierTransaction: '&',
                ammendmentProposal: '&',
                openInternalControl: '&',
                openInternalControlRequest: '&',
                openInternalControlReparation: '&',
                existOmission: '='
            },
            link: function (scope, element, attr) {
            	scope.isArchivedDossier = ((TramFactory.isArchivedDossier || (TramFactory.Dossier && TramFactory.Dossier.dossierStatus && _.contains(['CLOSED','TRANSFER','REJECTED','ARCHIVED'],TramFactory.Dossier.dossierStatus)))?true:false);
            	element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
				scope.canShowCIButtons = true;
				if((TramFactory.Dossier && TramFactory.Dossier.dossierStatus != 'IN_PROGRESS' && !TramFactory.isArchivedDossier) 
            		|| (TramFactory.Dossier &&  (TramFactory.Dossier.dossierStatus != 'TRANSFER' && TramFactory.Dossier.dossierStatus != 'REJECTED' && !(TramFactory.Dossier.dossierStatus == 'ARCHIVED' && !TramFactory.Dossier.archiveDefinitiveDate)) && TramFactory.isArchivedDossier)){
                	scope.canShowCIButtons = false;
            	}
            }
        }
    }])
    .directive('annexaBoxReceivers', ['$compile', 'TramFactory', 'Language', '$filter', function ($compile, TramFactory, Language, $filter) {
        var getTemplate = function (scope) {
            var template = '';

            template += '<div ng-if="receivers.length==0" class="text-center cursiva-grey" translate="global.literals.empty_receivers">Add</div>';

            template += '<div ng-if="receivers.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="receiver in receivers" >';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs _600">';
            template += '               <div class="col-xs-1">';
            template += '                   <i ng-if="receiver.principal" class="fa fa-lg text-warn fa-flag " title="{{\'global.literals.principalDest\' | translate}}"></i>';
            template += '                   <a ng-if="!receiver.principal" ng-click="setPrincipal(receiver.id, $index)" title="{{\'global.literals.makePrincipalDest\' | translate}}">';
            template += '                       <i ng-if="!receiver.principal" class="fa fa-lg text-grey fa-flag-o"></i>';
            template += '                   </a>';
            template += '               </div>';
            template += '               <div class="pull-left m-l m-r-xs">';
            template += '                   <href-permission class-href="text-primary _600"  link-href="annexa.thirds.edit" attr-compare="id" id-href="receiver.third.id" name-href="receiver.third.identificationDocument" href-permission-only="\'view_thirds\'"/>';
            template += '               </div>';
            template += '               <div class="col-sm-4 col-xxl-5">';
            template += '                   <span ng-if="receiver.third.corporateName">{{receiver.third.corporateName}}</span>';
            template += '                   <span ng-if="!receiver.third.corporateName && receiver.third.surename2">{{receiver.third.name + ((receiver.third.surname1Particles)?\' \'+receiver.third.surname1Particles:\'\') + \' \' + receiver.third.surename1 + ((receiver.third.surname2Particles)?\' \'+receiver.third.surname2Particles:\'\') + \' \' + receiver.third.surename2}}</span>';
            template += '                   <span ng-if="!receiver.third.corporateName && !receiver.third.surename2">{{receiver.third.name + ((receiver.third.surname1Particles)?\' \'+receiver.third.surname1Particles:\'\') + \' \' + receiver.third.surename1}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-2 col-lg-offset-1 col-lg-3 col-xl-offset-0 col-xl-2" ng-if="receiver.interested">';
            template += '                   <span class="label label-xxs" translate="global.literals.interested">Interessted</span>'+' <i ng-if="receiver.notifiable" title="'+$filter('translate')('global.tram.literals.secretary_notifiable')+'" class="fa fa-solid fa-bell text-warn"></i>';
            template += '                   <span ng-if="receiver.roleInterested" class="label label-xxs">{{receiver.roleInterested[languageColumn]}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-2 col-lg-3 col-xl-2">';
            template += '                   <span ng-if="receiver.representative && receiver.representated && receiver.representated.name && !receiver.representated.surename2" class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \' (\' + receiver.representated.name + ((receiver.representated.surname1Particles)?\' \'+receiver.representated.surname1Particles:\'\') + \' \' + receiver.representated.surename1 + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }">Representative</span>'+' <i ng-if="receiver.representative && receiver.representated && receiver.representated.name && !receiver.representated.surename2 && receiver.notifiable" title="'+$filter('translate')('global.tram.literals.secretary_notifiable')+'" class="fa fa-solid fa-bell text-warn"></i>';
            template += '                   <span ng-if="receiver.representative && receiver.representated && receiver.representated.name && receiver.representated.surename2" class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \' (\' + receiver.representated.name + ((receiver.representated.surname1Particles)?\' \'+receiver.representated.surname1Particles:\'\') + \' \' + receiver.representated.surename1 + ((receiver.representated.surname2Particles)?\' \'+receiver.representated.surname2Particles:\'\') + \' \' + receiver.representated.surename2 + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }">Representative</span>'+' <i ng-if="receiver.representative && receiver.representated && receiver.representated.name && receiver.representated.surename2 && receiver.notifiable" title="'+$filter('translate')('global.tram.literals.secretary_notifiable')+'" class="fa fa-solid fa-bell text-warn"></i>';
            template += '                   <span ng-if="receiver.representative && receiver.representated && !receiver.representated.name" class="label label-xxs" translate="global.literals.representative" translate-values="{ representated: \' (\' + receiver.representated.corporateName + \' (\' + receiver.representated.identificationDocument + \')\' + \')\' }">Representative</span>'+' <i ng-if="receiver.representative && receiver.representated && !receiver.representated.name && receiver.notifiable" title="'+$filter('translate')('global.tram.literals.secretary_notifiable')+'" class="fa fa-solid fa-bell text-warn"></i>';
            template += '               </div>';
            template += '           </div>';
            template += '           <div class="row m-b-xs" ng-repeat="address in receiver.addresses" >';
            template += '               <div class="col-sm-11 col-sm-offset-1 " ng-bind-html="address"></div>';
            template += '           </div>';
            template += '       	<div class="row m-b-xs" ng-if="receiver.additionalInformation" >';
            template += '				<div class="col-sm-11 col-sm-offset-1">';
            template += '					<i class="fa fa-info-circle fa-fw text-black-lt pull-left" title="{{\'global.tram.literals.additional_information\' | translate}}"></i>';
            template += '					<span class="pull-left-fa"> {{receiver.additionalInformation}}</span>';
            template += '				</div>';
            template += '           </div>';
            template += '       </div>'; 
            template += '       <div class="col-xs-1">';
            template += '           <annexa-edit-button class="pull-right" edit-function="editFunction" edit-object="receiver" edit-object-index="$index" actual="true"></annexa-edit-button>';
            template += '       </div>';            
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button class="pull-right"  delete-function="deleteFunction" delete-object="receiver" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                receivers: '=',
                deleteFunction: '=',
                editFunction: '=',
                setPrincipal: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);                
                scope.languageColumn = Language.getActiveColumn();
            }
        }
    }])
    .directive('annexaBoxDocumentsTram', ['$compile','$rootScope', 'TramFactory', 'globalModals', 'AnnexaFormlyFactory', function ($compile, $rootScope, TramFactory, globalModals, AnnexaFormlyFactory) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="documents.length == 0" class="text-center cursiva-grey" translate="global.literals.empty_documents">Add</div>';
            template += '<div ng-if="documents.length > 0">';
            template += '   <div class="row m-b-p-b-sm-b-b">';
            template += '       <div class="inline m-l-sm">';
            template += '		   <button class="btn btn-sm label label-breakable {{labelDossierTransactionDocs}}" ng-click="filterRelationType(\'DOCUMENT\')">';
            template += '		        <span>{{ \'global.relationType.DOCUMENT\' | translate}} ({{counterDossierTransactionDocs}})</span>';
            template += '		    </button>';
            template += '		</div>';
            template += '       <div class="inline m-l-xs">';
            template += '		    <button class="btn btn-sm label label-breakable {{labelDossierTransactionWorkDocs}}" ng-click="filterRelationType(\'WORK_DOCUMENT\')">';
            template += '		        <span>{{ \'global.relationType.WORK_DOCUMENT\' | translate}} ({{counterDossierTransactionWorkDocs}})<span>';
            template += '		    </button>';
            template += '		</div>';
            template += '       <div class="inline m-l-xs">';
            template += '		    <button class="btn btn-sm label label-breakable {{labelDossierTransactionInternalDocs}}" ng-click="filterRelationType(\'INTERNAL_DOCUMENT\')">';
            template += '		        <span>{{ \'global.relationType.INTERNAL_DOCUMENT\' | translate}} ({{counterDossierTransactionInternalDocs}})<span>';
            template += '		    </button>';
            template += '		</div>';
            template += '       <div class="inline m-l-xs">';
            template += '		    <button class="btn btn-sm label label-breakable {{labelDossierTransactionPaperBinDocs}}" ng-click="filterRelationType(\'PAPER_BIN\')">';
            template += '		        <span>{{ \'global.literals.paperBin\' | translate}} ({{counterDossierTransactionPaperBinDocs}})<span>';
            template += '		    </button>';
            template += '		</div>';
            template += '	</div>';
            template += '   <div ng-if="filteredContent.length == 0" class="text-center cursiva-grey" translate="global.literals.empty_documents">Add</div>';
            template += '   <div ng-if="filteredContent.length > 0" class="documentRows">';
        	template += '    <div class="row ng-class:{\'m-b-sm-p-b-sm-b-b\' : !$last} " ng-repeat="document in filteredContent | startFrom:page*itemsPage | limitTo:itemsPage">';
            template += '       <div class="col-sm-9 col-xxl-10">';
            template += '           <div class="row m-b-xs p-b-xs m-t-sm">';
            template += '               <div class="col-xs-3 col-sm-2 col-xxl-1">';
            template += '                   <span ng-if="document.document.publicDocument && actionFunction"><i title="{{\'global.documentState.public\' | translate}}" class="m-l-xs m-r-lg fa fa-check-square-o"></i></span>';
            template += '                   <span ng-if="!document.document.publicDocument && actionFunction"><i title="{{\'global.documentState.no_public\' | translate}}" class="m-l-xs m-r-lg text-grey fa fa-square-o"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'NEW\'"><i title="{{\'global.documentState.NEW\' | translate}}" class="fa fa-circle-o text-blue fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'NEW_AND_ATTACHMENT\'"><i title="{{\'global.documentState.NEW_AND_ATTACHMENT\' | translate}}" class="fa v-t fa-adjust text-blue fa-lg"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'PENDING_SIGN\'"><i title="{{\'global.documentState.PENDING_SIGN\' | translate}}" class="fa fa-circle text-blue v-t fa-lg "></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'SIGNED\'"><i title="{{\'global.documentState.SIGNED\' | translate}}" class="fa fa-circle text-success fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'REJECTED\'"><i title="{{\'global.documentState.REJECTED\'| translate}}" class="fa fa-circle text-danger fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'COMPLETE\'"><i title="{{\'global.documentState.COMPLETE\'| translate}}" class="fa fa-dot-circle-o text-success fa-lg v-t"></i></span>';
            template += '                   <span ng-if="document.document.docStatus == \'ARCHIVED\'"><i title="{{\'global.documentState.ARCHIVED\'| translate}}" class="fa fa-circle-o text-success fa-lg v-t"></i></span>';
            template += '               </div>';
            template += '               <div class="col-xs-9 col-sm-10 col-md-5 col-lg-4 small-lg">';
            template += '                   <annexa-href-permission-document document="document.document" name-properties="\'code\'" origin="DOSSIER"></annexa-href-permission-document>'
            template += '               </div>';
            template += '               <div class=" col-md-6 _600">';
            template += '                   <span >{{document.document.name}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-sm-3 col-xxl-2">';
            template += '           <annexa-document-actions-button dossier-transaction-document="document" transaction-init="transaction" list="true" module="EXPED" object-to-call="archiveClassification" thirds="thirds"></annexa-document-actions-button>'
            template += '       </div>'; // /col
            template += '    </div>';  // /row
            template += '   </div>'; // /documentRows
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                documents: '=',
                deleteFunction: '=',
                actionFunction: '=',
                actualTransaction: '=',
                transaction: '=',
                page: '=',
                itemsPage: '=',
				thirds: '='
            },
            link: function (scope, element, attr) {
                scope.$on('annexaBoxDocumentsTramUpdateCounter', function (event, args) {
                	if(!args.transactionId || (scope.transaction && args.transactionId == scope.transaction.id)){
	                	if (args.content && args.relationType) {
	                		if(args.relationType == 'PAPER_BIN') {
	                			scope.filteredContent = $linq(args.content).where("x => x.paperBin == true").toArray();
	                		} else {
	                			scope.filteredContent = $linq(args.content).where("x => x.relationType == '" + args.relationType + "' && x.paperBin == false").toArray();
	                		}
	                		if (args.relationType == 'DOCUMENT') {
	                    		scope.labelDossierTransactionDocs = 'success';
	                    		scope.labelDossierTransactionWorkDocs = '';
	                    		scope.labelDossierTransactionInternalDocs = '';
	                    		scope.labelDossierTransactionPaperBinDocs = '';
	                    		scope.counterDossierTransactionDocs = scope.filteredContent.length;
	                    	} else if (args.relationType && args.relationType == 'WORK_DOCUMENT') {
	                    		scope.labelDossierTransactionDocs = '';
	                    		scope.labelDossierTransactionWorkDocs = 'success';
	                    		scope.labelDossierTransactionInternalDocs = '';
	                    		scope.labelDossierTransactionPaperBinDocs = '';
	                    		scope.counterDossierTransactionWorkDocs = scope.filteredContent.length;
	                		} else if (args.relationType && args.relationType == 'INTERNAL_DOCUMENT') {
	                    		scope.labelDossierTransactionDocs = '';
	                    		scope.labelDossierTransactionWorkDocs = '';
	                    		scope.labelDossierTransactionInternalDocs = 'success';
	                    		scope.labelDossierTransactionPaperBinDocs = '';
	                    		scope.counterDossierTransactionInternalDocs = scope.filteredContent.length;
	                		} else {
	                    		scope.labelDossierTransactionDocs = '';
	                    		scope.labelDossierTransactionWorkDocs = '';
	                    		scope.labelDossierTransactionInternalDocs = '';
	                    		scope.labelDossierTransactionPaperBinDocs = 'success';
	                    		scope.counterDossierTransactionPaperBinDocs = scope.filteredContent.length;
	                		}
	                	} else if (args.content) {
	                		scope.counterDossierTransactionDocs = $linq(args.content).where("x => x.relationType == 'DOCUMENT' && x.paperBin == false").count();
	                		scope.counterDossierTransactionWorkDocs = $linq(args.content).where("x => x.relationType == 'WORK_DOCUMENT' && x.paperBin == false").count();
	                		scope.counterDossierTransactionInternalDocs = $linq(args.content).where("x => x.relationType == 'INTERNAL_DOCUMENT' && x.paperBin == false").count();
	                		scope.counterDossierTransactionPaperBinDocs = $linq(args.content).where("x => x.paperBin == true").count();
	                		if(scope.documents){
		                		if(scope.labelDossierTransactionDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'DOCUMENT' && x.paperBin == false").toArray();
		                		}else if(scope.labelDossierTransactionWorkDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'WORK_DOCUMENT' && x.paperBin == false").toArray();
		                		}else if(scope.labelDossierTransactionInternalDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'INTERNAL_DOCUMENT' && x.paperBin == false").toArray();
		                		}else if(scope.labelDossierTransactionPaperBinDocs == 'success'){
		                			scope.filteredContent = $linq(scope.documents).where("x => x.paperBin == true").toArray();
		                		}
	                		}else{
	                			if(scope.filteredContent){
	                				scope.filteredContent.length = 0;
	                			}else{
	                				scope.filteredContent = [];
	                			}
	                		}
	                	}
	                	$rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounterReloadTable', { reload:true });
                	}
                });

                scope.counterDossierTransactionDocs = 0;
                scope.counterDossierTransactionWorkDocs = 0;
                scope.counterDossierTransactionInternalDocs = 0;
                scope.counterDossierTransactionPaperBinDocs = 0;
                scope.labelDossierTransactionDocs = '';
                scope.labelDossierTransactionWorkDocs = '';
                scope.labelDossierTransactionInternalDocs = '';
                scope.labelDossierTransactionPaperBinDocs = '';
                scope.filteredContent = [];
                if (scope.documents) {
                	scope.filteredContent = $linq(scope.documents).where("x => x.relationType == 'DOCUMENT' && x.paperBin == false").toArray();
                	scope.labelDossierTransactionDocs = 'success';
                	scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.documents, transactionId:((scope.transaction)?scope.transaction.id:undefined) });
                }

                scope.filterRelationType = function (relationType) {
                	if(relationType == 'PAPER_BIN') {
            			scope.filteredContent = $linq(scope.documents).where("x => x.paperBin == true").toArray();
            		} else {
            			scope.filteredContent = $linq(scope.documents).where("x => x.relationType == '" + relationType + "' && x.paperBin == false").toArray();
            		}
                	if (relationType && relationType == 'DOCUMENT') {
                		scope.labelDossierTransactionDocs = 'success';
                		scope.labelDossierTransactionWorkDocs = '';
                		scope.labelDossierTransactionInternalDocs = '';
                        scope.labelDossierTransactionPaperBinDocs = '';
                	} else if (relationType && relationType == 'WORK_DOCUMENT') {
                		scope.labelDossierTransactionDocs = '';
                		scope.labelDossierTransactionWorkDocs = 'success';
                		scope.labelDossierTransactionInternalDocs = '';
                        scope.labelDossierTransactionPaperBinDocs = '';
                	} else if (relationType && relationType == 'WORK_DOCUMENT') {
                		scope.labelDossierTransactionDocs = '';
                		scope.labelDossierTransactionWorkDocs = '';
                		scope.labelDossierTransactionInternalDocs = 'success';
                        scope.labelDossierTransactionPaperBinDocs = '';
                	} else {
                		scope.labelDossierTransactionDocs = '';
                		scope.labelDossierTransactionWorkDocs = '';
                		scope.labelDossierTransactionInternalDocs = '';
                        scope.labelDossierTransactionPaperBinDocs = 'success';
                	}
                }

                element.html(getTemplate(scope)).show();
                scope.archiveClassification = ((TramFactory.Dossier && TramFactory.Dossier.archiveClassification)?TramFactory.Dossier.archiveClassification:undefined);
                $compile(element.contents())(scope);
                
                scope.$on('documentActionButtonExecuted', function (event, args) {
                    if (args.button == 'deleteDossierTransactionDocument') {
                    	var indexRemoved = $linq(scope.filteredContent).indexOf("x => x.document.id == " + args.data.documentId);
                        
                        if (indexRemoved != -1) {
                        	var isInPaperBin = scope.filteredContent[indexRemoved].paperBin;
                        	var relationTypeBeforeDelete = scope.filteredContent[indexRemoved].relationType;
                        	if(isInPaperBin) {
                        		relationTypeBeforeDelete = 'PAPER_BIN';
                        	}
                            scope.filteredContent.splice(indexRemoved, 1);
                        	scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.filteredContent, relationType: relationTypeBeforeDelete, transactionId: ((scope.transaction)?scope.transaction.id:undefined) });
                        }
                    } else if (args.button == 'updateRelationTypeDossierTransactionDocument') {
                        var indexDTD = $linq(scope.documents).indexOf("x => x.id == " + args.data.id);

                        if (indexDTD != -1) {
                        	var relationTypeBeforeUpdate = scope.documents[indexDTD].relationType;
                            scope.documents[indexDTD].relationType = args.data.relationType;
                            scope.documents[indexDTD].paperBin = args.data.paperBin;
                            scope.filteredContent = $linq(scope.documents).where("x => x.relationType == '" + relationTypeBeforeUpdate + "'").toArray();
                            scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.documents, transactionId: ((scope.transaction)?scope.transaction.id:undefined) });
                        }                    	
                    } else if(args.button == 'unbindDocument') {
                    	var indexRemoved = $linq(scope.documents).indexOf("x => x.document.id == " + args.data.document.id);
                    	if(indexRemoved != -1) {
	                    	scope.documents.splice(indexRemoved, 1);
	                    	scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: scope.documents, relationType: null, transactionId: ((scope.transaction)?scope.transaction.id:undefined) });
                    	}
                    }
                });

                scope.$on('SendToSignModalFinished', function (event, args) {
                    if (args.data) {
                        _.forEach(args.data, function (item) {
                            var indexDoc = $linq(scope.documents).indexOf("x => x.document.id == " + item.id);

                            if (indexDoc != -1) {
                                scope.documents[indexDoc].document.docStatus = item.docStatus;
                            }
                        })
                    }
                });
                
            }
        }
    }])
    .directive('annexaBoxRegisterEntries', ['$compile', function ($compile) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="registerEntries.length==0 && type == \'INPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntries">Add</div>';
            template += '<div ng-if="registerEntries.length==0 && type == \'OUTPUT\'" class="text-center cursiva-grey" translate="global.literals.empty_registerEntriesOutput">Add</div>';
            template += '<div ng-if="registerEntries.length>0" class="striped" >';
            template += '   <div class="row p-y-sm" ng-repeat="registerEntry in registerEntries">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            template += '               <div class="col-xs-10 col-sm-5 col-xl-3 hellip ">';
            template += '                   <annexa-href-permission-reg-input register-entry="registerEntry"></annexa-href-permission-reg-input>'
            template += '               </div>';
            template += '               <div class="col-sm-7 col-xl-9  _600">';
            template += '                   <span>{{registerEntry.extract}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button class="pull-right" delete-function="deleteFunction" delete-object="registerEntry" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                registerEntries: '=',
                deleteFunction: '=',
                type: '@'
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaBoxDossiers', ['$compile', 'Language', function ($compile, Language) {
        var getTemplate = function (scope) {
            var template = '';
            template += '<div ng-if="dossiers.length==0" class="text-center cursiva-grey" translate="global.literals.empty_dossiers">Empty</div>';
            template += '<div ng-if="dossiers.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="dossier in dossiers">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs">';
            template += '               <div class="col-xs-10 col-sm-1 col-md-1 col-lg-1 hellip ">';
            template += '                   <span ng-if="dossier.inverse" title="{{dossier.relation[inverseLangColumn]}}" class="material-icons"> swap_horizontal_circle </span>';
            template += '                   <span ng-if="!dossier.inverse" title="{{dossier.relation[langColumn]}}" class="material-icons fa-flip-horizontal "> swap_horizontal_circle </span>';
            template += '               </div>';
            template += '               <div class="col-xs-10 col-sm-3 col-md-4 col-lg-3 hellip ">';
            template += '                   <a ng-if="dossier && dossier.related && dossier.related.dossierStatus != \'ARCHIVED\' && dossier.related.dossierStatus != \'REJECTED\'  && dossier.related.dossierStatus != \'TRANSFER\'"  class="text-primary _400 small-lg" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: dossier.related.id, dossierTransaction: (dossier.related.actualDossierTransactionId ? dossier.related.actualDossierTransactionId : 0)})" target="_blank"> {{dossier.related.dossierNumber}}</a>';
            template += '                   <span ng-if="dossier && dossier.related && !(dossier.related.dossierStatus != \'ARCHIVED\' && dossier.related.dossierStatus != \'REJECTED\'  && dossier.related.dossierStatus != \'TRANSFER\')"  class="text-primary _400 small-lg"> {{dossier.related.dossierNumber}}</span>';
            template += '               </div>';
            template += '               <div ng-class="dossier.related.closed ? \' col-sm-4 col-md-7 col-lg-4 \' : \' col-sm-5 col-md-7 col-lg-5 \'">';
            template += '                   <span class=" _600">{{dossier.related.subject}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-3 col-md-12 col-lg-3" ng-if="!dossier.related.closed">';
            template += '                   <span class="label label-xxs" translate="global.literals.in_process">In proces</span>';
            template += '               </div>';
            template += '               <div class="col-sm-4 col-md-12 col-lg-4" ng-if="dossier.related.closed">';
            template += '                   <span class="label label-xxs success" ng-if="dossier.related.dossierStatus != \'CANCELLED\'" translate="global.literals.ended">Ended</span>';
            template += '                   <span class="label label-xxs danger" ng-if="dossier.related.dossierStatus == \'CANCELLED\'" translate="CANCELLED">Cancelled</span>';
            template += '                   <span class="small text-success">{{dossier.related.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1">';
            template += '           <annexa-delete-button class="pull-right" delete-function="deleteFunction" delete-object="dossier" delete-object-index="$index" actual="true"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                dossiers: '=',
                deleteFunction: '='
            },
            link: function (scope, element, attr) {
            	scope.langColumn = Language.getActiveColumn();
            	scope.inverseLangColumn = "inverseL"+scope.langColumn.substring(1);
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        }
    }])
    .directive('annexaDossierTransaction', ['$state', '$filter', '$rootScope', 'RestService', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaModalFactory', 'TramFactory', 'Language', 'HelperService', 'dialogs', 'ErrorFactory', 'AnnexaPermissionsFactory', 'SecFactory', '$q', 'SecModals', 'DialogsFactory', '$stateParams', 'apiAdmin', 'GlobalDataFactory'
    	, function ($state, $filter, $rootScope, RestService, globalModals, AnnexaFormlyFactory, AnnexaModalFactory, TramFactory, Language, HelperService, dialogs, ErrorFactory, AnnexaPermissionsFactory, SecFactory, $q, SecModals, DialogsFactory, $stateParams, apiAdmin,GlobalDataFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                previous: '=',
                order: '@',
                languageColumn: '=',
                viewData: '=',
                backbutton: "=",
                isUpdateDossier: '=',
                registerOutputBox: '=',
                notificationBox: '=',
                openTransaction: '=',
                activeTab: '=',
                loggedUser: '=',
				operationsActive: '=?'
            },
            templateUrl: './views/layout/viewdossier/general_left/transaction.html',
            controller: ['$scope', '$rootScope', 'TramFactory', function ($scope, $rootScope, TramFactory) {
            	$scope.loggedUser = $rootScope.LoggedUser;
            	$scope.dossierStatus = ((TramFactory.Dossier && TramFactory.Dossier.dossierStatus)?TramFactory.Dossier.dossierStatus:'');
            	var dtLanguage1 = (($scope.transaction)?$scope.transaction.language1:'');
            	var dtLanguage2 = (($scope.transaction)?$scope.transaction.language2:'');
            	var dtLanguage3 = (($scope.transaction)?$scope.transaction.language3:'');
                var getCurrentDateFormat = function () {
                    var now = new Date();
                    var year = now.getFullYear();
                    var month = now.getMonth();
                    var day = now.getDate();
                    var hour = now.getHours();
                    var min = now.getMinutes();
                    return day + "/" + month + "/" + year + ", " + hour + ":" + min;
                };
                $scope.guided = false;
                if (TramFactory.Dossier && TramFactory.Dossier && TramFactory.Dossier.procedure) {
                    $scope.guided = TramFactory.Dossier.procedure.guided;
                }
                $scope.transactionTabSelected = function ($event, tab) {
                    $scope.activeTab = tab;
                    if(tab == 2){
                    	$scope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: $scope.transaction.documents, transactionId:(($scope.transaction)?$scope.transaction.id:undefined) });
                    }
                    if(tab == 4){
                    	$rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
                    }
                    $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                    $rootScope.$broadcast('transactionTabSelected', {tab: tab});
                }
                
                var expiretype;
                if($scope.transaction.expireType && $scope.transaction.expireType != null) {
                	expiretype = $scope.transaction.expireType;
                } else {
                	expiretype = $scope.transaction.transactionType.expireType;
                }

                $scope.daysexpire = $scope.transaction.transactionRemainingDays;
                $scope.expiredate = HelperService.getExpirationDate(new Date(), $scope.daysexpire, $rootScope.LoggedUser.entity.holidays, expiretype);
                
                var canEditDossierTransactionResponsibleUser = function() {
                	var res = false;
					if($scope.isUpdateDossier){
	            		if (TramFactory.Dossier.responsibleUser && TramFactory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id) {
							res = true;
						} else {
							if(AnnexaPermissionsFactory.havePermission("change_responsible_dossier_transaction")){
								if ($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles) {
									if(TramFactory.Dossier.dossierResponsibleProfiles){
										angular.forEach(TramFactory.Dossier.dossierResponsibleProfiles, function (profile) {
											if(profile.profile && profile.profile.id){
												var profiles = $linq($rootScope.LoggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
			                                    if (profiles && profiles.length > 0) {
			                                        res = true;
			                                    }
											}
										});
			    
									}
								}
								if(!res){
									angular.forEach($scope.transaction.processProfiles, function (profile) {
			            				if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
			            					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
			            					if(dtpp){
			            						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			            						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
			            						angular.forEach(users, function (user) {
			            							if (user.id == $rootScope.LoggedUser.id) {
			            								res = true;
			            							}
			            						});
			            					}
			            				}
			            			});
								}
							}
	                	}
					}
                	return res;
                };                
				$scope.canEditDossierTransactionResponsibleUser = canEditDossierTransactionResponsibleUser();

				var canSeeDossierTransactionResponsibleUserLabel = function() {
                	var res = false;
                	if (!$rootScope.esetMode && (!$rootScope.app || !$rootScope.app.configuration || !$rootScope.app.configuration.assign_responsible_user || !$rootScope.app.configuration.assign_responsible_user.dossierTransaction || 
							$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria')) {
						if (TramFactory.Dossier.responsibleUser && TramFactory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id) {
							res = false;
						}else if(AnnexaPermissionsFactory.havePermission("change_responsible_dossier_transaction")){
							if ($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles) {
								var isInResponsibleProfiles = false;//Los usuarios del perfíl responsabe del expediente simpre que tengan el permiso, pueden modificar el usuario responsable del trámite
		            			if(TramFactory.Dossier.dossierResponsibleProfiles){
									angular.forEach(TramFactory.Dossier.dossierResponsibleProfiles, function (profile) {
										if(!isInResponsibleProfiles && profile.profile && profile.profile.id){
											var profiles = $linq($rootScope.LoggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
		                                    if (profiles && profiles.length > 0) {
		                                        isInResponsibleProfiles = true;
		                                    }
										}
									});
		    
								}
								if(!isInResponsibleProfiles){
									angular.forEach($scope.transaction.processProfiles, function (profile) {
			            				if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
			            					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
			            					if(dtpp){
			            						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			            						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
			            						angular.forEach(users, function (user) {
			            							if (user.id == $rootScope.LoggedUser.id) {
			            								isInResponsibleProfiles = true;
			            							}
			            						});
			            					}
			            				}
			            			});
								}
								if(!isInResponsibleProfiles && (!$scope.transaction.responsibleUser || $scope.transaction.responsibleUser.id == $rootScope.LoggedUser.id)){
									res = true;
								}else{
									res = false;
								}
							}else{
								res = false;
							}
	            		} else {
	                		if(!$scope.transaction.responsibleUser || $scope.transaction.responsibleUser.id == $rootScope.LoggedUser.id){
								res = true;
							}   
	                	}
                	}
                	return res;
                };                
				$scope.canSeeDossierTransactionResponsibleUserLabel = canSeeDossierTransactionResponsibleUserLabel();

				var canSeeDossierTransactionResponsibleUserSelect = function() {
                	var res = false;
            		if (TramFactory.Dossier.responsibleUser && TramFactory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id) {
						res = true;
					} else {
						if(AnnexaPermissionsFactory.havePermission("change_responsible_dossier_transaction")){
							if ($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles) {
								if(TramFactory.Dossier.dossierResponsibleProfiles){
									angular.forEach(TramFactory.Dossier.dossierResponsibleProfiles, function (profile) {
										if(profile.profile && profile.profile.id){
											var profiles = $linq($rootScope.LoggedUser.userProfiles).where("x => x.profile.id == " + profile.profile.id).toArray();
		                                    if (profiles && profiles.length > 0) {
		                                        res = true;
		                                    }
										}
									});
		    
								}
							}
							if(!res){
								angular.forEach($scope.transaction.processProfiles, function (profile) {
		            				if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
		            					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
		            					if(dtpp){
		            						var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
		            						users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
		            						angular.forEach(users, function (user) {
		            							if (user.id == $rootScope.LoggedUser.id) {
		            								res = true;
		            							}
		            						});
		            					}
		            				}
		            			});
							}
						}
						if($rootScope.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							$rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')){
							res = true;
						}else{
							if(!res && $scope.transaction.responsibleUser && $scope.transaction.responsibleUser.id != $rootScope.LoggedUser.id){
								res = true;
							}							
						}
                	}
                	return res;
                };                
				$scope.canSeeDossierTransactionResponsibleUserSelect = canSeeDossierTransactionResponsibleUserSelect();
				
				var canSetDossierTransactionResponsibleUser = function () {
					var res = false;
                	
                	if (!$rootScope.esetMode && $scope.isUpdateDossier) {
                		//El usuario actual se puede hacer responsable del trámite, aunque no tenga el permiso y simpre que se encuentre dentro del perfil responsable del trámitre
            			angular.forEach($scope.transaction.processProfiles, function (profile) {
            				if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
            					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
            					if(dtpp){
		            				var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
		            				users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
		    	
		    	                    angular.forEach(users, function (user) {
		    	                    	if (user.id == $rootScope.LoggedUser.id) {
		    	                    		res = true;
		    	                    	}
		    	                    });
            					}
            				}
            			});
                	}
                	
					return res;
				}
				$scope.canSetDossierTransactionResponsibleUser = canSetDossierTransactionResponsibleUser();
                
                $scope.comboTransactionResponsibleUser = [];                
                
                $scope.loadComboTransactionResponsibleUser = function () {
                	$scope.comboTransactionResponsibleUser = [];
	                angular.forEach($scope.transaction.processProfiles, function (profile) {
	                	if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
        					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
        					if(dtpp){
		        				var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			                    users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
								if(!$rootScope.esetMode && $rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
									$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria'){
									if(!$linq($scope.comboTransactionResponsibleUser).contains({id: undefined}, "(x,y) => x.id == y.id")){
										$scope.comboTransactionResponsibleUser.push({id: undefined, description: $filter("translate")("global.literals.withoutResponsible")});
									}	
								}
			                    angular.forEach(users, function (user) { 
			                    	if (!$scope.transaction.responsibleUser || user.id != $scope.transaction.responsibleUser.id) {
										if(!$linq($scope.comboTransactionResponsibleUser).contains(user.id, "(x,y) => x == y.id")){
											var responsibleUser = "";
					                        if (user.name) {
					                      	  responsibleUser = responsibleUser + user.name + " ";
					                        }
					                        if (user.surename1) {
					                      	  responsibleUser = responsibleUser + user.surename1 + " ";
					                        }
					                        if (user.surename2) {
					                      	  responsibleUser = responsibleUser + user.surename2;
					                        }
					                    	
					                    	$scope.comboTransactionResponsibleUser.push({id: user.id, description: responsibleUser});
										}
			                    	}
			                    });
        					}
	                	}
			        });
                };   
                
                $scope.loadComboTransactionResponsibleUser();
	            
				$scope.$on('updatedResponsibleProfileLoadResponsableUser', function (event, args) {
					$scope.loadComboTransactionResponsibleUser();
				});
                
				$scope.auditDossierTransaction = function () {
                    if ($scope.transaction && $scope.transaction.id) {
                        window.open($state.href('annexa.admin.audit_dossier_transaction', {"objectId": $scope.transaction.id}), '_blank');
                    }
                };
                // "Retrocedir" fase de tramitació crea un modal de confirmació amb textarea de "motiu"
                $scope.goBack = function () {
                	var trans = this.transaction;
                    if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                        DialogsFactory.confirm('global.tram.literals.goback_transaction_title', 'global.tram.literals.confirmGoBackTransaction').then(function () {
                        	existsDocumentsGoBackConfirm();
                        }).catch(function () {
                            //Empty
                        });
                    } else {
                    	existsDocumentsGoBackConfirm();
                    }
                };
                
                var existsDocumentsGoBackConfirm = function () {
                    if ($scope.transaction.documents.length > 0) {
                        var dlg = dialogs.confirm(
                            $filter('translate')('global.tram.literals.dialog_exists_documents_goback'),
                            $filter('translate')('global.tram.literals.dialog_exists_documents_goback_confirm'),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                        dlg.result.then(function (btn) {
                            $scope.goBackOK();
                        }, function (btn) {

                        });
                    } else {
                        $scope.goBackOK();
                    }                	
                }
                
                $scope.goBackOK = function () {
                    var data = {
                        row: true,
                        colClass: ' col-sm-12',
                        labelClass: ''
                    };
                    var actualTransactionsNum = $linq(TramFactory.Dossier.dossierTransactions).count("x => x.actual");
                    if (actualTransactionsNum > 1) {
                        var modal = angular.copy(globalModals.goBackTransactionPopup);
                        modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = {};
                        modal.annexaFormly.options = {};
                        modal.extra = {transaction: this.transaction};
                        AnnexaFormlyFactory.showModal('modalGoBackTransaction', modal, $scope.goBackSubmitPopup, false);

                    } else {
                        var modal = angular.copy(globalModals.goBackTransactionPopupWizard);
                        _.forEach(modal.wizard.steps, function (step, key) {
                            modal.wizard.steps[key].annexaFormly.model = {};
                            modal.wizard.steps[key].annexaFormly.options = {};
                            if (key == 1) {
                                modal.wizard.steps[key].annexaFormly.model = {
                                    transactionNoGuiadaList: [],
                                    haveReopenPermission: AnnexaPermissionsFactory.havePermission("reopen_dossier_transaction")
                                };
                                modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.loadFunction = $scope.searchProfiles;
                                modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.atributes = {
                                    transaction: $scope.transaction,
                                    backbutton: $scope.backbutton
                                };
                                modal.wizard.steps[key].annexaFormly.fields[1].templateOptions.numActuals = $linq(TramFactory.Dossier.dossierTransactions).count("x => x.actual");
                            }
                            if (key == 0) {
                                modal.wizard.steps[key].annexaFormly.fields[0].templateOptions.closedDossierTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => !x.actual && !x.subprocedure").toArray();
                            }
                        });
                        modal.wizard.modal = modal;
                        modal.extra = {transaction: this.transaction};
                        AnnexaFormlyFactory.showModal('modalGoBackTransaction', modal, $scope.goBackSubmitPopup, false, true);
                    }

                }
                // Submit del modal de confirmació al "Retrocedir" fase tramitació
                $scope.goBackSubmitPopup = function () {
                    var actualTransactionsNum = $linq(TramFactory.Dossier.dossierTransactions).count("x => x.actual")
                    var self = this;
                    var motiu = "";
                    if (actualTransactionsNum > 1) {
                        motiu = this.annexaFormly.model.motiu;
                    } else {
                        motiu = this.wizard.steps[0].annexaFormly.model.motiu;
                    }

                    var okFields = false;
                    var transactionLists = [];
                    var dossierTransactionToReopen = null;
                    if (this.wizard && this.wizard.steps && this.wizard.steps[1] && this.wizard.steps[1].annexaFormly && this.wizard.steps[1].annexaFormly.fields && this.wizard.steps[1].annexaFormly.fields[1] && this.wizard.steps[1].annexaFormly.fields[1].templateOptions && this.wizard.steps[1].annexaFormly.fields[1].templateOptions.numActuals < 2) {
                        if (this.wizard.steps[1].annexaFormly.model.dossierTransactionType == 1 || !AnnexaPermissionsFactory.havePermission("reopen_dossier_transaction")) {
                            if (this.wizard.steps[1].annexaFormly.model && this.wizard.steps[1].annexaFormly.model.transactionNoGuiadaList && this.wizard.steps[1].annexaFormly.model.transactionNoGuiadaList.length > 0) {
                                okFields = true;
                                transactionLists = this.wizard.steps[1].annexaFormly.model.transactionNoGuiadaList;
                            }
                        } else if (this.wizard.steps[1].annexaFormly.model.dossierTransactionType == 2) {
                            if (this.wizard.steps[1].annexaFormly.model && this.wizard.steps[1].annexaFormly.model.dossierTransactions) {
                                okFields = true;
                                dossierTransactionToReopen = this.wizard.steps[1].annexaFormly.model.dossierTransactions;
                            }
                        }
                    } else {
                        okFields = true;
                    }
                    if (okFields) {
                        TramFactory.deleteDossierTransaction($scope.transaction.id, transactionLists, motiu, dossierTransactionToReopen).then(function (data) {
                            if (data != null) {
                                self.close();
                                $state.reload();
                            }
                        }).catch(function (error) {
                            self.alerts.push({msg: 'global.errors.unknownDelete'});
                        });
                    } else {
                        this.alerts.push({msg: 'global.tram.errors.noTransactionsFinal'});
                    }
                };
                $scope.reopen = function () {
                    var dlg = dialogs.confirm(
                        $filter('translate')('global.tram.literals.reopen_dossier_transaction'),
                        $filter('translate')('global.tram.literals.reopen_dossier_transaction_confirm'),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                    dlg.result.then(function (btn) {
                        TramFactory.reopenDossierTransaction($scope.transaction.id).then(function (data) {
                            if (data != null) {
                                $state.reload();
                                var err = dialogs.notify(
                                    '',
                                    $filter('translate')('global.tram.literals.reopen_dossier_transaction_OK'),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            }
                        }).catch(function (error) {
                            var err = dialogs.error(
                                $filter('translate')('DIALOGS_ERROR_MSG'),
                                $filter('translate')('global.tram.literals.reopen_dossier_transaction_error'),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );
                        });
                    }, function (btn) {

                    });
                };

                $scope.completeTransactionPopupNoGuiats = function () {
                    var trans = this.transaction;
					var obligOK = true;
                    angular.forEach(trans.customFields, function (value, key) {
                        if (value.required) {
                            var valueField = angular.fromJson(value.value);
                            if (!valueField || !valueField.value) {
                                obligOK = false;
                            }
                        }
                    });

                    if (obligOK) {
						if(trans && trans.transactionType && trans.transactionType.transactionSubtype && (trans.transactionType.transactionSubtype == 12 || trans.transactionType.transactionSubtype == 14)){
							DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransactionReparation').then(function () {
	                        	TramFactory.completeDossierTransaction(trans, undefined, false, []).then(function (data) {
	                                var actualTransactionsWithPermission = [];
	                                if (data) {
	                                    data = JSOG.decode(data);
	                                    if(data.actualsTransaction) {
		                                    _.forEach(data.actualsTransaction, function (value) {
		                                    	if (value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
		                                			actualTransactionsWithPermission.push(value);
		                                		}
		                                    });
	                                    }
	                                    if(actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions && data.finishedTransaction) {
	                                        _.forEach($linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual").toArray(), function (value) {
	                                            if (data.finishedTransaction.id != value.id && value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
	                                            	actualTransactionsWithPermission.push(value);
	                                            }
	                                        });
	                                    }
	                                }
	                                if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
	                                    if (self && self.extra && self.extra.transaction && self.extra.transaction.documents) {
	                                        _.forEach(self.extra.transaction.documents, function (document) {
	                                            if (document.document && document.document.docStaus == "NEW_AND_ATTACHMENT" && document.relationType == "DOCUMENT") {
	                                                document.document.docStauts = "COMPLETE";
	                                            }
	                                        });
	                                    }
	                                    trans.actual = false;
	                                    if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
		                                    $state.transitionTo('annexa.tram.pending.viewdossier', {
		                                        "dossier": TramFactory.Dossier.id,
		                                        "dossierTransaction": actualTransactionsWithPermission[0].id
		                                    });
	                                    } else {
	                                    	$state.reload();
	                                    }
	                                } else if(TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
	                                	$state.reload();
	                                } else {
	                                    $state.transitionTo('annexa.tram.pending');
	                                }
	                                self.close();
	                            }).catch(function (error) {
	                                if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
										self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
									}else{
										self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
									}
	                            });
	                        }).catch(function (error) {
	                        });
						}else{
					    	var data = {
	                            row: true,
	                            colClass: ' col-sm-12',
	                            labelClass: ''
	                        };
	                        var modal = angular.copy(globalModals.completeTransactionPopup);
	                        modal.alerts = [];
	                        modal.annexaFormly = new AnnexaFormly();
	
	                        var fields = [];
	                        fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12', new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler_finally', false, false, [], $scope.searchProfiles, function () {
	                        }, 'Profile', 'global.literals.select', {transaction: this.transaction}, undefined, 'global.reg.literals.profileOrUserName', false, false, true), data));
	                        modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
	                        modal.annexaFormly.model = {};
	                        modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
	                        modal.annexaFormly.options = {};
	                        modal.annexaFormly.options.formState = {readOnly: false};
	                        modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = this.transaction;
	                        modal.extra = {transaction: this.transaction};
	                        AnnexaFormlyFactory.showModalPromise("modalCloseTransaction", modal, $scope.completeTransaction, false, false, 'global.tram.literals.completetransaction').then(function (modalAux) {
	                            modal.modal = modalAux;
	                            modal.close = modalAux.element.scope().close;
	                            modalAux.element.modal();
	                            angular.element(".modal-title").focus();
	                        }).catch(function () {
	                            var a = 0;
	                        });
						}
                    } else {
                        DialogsFactory.error('global.literals.customFieldOblig', $filter('translate')('DIALOGS_ERROR_MSG'));
                    }
	            };
                
                $scope.searchProfiles = function (value) {
                    if (!value || !value.val) {
                        return [];
                    } else if (value.val != '*' && value.val.length < 3) {
                        return [];
                    } else {
                        if (value.val == '*') {
                            value.val = '';
                        }
                        return RestService.filterData('common', 'Profile', {}, {search: value.val, langColumn:Language.getActiveColumn()}, 0, 10, '', 3)
                            .then(function (data) {
                                var items = [];

                                if (data.data && data.data.content) {
                                    _.forEach(JSOG.decode(data.data.content), function (item) {
                                        items.push({
                                            id: item.id,
                                            value: item[Language.getActiveColumn()],
                                            profile: item
                                        });
                                    })
                                }
                                return items;
                            }).catch(function () {
                                return [];
                            });
                    }
                };

				var inSubprocedureNotGuided = function(dt){
					var inSubNG = false;
					if(TramFactory.Dossier && TramFactory.Dossier.procedure && !TramFactory.Dossier.procedure.guided && dt && dt.subprocedure && dt.subprocedure.id){
						inSubNG = true;			
					}
					return inSubNG;	
				}   
                // Aqui nos iremos al formulario para completar un tramite
                $scope.completeTransaction = function () {
                    var self = this;
                    if (self.annexaFormly.model.modal_body.transactionNoGuiadaList && self.annexaFormly.model.modal_body.transactionNoGuiadaList.length > 0) {
                        if ($rootScope.esetMode && self.extra.transaction && self.extra.transaction.transactionType && self.extra.transaction.transactionType.finallyType && inSubprocedureNotGuided(self.extra.transaction)) {
                            self.alerts.push({msg: 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions'});
                        } else {
                            TramFactory.completeDossierTransaction(self.extra.transaction, undefined, false, self.annexaFormly.model.modal_body.transactionNoGuiadaList).then(function (data) {
                                var actualTransactionsWithPermission = [];
                                if (data) {
                                    data = JSOG.decode(data);
                                    if(data.actualsTransaction) {
                                        _.forEach(data.actualsTransaction, function (value) {
                                            if (value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                            	actualTransactionsWithPermission.push(value);
                                            }
                                        });
                                    }
                                    if(actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions && data.finishedTransaction) {
                                        _.forEach($linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual").toArray(), function (value) {
                                            if (data.finishedTransaction.id != value.id && value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                            	actualTransactionsWithPermission.push(value);
                                            }
                                        });
                                    }
                                }
                                if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                                    if (self && self.extra && self.extra.transaction && self.extra.transaction.documents) {
                                        _.forEach(self.extra.transaction.documents, function (document) {
                                            if (document.document && document.document.docStaus == "NEW_AND_ATTACHMENT" && document.relationType == "DOCUMENT") {
                                                document.document.docStauts = "COMPLETE";
                                            }
                                        });
                                    }
                                    self.extra.transaction.actual = false;
                                    if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
	                                    $state.transitionTo('annexa.tram.pending.viewdossier', {
	                                        "dossier": TramFactory.Dossier.id,
	                                        "dossierTransaction": actualTransactionsWithPermission[0].id
	                                    });
                                    } else {
                                    	$state.reload();
                                    }
                                } else if(TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                                	$state.reload();
                                } else {
                                    $state.transitionTo('annexa.tram.pending');
                                }
                                self.close();
                            }).catch(function (error) {
                                if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
									self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
								}else{
									self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
								}
                            });
                        }
                    } else {
                        if (TramFactory.isResponsible(TramFactory.Dossier) || AnnexaPermissionsFactory.havePermission('close_dossier_transaction') || (self.extra && self.extra.transaction && self.extra.transaction.transactionType 
                        		&& (self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id || 
                        				self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id ||
                        				($scope.haveInspectionInterventionOpen() && 
                        				(self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id ||
                        				self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))))) {
                            //TODO --> si sol en queda un i no es final no es podra finalitzar
                            var dossierTransactionIsFinal = false;
                            if (self.extra.transaction && self.extra.transaction.transactionType && self.extra.transaction.transactionType.finallyType) {
                                if(TramFactory.Dossier.procedure && !TramFactory.Dossier.procedure.guided){
									if(!self.extra.transaction.subprocedure){
										dossierTransactionIsFinal = true;		
									}
								}else{
			                    	dossierTransactionIsFinal = true;
								}
                            }
                            var openTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual == true").toArray();
                            var canCompleteEset = false;
                            var canCompleteNoEset = false;
                            if ($rootScope.esetMode) {
                                if ((openTransactions && openTransactions.length > 1 && !dossierTransactionIsFinal) || (openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal)) {
                                    canCompleteEset = true;
                                }
                            } else {
                                if ((openTransactions && openTransactions.length > 1) || (openTransactions && openTransactions.length == 1 && dossierTransactionIsFinal)) {
                                    canCompleteNoEset = true;
                                }
                            }
                            if (canCompleteEset || canCompleteNoEset) {
                                TramFactory.completeDossierTransaction(self.extra.transaction, undefined, false, undefined, true).then(function (data) {
                                    self.extra.transaction.actual = false;
                                    if (self && self.extra && self.extra.transaction && self.extra.transaction.documents) {
                                        _.forEach(self.extra.transaction.documents, function (document) {
                                            if (document.document && document.document.docStaus == "NEW_AND_ATTACHMENT" && document.relationType == "DOCUMENT") {
                                                document.document.docStauts = "COMPLETE";
                                            }
                                        });
                                    }
                                    var reloadStateTram = function(data){
                                    	var actualTransactionsWithPermission = [];
                                    	if (data) {
                                    		data = JSOG.decode(data);
                                    		if(data.actualsTransaction) {
                                        		_.forEach(data.actualsTransaction, function (value) {
                                        			if (value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                        				actualTransactionsWithPermission.push(value);
                                        			}
                                        		});
                                    		}
                                            if(actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions && data.finishedTransaction) {
                                                _.forEach($linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual").toArray(), function (value) {
                                                    if (data.finishedTransaction.id != value.id && value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                                    	actualTransactionsWithPermission.push(value);
                                                    }
                                                });
                                            }
                                    	}
                                    	if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                                    		if (self && self.extra && self.extra.transaction && self.extra.transaction.documents) {
                                    			_.forEach(self.extra.transaction.documents, function (document) {
                                    				if (document.document && document.document.docStaus == "NEW_AND_ATTACHMENT" && document.relationType == "DOCUMENT") {
                                    					document.document.docStauts = "COMPLETE";
                                    				}
                                    			});
                                    		}
                                    		self.extra.transaction.actual = false;
    	                                    if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
    		                                    $state.transitionTo('annexa.tram.pending.viewdossier', {
    		                                        "dossier": TramFactory.Dossier.id,
    		                                        "dossierTransaction": actualTransactionsWithPermission[0].id
    		                                    });
    	                                    } else {
    	                                    	$state.reload();
    	                                    }
                                    	} else if(TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                                    		$state.reload();
                                    	} else {
                                    		$state.transitionTo('annexa.tram.pending');
                                    	}
	                                    self.close();
                                    }
                                    if(self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id || self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id){
                                    	var dlg = dialogs.notify('',$filter('translate')("global.tram.literals.closeReparation"),{animation: true,backdrop: 'static',keyboard: true,size: ''});
                                        dlg.result.then(function(btn){reloadStateTram(data);}, function (btn) {reloadStateTram(data);});
                                    }else if(self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id || self.extra.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){
                                    	var dlg = dialogs.notify('',$filter('translate')("global.tram.literals.closeOmission"),{animation: true,backdrop: 'static',keyboard: true,size: ''});
                                        dlg.result.then(function(btn){reloadStateTram(data);}, function (btn) {reloadStateTram(data);});                                    	
                                    }else{
                                    	reloadStateTram(data);
                                    }
                                }).catch(function (error) {
                                    if (error.data && error.data.message && error.data.message == 'error-requestCompleteDossier-endDossier not found') {
                                        var dlg = dialogs.confirm(
                                            $filter('translate')('global.literals.confirmEndDossierTitle'),
                                            $filter('translate')('global.literals.confirmEndDossierBody'),
                                            {
                                                animation: true,
                                                backdrop: 'static',
                                                keyboard: true,
                                                size: '',
                                                backdropClass: 'zMax',
                                                windowClass: 'zMax2'
                                            }
                                        );

                                        dlg.result.then(function (btn) {
                                            TramFactory.endDossier(self.extra.transaction).then(function (data) {
                                                $state.transitionTo('annexa.tram.pending');
                                                self.close();
                                            }).catch(function (error) {
                                                if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
													self.alerts.push({msg:  $filter('translate')('global.tram.errors.processTemplate'), msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
												}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
													 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierCustomFields')});
												}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
													 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierTransactionCustomFields')});
												}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
													self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
												}else{
												  	self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
												}
                                            });
                                        }, function (btn) {
                                            var a = 0;
                                        });
                                    }else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
										self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
									} else {
                                        self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                                    }
                                });
                            } else {
                                if (dossierTransactionIsFinal) {
                                    self.alerts.push({msg: 'global.tram.errors.noCompleteFinallyTransactionHaveOpenTransactions'});
                                } else {
                                    self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                                }
                            }
                        } else {
                            var openTransactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual == true").toArray();
                            if (openTransactions && openTransactions.length > 1) {
                                self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                            } else {
                                self.alerts.push({msg: 'global.tram.errors.onlyResponsible'});
                            }
                        }
                    }
                };
                
                $scope.completeTransactionPopup = function () {
                    var haveNotificationCertificate = function(proposal){
                    	var count = 0;
                    	if(proposal && proposal.documents){
                    		_.forEach(proposal.documents, function(proposalDocument){
                    			if(proposalDocument.proposalDocumentType && proposalDocument.proposalDocumentType == 'SCR'){
                    				if(TramFactory.Dossier && TramFactory.Dossier.notifications){
                    					count = count + $linq(TramFactory.Dossier.notifications).count(function(x){
                    						var exist = false;
                    						if(x.documents){
                    							_.forEach(x.documents, function(doc){
                    								if(doc.id == proposalDocument.document.id){
                    									exist = true;
                    								}
                    							})
                    						}
                    						return exist;
                    					});
                    				}
                    			}
                    		});
                    	}
                    	if(count > 0){
                    		return true;
                    	}else{
                    		return false;
                    	}
                    }
                	var trans = this.transaction;
                    if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                        DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransaction').then(function () {
                        	pendingSingDocumentsFunction(trans);
                        }).catch(function () {
                            //Empty
                        });
                    } else if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id && !haveNotificationCertificate(this.proposal)) {
                        DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmCompleteTransactionTransfer').then(function () {
                        	pendingSingDocumentsFunction(trans);
                        }).catch(function () {
                            //Empty
                        });
                    } else if (trans.transactionType && trans.transactionType.transactionSubtype 
                    		&& trans.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
                        DialogsFactory.confirm('global.tram.literals.complete_transaction_title', 'global.tram.literals.confirmInspectionCompleteTransaction').then(function () {
                        	pendingSingDocumentsFunction(trans);
                        }).catch(function () {
                            //Empty
                        });
                    } else {
                    	pendingSingDocumentsFunction(trans);
                    }
                };

                var pendingSingDocumentsFunction = function (trans) {
                    var pendingSingDocuments = 0;
                    if (trans.documents) {
                        pendingSingDocuments = $linq(trans.documents).where("x => x.document.docStatus == 'PENDING_SIGN' || x.document.docStatus == 'NEW'").toArray();
                    }
                    if (pendingSingDocuments.length > 0) {
                        DialogsFactory.error('global.errors.pendingSingDocumentsOrNew', $filter('translate')('DIALOGS_ERROR_MSG'));
                    } else {
                        var notDefinitiveDocuments = 0;
                        if (trans.documents) {
                            notDefinitiveDocuments = $linq(trans.documents).where( function (x) {
                            	return ((x.document.docStatus == 'NEW_AND_ATTACHMENT'  || (x.document.docStatus == 'NEW' && x.document.physical)) && x.relationType == 'DOCUMENT');
                            }).toArray();
                        }
                        if (notDefinitiveDocuments.length > 0) {
                            DialogsFactory.confirm('global.literals.completeDocument', 'global.tram.literals.not_definitive_documents').then(function () {
                                if (trans.graphNodeId != undefined && trans.graphNodeId != "") {
                                    $scope.completeTransactionPopupGuiats();
                                }
                                else {
                                    $scope.completeTransactionPopupNoGuiats();
                                }
                            }).catch(function () {
                                //Empty
                            });
                        } else {
                            if (trans.graphNodeId != undefined && trans.graphNodeId != "") {
                               	$scope.completeTransactionPopupGuiats();
                            }
                            else {
                                $scope.completeTransactionPopupNoGuiats();
                            }
                        }
                    }
                };

                $scope.completeTransactionPopupGuiats = function () {
                    var trans = this.transaction;
                    var obligOK = true;
                    angular.forEach(trans.customFields, function (value, key) {
                        if (value.required) {
                            var valueField = angular.fromJson(value.value);
                            if (!valueField || !valueField.value) {
                                obligOK = false;
                            }
                        }
                    });

                    if (obligOK) {
                    	var showDossiersNonAgreementTrasactions = function(node){
                    		if (node) {
                                var dossiersNonAgreementTransactionsInfo = 
                              	  $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype != " 
                              			  + apiAdmin.transactionSubtypes[2].id + " && x.dossierTransactionDefinition.transactionType.transactionSubtype != " + apiAdmin.transactionSubtypes[5].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[6].id
                              			 + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[8].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[9].id
                              			 + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[10].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[11].id 
                              			+ "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[12].id + "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[13].id 
                              			+ "&& x.dossierTransactionDefinition.transactionType.transactionSubtype != "+ apiAdmin.transactionSubtypes[14].id +")").toArray();
                                dossiersNonAgreementTransactionsInfo = $linq(dossiersNonAgreementTransactionsInfo).where(function(x){
                              	  var isOkDTI = function(dti){
                              		  var dossiersNonAgreementTransactionsInfoAux =  ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[2].id 
                              			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[5].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[6].id
                               			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[8].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[9].id
                               			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[10].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[11].id 
                               			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[12].id && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[13].id 
                               			 && dti.dossierTransactionDefinition.transactionType.transactionSubtype != apiAdmin.transactionSubtypes[14].id))?true:false);
                              		  	if(!dossiersNonAgreementTransactionsInfoAux && !dti.dossierTransactionDefinition){
                              		  	if(dti.dossiersTransactionsInfo){
                                			  var isOk = false;
                                			  _.forEach(dti.dossiersTransactionsInfo, function(dtiAux){
                                				  if(!isOk){
                                					  isOk = isOkDTI(dtiAux);
                                				  }
                                			  });
                                			  return isOk;
                                		  }else{
                                			  return false;
                                		  }
                              		  	}
                                        return dossiersNonAgreementTransactionsInfoAux;
                              	  }
                              	  if(x && !x.dossierTransactionDefinition ){
                              		  if(x.dossiersTransactionsInfo){
                              			  var isOk = false;
                              			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                              				  if(!isOk){
                              					  isOk = isOkDTI(dti);
                              				  }
                              			  });
                              			  return isOk;
                              		  }else{
                              			  return false;
                              		  }
                              	  }else{
                              		  return true;
                              	  }
                                }).toArray();
                                node.dossiersTransactionsInfo = dossiersNonAgreementTransactionsInfo;
                                
                                var modal = angular.copy(globalModals.completeTransactionPopupGuided);
                                modal.annexaFormly.model = {};
                                modal.annexaFormly.model.modal_body = {};
                                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = trans;
                                modal.nextDossierTransactionInfo = node;
                                modal.transaction = trans;
                                AnnexaFormlyFactory.showModal("modalCloseTransaction", modal, $scope.completeTransactionGuiat, false);
                            } else {
                                TramFactory.isFinalTransaction(trans);
                            }
                    	}
                    	if($scope.existReparation() && ($scope.isInspectionIntervention() || $scope.isInspectionInterventionFree())){
                    		DialogsFactory.confirm('global.tram.literals.existReparation', 'global.tram.literals.existReparationClose').then(function () {
                        		TramFactory.getNextDossierTransactions(trans.id).then(function (data) {
                                    if (data) {
                                        var node = JSOG.decode(data);
                                        if(node.graphNodeType == "SelectAllClose" && node.dossiersTransactionsInfo && node.dossiersTransactionsInfo.length > 0 && node.dossiersTransactionsInfo[0].graphNodeType == "SelectOne"){
                                        	showDossiersNonAgreementTrasactions(node.dossiersTransactionsInfo[0]);
                                        }else{
                                        	showDossiersNonAgreementTrasactions(node);
                                        }
                                    } else {
                                        TramFactory.isFinalTransaction(trans);
                                    }
                                });
                       	 	}).catch(function () {
                                //Empty
                            });
                    	}else{
                    		TramFactory.getNextDossierTransactions(trans.id).then(function (data) {
                                if (data) {
                                    var node = JSOG.decode(data);
                                    if(($scope.isOmission() || $scope.isOmissionFree()) && !$scope.existInspectionIntervention() && node.graphNodeType == "SelectAllClose"){
                                       	showDossiersNonAgreementTrasactions(node.dossiersTransactionsInfo[0]);
                                    }else if(($scope.isInspectionInterventionFree() || $scope.isInspectionIntervention()) && node.graphNodeType == "SelectAllClose"){
                                    	showDossiersNonAgreementTrasactions(node.dossiersTransactionsInfo[0]);
                                    }else{
                                    	showDossiersNonAgreementTrasactions(node);
                                    }
                                } else {
                                    TramFactory.isFinalTransaction(trans);
                                }
                            });	
                    	}
                    } else {
                    	DialogsFactory.error('global.literals.customFieldOblig', $filter('translate')('DIALOGS_ERROR_MSG'));
                    }
                };
                $scope.completeTransactionGuiat = function (modal) {
                    var self = this;

                    var inputs = modal.annexaFormly.model.modal_body;

                    $scope.changeFreeNodes(this.nextDossierTransactionInfo);

                    TramFactory.completeDossierTransactionGuiat(this.transaction, this.nextDossierTransactionInfo).then(function (data) {
                    	var actualTransactionsWithPermission = [];
                    	var existTransactionsWithPermission = false;
                    	if (data && data.responsibleUser) {
                    		data = JSOG.decode(data);
                			if (data.responsibleUser && data.responsibleUser.id && data.responsibleUser.id == $rootScope.LoggedUser.id) {
                				actualTransactionsWithPermission.push(data);
                			}
                    	}
                    	if(actualTransactionsWithPermission.length == 0 && self && self.nextDossierTransactionInfo && 
                    			self.nextDossierTransactionInfo.graphNodeId && self.nextDossierTransactionInfo.dossiersTransactionsInfo) {
                    		var graphId = self.nextDossierTransactionInfo.graphNodeId;
                    		_.forEach(self.nextDossierTransactionInfo.dossiersTransactionsInfo, function (value) {
                                if (graphId.includes(value.graphNodeId) && value.active && value.responsibleUser && value.responsibleUser.user && value.responsibleUser.user.id == $rootScope.LoggedUser.id) {
                                	existTransactionsWithPermission = true;
                                }
                            });
                    	}
                        if(actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                            _.forEach($linq(TramFactory.Dossier.dossierTransactions).where("x => x.actual").toArray(), function (value) {
                                if (self.transaction.id != value.id && value.responsibleUser && value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                    actualTransactionsWithPermission.push(value);
                                }
                            });
                        }
                    	if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                            self.transaction.actual = false;
                            if (data.id && $stateParams.dossierTransaction != data.id) {
                                $state.transitionTo('annexa.tram.pending.viewdossier', {
                                    "dossier": TramFactory.Dossier.id,
                                    "dossierTransaction": data.id
                                });
                            } else if(actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        		$state.transitionTo('annexa.tram.pending.viewdossier', {
                        			"dossier": TramFactory.Dossier.id,
                        			"dossierTransaction": actualTransactionsWithPermission[0].id
                        		});
                            } else {
                            	$state.reload();
                            }
                    	} else if(existTransactionsWithPermission || TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                    		self.transaction.actual = false;
                    		$state.reload();
                    	} else  {
                    		$state.transitionTo('annexa.tram.pending');
                    	}
                        modal.close();
                    }).catch(function (error) {
                        if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							modal.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
						}else{
							modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
						}
                    });
                };

                $scope.changeFreeNodes = function (dossierTransactionInfo) {
                    if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                        if (dossierTransactionInfo && dossierTransactionInfo.profile && dossierTransactionInfo.profile.id) {
                            dossierTransactionInfo.profiles = [];
                            dossierTransactionInfo.profiles.push({id: dossierTransactionInfo.profile.id});
                        }
                    }
                    angular.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                        $scope.changeFreeNodes(value);
                    });
                };

                $scope.completeTransactionEndDossierTest = function (modal) {
                    var self = this;

                    TramFactory.endDossier(this.atributes.transaction).then(function (data) {
                        $state.transitionTo('annexa.tram.pending');
                        self.modal.close();
                    }).catch(function (error) {
                       if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							modal.alerts.push({msg: $filter('translate')('global.tram.errors.processTemplate'), msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
							 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierCustomFields')});
						}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
							 $scope.dossierAlerts.push({msg: $filter('translate')('global.tram.errors.dossierTransactionCustomFields')});
						}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							self.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
						}else{
		                	modal.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
						}
                    });
                };
                
                $scope.getDossierResponsibleUser = function () {
                	return ((TramFactory.Dossier && TramFactory.Dossier.responsibleUser)?TramFactory.Dossier.responsibleUser:undefined);
                }

                $scope.canModifyTransaction = function () {
                    var modifyTransaction = false;
                    if(TramFactory.Dossier && TramFactory.isArchivedDossier && (TramFactory.Dossier.dossierStatus == 'TRANSFER' || TramFactory.Dossier.dossierStatus == 'REJECTED' || (TramFactory.Dossier.dossierStatus == 'ARCHIVED' && !TramFactory.Dossier.archiveDefinitiveDate))){
                    	 modifyTransaction = true;
                    }else{
						if ($scope.transaction && $scope.transaction.processProfiles && $scope.transaction.processProfiles.length > 0) {
	                        if ($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles) {
	                            var existProfile = false;
	                            angular.forEach($scope.transaction.processProfiles, function (value, key) {
	                                if (!existProfile && value.profile && value.profile.id) {
	                                    var profiles = $linq($rootScope.LoggedUser.userProfiles).where("x => x.profile.id == " + value.profile.id).toArray();
	                                    if (profiles && profiles.length > 0) {
	                                        existProfile = true;
	                                    }
	                                }
	                            });
	                            if (existProfile) {
	                                if ($rootScope.esetMode && $scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.finallyType && !inSubprocedureNotGuided($scope.transaction)) {
	                                    if (TramFactory.isResponsible($scope.transaction.dossier)) {
	                                        modifyTransaction = true;
	                                    }
	                                } else {
	                                    modifyTransaction = true;
	                                }
	                            }
	                        }
						}
						if (!modifyTransaction && TramFactory.procedureTramitationProfiles && TramFactory.procedureTramitationProfiles.length > 0) {
	                        if ($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles) {
	                            var existProfile = false;
	                            angular.forEach(TramFactory.procedureTramitationProfiles, function (value, key) {
	                                if (!existProfile && value.profile && value.profile.id) {
	                                    var profiles = $linq($rootScope.LoggedUser.userProfiles).where("x => x.profile.id == " + value.profile.id).toArray();
	                                    if (profiles && profiles.length > 0) {
	                                        existProfile = true;
	                                    }
	                                }
	                            });
	                            if (existProfile) {
	                                if ($rootScope.esetMode && $scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.finallyType && !inSubprocedureNotGuided($scope.transaction)) {
	                                    if (TramFactory.isResponsible($scope.transaction.dossier)) {
	                                        modifyTransaction = true;
	                                    }
	                                } else {
	                                    modifyTransaction = true;
	                                }
	                            }
	                        }
						}
                    }
                    return modifyTransaction;
                }
                
              $scope.sendProposalDossierTransaction = function () {
                  var prop = this.proposal;
                  var titleConfirm = "global.tram.literals.confirmSendProposal";
                  if($scope.isInspectionDepartmentNext()){
                	  titleConfirm = "global.tram.literals.confirmSendProposalInspectionDepartment";
                  }else if($scope.isInspectionInterventionNext()){
                	  titleConfirm = "global.tram.literals.confirmSendProposalInspectionIntervention";
                  }
            	  DialogsFactory.confirm('global.tram.literals.sendProposal', titleConfirm).then(function () {
                	  //1.- comprovar que els camps estiguin informats
                      var propDossierTransaction = $scope.transaction;
                      var realProposalDossierTransaction = propDossierTransaction;
                	  if(propDossierTransaction && propDossierTransaction.transactionType && propDossierTransaction.transactionType.transactionSubtype != 1 && TramFactory.proposalsDossierTransaction && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                		  var pdtAux = $linq(TramFactory.proposalsDossierTransaction).firstOrDefault(undefined, "x=>x.proposal.id == "+prop.id+" && x.proposalDossierTransactionType == 'PROPOSAL'" )
                		  if(pdtAux && pdtAux.dossierTransaction){
                			  realProposalDossierTransaction = $linq(TramFactory.Dossier.dossierTransactions).firstOrDefault(undefined, "x=>x.id == "+pdtAux.dossierTransaction.id);
                		  }
                	  }
                      $scope.proposalDocumentsDT = [];
	                  if(prop && prop.proposalType && prop.proposalType.proposalTypeSubtype &&  prop.proposalType.proposalTypeSubtype == 'DECREE'){
	            			var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPD"];
	            		}else{            			
	            			var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPR"];
	            		}
	                  if(realProposalDossierTransaction && realProposalDossierTransaction.documents){
	                	  _.forEach(realProposalDossierTransaction.documents, function(documentDT){
	                		  if(documentDT && documentDT.document && documentDT.document.type && _.contains(documentTypeProp.id, documentDT.document.type.id+"")){
	                			  $scope.proposalDocumentsDT.push(documentDT);
	                		  }
	                	  });
	                  }
                      
                      var obligOK = true;
                      var requiredFields = [];
                      angular.forEach(prop, function (value, key) { 
                          if (key) {
                        	  switch (key) {
                        	  	case 'proposalType':
                        	  	case 'extract':
                        	  	case 'profile':
									if (value == undefined || value == null) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
									break;
                        	  	case 'notificatorProfile':
                        	  		if(!(prop && prop.proposalType && prop.proposalType.proposalTypeSubtype &&  prop.proposalType.proposalTypeSubtype == 'DECREE')){
    	                        	  	if (!value) {
	                        	  			obligOK = false;
	                        	  			requiredFields.push(key);
	                        	  		}
                        	  		}
                        	  		break;
                        	  	case 'notificatorResponsible':
                        	  		if(!(prop && prop.proposalType && prop.proposalType.proposalTypeSubtype &&  prop.proposalType.proposalTypeSubtype == 'DECREE')){
    	                        	  	if ($rootScope.esetMode && !value) {
	                        	  			obligOK = false;
	                        	  			requiredFields.push(key);
	                        	  		}
                        	  		}
                        	  		break;
                        	  	case 'anonymousThirds':
                        	  		if (value == undefined || value == null) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  	case 'urgent':
                        	  		if (value == undefined || value == null) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  	case 'organs':
                        	  		if(!(prop && prop.proposalType && prop.proposalType.proposalTypeSubtype &&  prop.proposalType.proposalTypeSubtype == 'DECREE')){
	                        	  		if (!value || value.length == 0) {
	                        	  			obligOK = false;
	                        	  			requiredFields.push(key);
	                        	  		} else {
	                        	  			var isAgreementOrgan = false;
	                        	  			_.forEach(value, function(proposalOrgan){
	                        	  				if (proposalOrgan.organ && proposalOrgan.organ.resolutionType == 'AGREEMENT') {
	                        	  					isAgreementOrgan = true;
	                        	  				}
	                        	  			});
	                        	  			
	                        	  			if (!isAgreementOrgan) {
	                                        	obligOK = false;
	                                          	requiredFields.push(key);
	                        	  			}
	                        	  		}
                        	  		}
                        	  		break;
                        	  	case 'documents':
                        	  		if ($scope.proposalDocumentsDT && $scope.proposalDocumentsDT.length > 1 
                        	  				&& value && value.length == 0) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		} else if ($scope.proposalDocumentsDT && $scope.proposalDocumentsDT.length > 1) {
                        	  			var existPropDoc = false;
                        	  			_.forEach(value, function(propDoc){
                        	  				if (propDoc.proposalDocumentType == 'SPR') {
                        	  					existPropDoc = true;
                        	  				}
                        	  			});
                        	  			if(!existPropDoc) {
                            	  			obligOK = false;
                            	  			requiredFields.push(key);
                        	  			}
                        	  		}
                        	  	case 'amendmentTransactionType':
                        	  	case 'transferTransactionType':
                        	  	case 'cancelTransactionType':
                        	  		if (!propDossierTransaction.graphNodeId && !value) {
                        	  			obligOK = false;
                        	  			requiredFields.push(key);
                        	  		}
                        	  		break;
                        	  }
                          } else {
                        	  obligOK = false;
                          }
                      });

                      if (obligOK) {
                    	  //2.- mirar si el document de la proposta està firmat
                          var proposalDocumentDossierTransactionSigned = 0;
                          var proposalDocumentNotFound = false;
                          if (realProposalDossierTransaction && realProposalDossierTransaction.documents && realProposalDossierTransaction.documents.length > 0
                        		  && prop && prop.documents && prop.documents.length > 0) { //primer mirem si s'ha informat des de la pantalla de proposta
                        	  var proposalDocument = $linq(prop.documents).where("x => x.proposalDocumentType != 'ADJ'").select("x => x.document").toArray();
                        	  var docsToCheck = [];
                        	  if(proposalDocument && proposalDocument.length > 0) {
                            	  docsToCheck = $linq(realProposalDossierTransaction.documents).intersect(proposalDocument, function(x,y){
                            		  if(_.contains(documentTypeProp.id, y.document.type.id.toString()) && x.id == y.document.id){
                            			  return true;
                            		  }
                            		  return false;
                            	  }).toArray();
                        	  } else {
                        		  docsToCheck = $linq(realProposalDossierTransaction.documents).where(function(x){
                            		  if(_.contains(documentTypeProp.id, x.document.type.id.toString())){
                            			  return true;
                            		  }
                            		  return false;
                            	  }).toArray();
                        	  }
                        	 
                        	  if(documentTypeProp.valid_states && documentTypeProp.valid_states.length > 0){
                        		  proposalDocumentDossierTransactionSigned = $linq(docsToCheck).where(function(x){
                        			  if(_.contains(documentTypeProp.valid_states, x.document.docStatus)){
                        				  return true;
                        			  }
                        			  return false;
                        		  }).toArray();                        		  
                        	  }else{
                        		  proposalDocumentDossierTransactionSigned = $linq(docsToCheck).where("x => x.document.docStatus == 'SIGNED'").toArray();
                        	  }
                          } else if ($scope.proposalDocumentsDT && $scope.proposalDocumentsDT.length > 0) { //si no s'ha seleccionat des de la pantalla de proposta
                        	  if(documentTypeProp.valid_states && documentTypeProp.valid_states.length > 0){
                        		  proposalDocumentDossierTransactionSigned = $linq($scope.proposalDocumentsDT).where(function(x){
                        			  if(_.contains(documentTypeProp.id, x.document.type.id.toString()) && _.contains(documentTypeProp.valid_states, x.document.docStatus)){
                        				  return true;
                        			  }
                        			  return false;
                        		  }).toArray();
                        	  }else{
                        		  proposalDocumentDossierTransactionSigned = $linq($scope.proposalDocumentsDT).where("x => x.document.docStatus == 'SIGNED'").toArray();
                        	  }
                          } else {
                        	  proposalDocumentNotFound = true;
                          }
                          if (proposalDocumentNotFound) {
                              DialogsFactory.error('global.sec.errors.noProposalDocumentCreated', $filter('translate')('DIALOGS_ERROR_MSG'));
                          } else if (proposalDocumentDossierTransactionSigned == 0 || proposalDocumentDossierTransactionSigned.length <= 0) {
                        	  DialogsFactory.error('global.sec.errors.noProposalDocumentSigned', $filter('translate')('DIALOGS_ERROR_MSG'));
                          } else {
                              if (propDossierTransaction.graphNodeId != undefined && propDossierTransaction.graphNodeId != "") {
                                  TramFactory.getNextDossierTransactions(propDossierTransaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                        	  if(node.graphNodeType == "SelectAllClose" && node.dossiersTransactionsInfo && node.dossiersTransactionsInfo.length > 0 && node.dossiersTransactionsInfo[0].graphNodeType == "SelectOne" && $scope.existReparation()){
                                        		  node = node.dossiersTransactionsInfo[0];
                                        	  }else if(($scope.isInspectionInterventionFree() || $scope.isInspectionIntervention()) && node.graphNodeType == "SelectAllClose"){
                                        		  node = node.dossiersTransactionsInfo[0];
                                              }
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[2].id + ")").toArray();
                                              if($scope.isInspectionDepartmentNext()){
                                            	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[6].id + ")").toArray();
                                              }else if($scope.isInspectionInterventionNext()){
                                            	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[5].id + ")").toArray();
                                              }
                                              dossiersAgreementTransactionsInfo = $linq(dossiersAgreementTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[10].id))?true:false);
                                                      if($scope.isInspectionDepartmentNext()){
                                                    	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id))?true:false);
                                                      }else if($scope.isInspectionInterventionNext()){
                                                    	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id))?true:false);
                                                      }
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return true;
                                            	  }
                                              }).toArray();
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                              modal.annexaFormly.model = {};
                                              modal.annexaFormly.model.modal_body = {};
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = propDossierTransaction;
                                              modal.nextDossierTransactionInfo = node;
                                              modal.transaction = propDossierTransaction;
                                              modal.extra = {proposal: prop};
                                              AnnexaFormlyFactory.showModal("sendProposalDossierTransaction", modal, $scope.sendGuidedProposalDossierTransaction, false);
                                          } else {
                                              TramFactory.isFinalTransaction(propDossierTransaction);
                                          }
                                      } else {
                                          TramFactory.isFinalTransaction(propDossierTransaction);
                                      }
                                  });
                              } else {
                                  var dataModal = {
                                      row: true,
                                      colClass: ' col-sm-12',
                                      labelClass: ''
                                  };
                                  var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
                                  modal.alerts = [];
                                  modal.annexaFormly = new AnnexaFormly();
                                  var fields = [];
                                  fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                                      new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler_finally', false, false, [], TramFactory.searchProfiles, function () {},
                                          'Profile', 'global.literals.select', {dossier: TramFactory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                                      , dataModal)
                                  );
                                  modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                                  modal.annexaFormly.model = {};
                                  modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                                  modal.annexaFormly.options = {};
                                  modal.annexaFormly.options.formState = {readOnly: false};
                                  modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = propDossierTransaction;
                                  modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.sendProposal = true;
                                  modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.nextIntervention = $scope.isInspectionInterventionNext();
                                  modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.nextDepartment= $scope.isInspectionDepartmentNext();
                                  modal.extra = {transaction: propDossierTransaction, proposal: prop};
                                  AnnexaFormlyFactory.showModalPromise("sendProposalDossierTransaction", modal, $scope.sendNoGuidedProposalDossierTransaction, false, false, 'global.literals.create').then(function (modalAux) {
                                      modal.modal = modalAux;
                                      modal.close = modalAux.element.scope().close;
                                      modalAux.element.modal();
                                      angular.element(".modal-title").focus();
                                  }).catch(function () {
                                      var a = 0;
                                  });
                              }
                          }
                      } else {
                    	  DialogsFactory.error(getDetailedRequiredFields(requiredFields), $filter('translate')('global.tram.literals.sendProposal'));
                      }
                  }).catch(function () {
                      //Empty
                  });
              };
              
              $scope.sendInspectionProposalDossierTransaction = function () {
                  var insProp = this.inspectionProposal;
                  var titleConfirm = "global.tram.literals.confirmSendInspectionProposal";
                  if($scope.isInspectionDepartmentFreeNext()){
                	  titleConfirm = "global.tram.literals.confirmSendInspectionProposalInspectionDepartment";
                  }else if($scope.isInspectionInterventionFreeNext()){
                	  titleConfirm = "global.tram.literals.confirmSendInspectionProposalInspectionIntervention";
                  }
            	  DialogsFactory.confirm('global.tram.literals.sendInspectionProposal', titleConfirm).then(function () {
            		  var obligOK = true;
                	  angular.forEach(insProp, function (value, key) {
                          if (key) {
//                        	  switch (key) {
//                        	  	case 'amendmentTransactionType':
//                        	  		if (!$scope.transaction.graphNodeId && !value) {
//                        	  			obligOK = false;
//                        	  			requiredFields.push(key);
//                        	  		}
//                        	  		break;
//                        	  }
                          } else {
                        	  obligOK = false;
                          }
                      });

                      if (obligOK) {
                	      if ($scope.transaction.graphNodeId != undefined && $scope.transaction.graphNodeId != "") {
                              TramFactory.getNextDossierTransactions($scope.transaction.id).then(function (data) {
                                  if (data) {
                                      var node = JSOG.decode(data);
                                      if (node) {
                                    	  if(node.graphNodeType == "SelectAllClose" && node.dossiersTransactionsInfo && node.dossiersTransactionsInfo.length > 0 && node.dossiersTransactionsInfo[0].graphNodeType == "SelectOne" && $scope.existReparation()){
                                    		  node = node.dossiersTransactionsInfo[0];
                                    	  }else if(($scope.isInspectionInterventionFree() || $scope.isInspectionIntervention()) && node.graphNodeType == "SelectAllClose"){
                                    		  node = node.dossiersTransactionsInfo[0];
                                          }
                                          var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[10].id + ")").toArray();
                                          if($scope.isInspectionDepartmentFreeNext()){
                                        	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[9].id + ")").toArray();
                                          }else if($scope.isInspectionInterventionFreeNext()){
                                        	  dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where("x => !x.dossierTransactionDefinition || !x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == "+ apiAdmin.transactionSubtypes[8].id + ")").toArray();
                                          }
                                          dossiersAgreementTransactionsInfo = $linq(dossiersAgreementTransactionsInfo).where(function(x){
                                        	  var isOkDTI = function(dti){
                                        		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[10].id))?true:false);
                                                  if($scope.isInspectionDepartmentFreeNext()){
                                                	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id))?true:false);
                                                  }else if($scope.isInspectionInterventionFreeNext()){
                                                	  dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id))?true:false);
                                                  }
                                                  return dossiersAgreementTransactionsInfoAux;
                                        	  }
                                        	  if(x && !x.dossierTransactionDefinition ){
                                        		  if(x.dossiersTransactionsInfo){
                                        			  var isOk = false;
                                        			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                        				  if(!isOk){
                                            				isOk = isOkDTI(dti);
                                        				  }
                                        			  });
                                        			  return isOk;
                                        		  }else{
                                        			  return false;
                                        		  }
                                        	  }else{
                                        		  return true;
                                        	  }
                                          }).toArray();
                                          node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                          var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                          modal.annexaFormly.model = {};
                                          modal.annexaFormly.model.modal_body = {};
                                          modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                          modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = $scope.transaction;
                                          modal.nextDossierTransactionInfo = node;
                                          modal.transaction = $scope.transaction;
                                          modal.extra = {inspectionProposal: insProp};
                                          AnnexaFormlyFactory.showModal("sendInspectionProposalDossierTransaction", modal, $scope.sendGuidedInspectionProposalDossierTransaction, false);
                                      } else {
                                          TramFactory.isFinalTransaction($scope.transaction);
                                      }
                                  } else {
                                      TramFactory.isFinalTransaction($scope.transaction);
                                  }
                              });
                          } else {
                              var dataModal = {
                                  row: true,
                                  colClass: ' col-sm-12',
                                  labelClass: ''
                              };
                              var modal = angular.copy(globalModals.sendProposalDossierTransactionPopup);
                              modal.alerts = [];
                              modal.annexaFormly = new AnnexaFormly();
                              var fields = [];
                              fields.push(modal.annexaFormly.createField('profile', 'annexaLoadUserAndComboButtonRow', 'col-sm-12',
                                  new AnnexaFormlyLoadUserFieldTemplateOptions('text', 'global.literals.profile_handler_finally', false, false, [], TramFactory.searchProfiles, function () {},
                                      'Profile', 'global.literals.select', {dossier: TramFactory.Dossier}, undefined, 'global.reg.literals.profileOrUserName', false, false, true)
                                  , dataModal)
                              );
                              modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                              modal.annexaFormly.model = {};
                              modal.annexaFormly.model.modal_body = {transactionNoGuiadaList: []};
                              modal.annexaFormly.options = {};
                              modal.annexaFormly.options.formState = {readOnly: false};
                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = $scope.transaction;
                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.sendInspectionProposal = true;
                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.nextIntervention = $scope.isInspectionInterventionFreeNext();
                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.nextDepartment= $scope.isInspectionDepartmentFreeNext();
                              modal.extra = {transaction: $scope.transaction, inspectionProposal: insProp};
                              AnnexaFormlyFactory.showModalPromise("sendInspectionProposalDossierTransaction", modal, $scope.sendNoGuidedInspectionProposalDossierTransaction, false, false, 'global.literals.create').then(function (modalAux) {
                                  modal.modal = modalAux;
                                  modal.close = modalAux.element.scope().close;
                                  modalAux.element.modal();
                                  angular.element(".modal-title").focus();
                              }).catch(function () {
                                  var a = 0;
                              });
                          }
                      } else {
                    	  DialogsFactory.error($filter('translate')('global.tram.literals.sendInspectionProposal'));
                      }
                  }).catch(function () {
                      //Empty
                  });
              };
             
              var getDetailedRequiredFields = function (requiredFields) {
                  var template = $filter('translate')('global.tram.errors.requiredSendProposalFields');

                  template += '<ul>';

                  _.forEach(requiredFields, function (requiredField) {
                      switch (requiredField) {
                          case 'proposalType':
                              template += '<li>' + $filter('translate')('global.sec.literals.proposalType') + '</li>';
                              break;
                          case 'extract':
                              template += '<li>' + $filter('translate')('global.literals.title') + '</li>';
                              break;
                          case 'profile':
                              template += '<li>' + $filter('translate')('global.sec.literals.created_profile') + '</li>';
                              break;
                          case 'notificatorProfile':
                              template += '<li>' + $filter('translate')('global.sec.literals.notificator_profile') + '</li>';
                              break;
                          case 'notificatorResponsible':
                              template += '<li>' + $filter('translate')('global.sec.literals.notificator_responsible') + '</li>';
                              break;
                          case 'anonymousThirds':
                              template += '<li>' + $filter('translate')('global.sec.literals.anonymReceiver') + '</li>';
                              break;
                          case 'urgent':
                              template += '<li>' + $filter('translate')('global.sec.literals.urgentProposal') + '</li>';
                              break;
                          case 'organs':
                              template += '<li>' + $filter('translate')('global.sec.literals.agreementOrgan') + '</li>';
                              break;
                          case 'documents':
                              template += '<li>' + $filter('translate')('global.sec.literals.proposalDocs') + '</li>';
                              break;
                          case 'amendmentTransactionType':
                              template += '<li>' + $filter('translate')('global.sec.literals.amendmentProposal') + '</li>';
                              break;
                          case 'transferTransactionType':
                              template += '<li>' + $filter('translate')('global.sec.literals.transferProposal') + '</li>';
                              break;
                          case 'cancelTransactionType':
                              template += '<li>' + $filter('translate')('global.sec.literals.cancelProposal') + '</li>';
                              break;
                      }
                  });

                  template += '</ul>';

                  return template;
              }

              $scope.sendNoGuidedProposalDossierTransaction = function (modal) {
                  var self = this;

                  TramFactory.sendProposalDossierTransaction(self).then(function (data) {      
                	  var actualTransactionsWithPermission = [];
                      if(data && data.newTransactions) {
                          data = JSOG.decode(data);
                          if(data.newTransactions.length > 0) {
                              _.forEach(data.newTransactions, function (value) {
                                  if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                                          value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                      actualTransactionsWithPermission.push(value);
                                  }
                              });
                          }
                      }
                      if (actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                          _.forEach(TramFactory.Dossier.dossierTransactions, function (value) {
                              if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                            		  value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	  actualTransactionsWithPermission.push(value);
                              }
                          });
                      }
                      if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    	  $scope.transaction.actual = false;
                    	  if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        	  $state.transitionTo('annexa.tram.pending.viewdossier', {
                                  "dossier": TramFactory.Dossier.id,
                                  "dossierTransaction": actualTransactionsWithPermission[0].id
                              });                    		  
	                      } else {
	                          $state.reload();
	                      }
                      } else if(TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                    	  $state.reload();
                  	  } else {
                          $state.transitionTo('annexa.tram.pending');
                      }
                      modal.close();
                  }).catch(function (error) {
                      modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                  });
              };
              
              $scope.sendGuidedProposalDossierTransaction = function (modal) {
                  var self = this;

                  $scope.changeFreeNodes(this.nextDossierTransactionInfo);

                  TramFactory.sendGuidedProposalDossierTransaction(this.transaction, modal.extra.proposal, this.nextDossierTransactionInfo).then(function (data) {
                      var actualTransactionsWithPermission = [];
                      if(data && data.newTransactions) {
                          data = JSOG.decode(data);
                          if(data.newTransactions.length > 0) {
                              _.forEach(data.newTransactions, function (value) {
                                  if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                                          value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                                      actualTransactionsWithPermission.push(value);
                                  }
                              });
                          }
                      }
                      if (actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                          _.forEach(TramFactory.Dossier.dossierTransactions, function (value) {
                              if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                            		  value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	  actualTransactionsWithPermission.push(value);
                              }
                          });
                      }
                      if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    	  $scope.transaction.actual = false;
                    	  if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        	  $state.transitionTo('annexa.tram.pending.viewdossier', {
                                  "dossier": TramFactory.Dossier.id,
                                  "dossierTransaction": actualTransactionsWithPermission[0].id
                              });                    		  
	                      } else {
	                          $state.reload();
	                      }
                      } else if(TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                    	  $state.reload();
                  	  } else {
                          $state.transitionTo('annexa.tram.pending');
                      }
                      modal.close();
                  }).catch(function (error) {
						if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							modal.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((error.data.message)?error.data.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
						}else if(error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
							modal.alerts.push({msg: 'global.documents.definitiveNotificationNoValidError'});
						}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                    	modal.alerts.push({msg:'global.tram.errors.inRestrictiveTransaction'});
	                    }else{
							modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
						}
                  });
              };
              
              $scope.sendNoGuidedInspectionProposalDossierTransaction = function (modal) {
                  var self = this;

                  TramFactory.sendInspectionProposalDossierTransaction(self).then(function (data) {
                      var actualTransactionsWithPermission = [];
                      data = JSOG.decode(data);
                      if(data.newTransactions) {
                    	  _.forEach(data.newTransactions, function (value) {
                        	  if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                            		  value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	  actualTransactionsWithPermission.push(value);
                              }
                          });
                      }
                      if (actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                          _.forEach(TramFactory.Dossier.dossierTransactions, function (value) {
                        	  if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                            		  value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	  actualTransactionsWithPermission.push(value);
                              }
                          });
                      }
                      if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    	  $scope.transaction.actual = false;
                    	  if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        	  $state.transitionTo('annexa.tram.pending.viewdossier', {
                                  "dossier": TramFactory.Dossier.id,
                                  "dossierTransaction": actualTransactionsWithPermission[0].id
                              });                    		  
	                      } else {
	                          $state.reload();
	                      }
                      } else if(TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                    	  $state.reload();
                  	  } else {
                          $state.transitionTo('annexa.tram.pending');
                      }
                      modal.close();
                  }).catch(function (error) {
                      modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                  });
              };
              
              $scope.sendGuidedInspectionProposalDossierTransaction = function (modal) {
                  var self = this;

                  $scope.changeFreeNodes(this.nextDossierTransactionInfo);

                  TramFactory.sendGuidedInspectionProposalDossierTransaction(this.transaction, modal.extra.inspectionProposal, this.nextDossierTransactionInfo).then(function (data) {
                      var actualTransactionsWithPermission = [];
                      var existTransactionsWithPermission = false;
                      data = JSOG.decode(data);
                      if(data.newTransactions && data.newTransactions.length > 0) {
                    	  _.forEach(data.newTransactions, function (value) {
                        	  if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                            		  value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	  actualTransactionsWithPermission.push(value);
                              }
                          });
                      }
                      if(actualTransactionsWithPermission.length == 0 && self && self.nextDossierTransactionInfo && self.nextDossierTransactionInfo.graphNodeId && self.nextDossierTransactionInfo.dossiersTransactionsInfo) {
                          var graphId = self.nextDossierTransactionInfo.graphNodeId;
                          _.forEach(self.nextDossierTransactionInfo.dossiersTransactionsInfo, function (value) {
                              if (graphId.includes(value.graphNodeId) && value.active && value.responsibleUser && value.responsibleUser.user && value.responsibleUser.user.id == $rootScope.LoggedUser.id) {
                                  existTransactionsWithPermission = true;
                              }
                          });
                      }
                      if (actualTransactionsWithPermission.length == 0 && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions) {
                          _.forEach(TramFactory.Dossier.dossierTransactions, function (value) {
                        	  if (value.actual && $scope.transaction.id != value.id && value.responsibleUser && 
                            		  value.responsibleUser.id && value.responsibleUser.id == $rootScope.LoggedUser.id) {
                            	  actualTransactionsWithPermission.push(value);
                              }
                          });
                      }
                      if (actualTransactionsWithPermission && actualTransactionsWithPermission.length > 0) {
                    	  $scope.transaction.actual = false;
                    	  if (actualTransactionsWithPermission[0].id && $stateParams.dossierTransaction != actualTransactionsWithPermission[0].id) {
                        	  $state.transitionTo('annexa.tram.pending.viewdossier', {
                                  "dossier": TramFactory.Dossier.id,
                                  "dossierTransaction": actualTransactionsWithPermission[0].id
                              });                    		  
	                      } else {
	                          $state.reload();
	                      }
                      } else if(existTransactionsWithPermission || TramFactory.isResponsible() || TramFactory.haveProcedureTramitationProfile()) {
                    	  $state.reload();
                  	  } else {
                          $state.transitionTo('annexa.tram.pending');
                      }
                      modal.close();
                  }).catch(function (error) {
                      modal.alerts.push({msg: ErrorFactory.getErrorMessage('proposal', 'send', error.data)});
                  });
              };

              $scope.isProposalTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isAgreementTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[2].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

			$scope.isOmissionAgreementTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[15].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isTransferTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[3].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isAmendmentTransaction = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isInspectionIntervention = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[5].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.isInspectionDepartment = function () {
                  if ($scope.transaction && $scope.transaction.transactionType 
                  		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[6].id) {
                      return true;
                  } else {
                  	return false;
                  }
              };
              $scope.isStartInspectionTransaction = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isAgreementFreeTransaction = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[10].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isInspectionInterventionFree = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isInspectionDepartmentFree = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isOmission = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isReparation = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isOmissionFree = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.isReparationFree = function(){
            	  if ($scope.transaction && $scope.transaction.transactionType 
                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id) {
                        return true;
                    } else {
                    	return false;
                    }
              };
              
              $scope.canCreateOmissionReparation = function(){
            	  if($scope.inspectionInterventionActive()){
            		  if($scope.proposal && $scope.proposal.canCreateOmissionReparation){
            			  if ($scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[5].id) {
            				  return true;
    	                  }
            		  }else if($scope.inspectionProposal && $scope.inspectionProposal.canCreateOmissionReparation){
            			  if ($scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[8].id) {
            				  return true;
    	                  }
            		  }
            	  }
            	  return false;
              };
              
              $scope.existReparation = function(){
            	  if($scope.inspectionInterventionActive()){
            		  if($scope.proposal){
            			  var dts = $linq(TramFactory.proposalsDossierTransaction).where("x => x.proposal.id == " + $scope.proposal.id+" && x.dossierTransaction.actual && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[12].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }else if($scope.inspectionProposal){
            			  var dts = $linq(TramFactory.inspectionProposalsDossierTransaction).where("x => x.inspectionProposal.id == " + $scope.inspectionProposal.id+" && x.dossierTransaction.actual && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[14].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }
            	  }
            	  return false;
              };
              
              $scope.existOmission = function(){
            	  if($scope.inspectionInterventionActive()){
            		  if($scope.proposal){
            			  var dts = $linq(TramFactory.proposalsDossierTransaction).where("x => x.proposal.id == " + $scope.proposal.id+" &&  x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[11].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }else if($scope.inspectionProposal){
            			  var dts = $linq(TramFactory.inspectionProposalsDossierTransaction).where("x => x.inspectionProposal.id == " + $scope.inspectionProposal.id+" && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[13].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }
            	  }
            	  return false;
              };
              
              $scope.existInspectionIntervention = function(){
            	  if($scope.inspectionInterventionActive()){
            		  if($scope.proposal){
            			  var dts = $linq(TramFactory.proposalsDossierTransaction).where("x => x.proposal.id == " + $scope.proposal.id+" && x.dossierTransaction.actual && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[5].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }else if($scope.inspectionProposal){
            			  var dts = $linq(TramFactory.inspectionProposalsDossierTransaction).where("x => x.inspectionProposal.id == " + $scope.inspectionProposal.id+" && x.dossierTransaction.actual && x.dossierTransaction.transactionType && x.dossierTransaction.transactionType.transactionSubtype == "+apiAdmin.transactionSubtypes[8].id).toArray();
    	                  if (dts && dts.length > 0) {
    	                	  return true;
    	                  }
            		  }
            	  }
            	  return false;
              };
              
              var getDtAux = function(dtinfs, subtype){
            	  var dossiersAgreementTransactionsInfo = $linq(dtinfs).where(function(x){
                	  var isOkDTI = function(dti){
                		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == subtype))?true:false);
                          return dossiersAgreementTransactionsInfoAux;
                	  }
                	  if(x && !x.dossierTransactionDefinition ){
                		  if(x.dossiersTransactionsInfo){
                			  var isOk = false;
                			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                				  if(!isOk){
                    				isOk = isOkDTI(dti);
                				  }
                			  });
                			  return isOk;
                		  }else{
                			  return false;
                		  }
                	  }else{
                		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))?true:false);
                	  }
                  }).toArray();
            	  return dossiersAgreementTransactionsInfo;
              }
              
              $scope.createOmission = function(){
            	  if($scope.canCreateOmissionReparation()){
            		  DialogsFactory.confirm('global.tram.literals.createOmissionText', 'global.tram.literals.confirmCreateOmissionText').then(function () {
            			  if($scope.proposal){
            				  if ($scope.transaction.graphNodeId != undefined && $scope.transaction.graphNodeId != "") {
            					  TramFactory.getNextDossierTransactions($scope.transaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[11].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                              modal.annexaFormly.model = {};
                                              modal.annexaFormly.model.modal_body = {};
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = $scope.transaction;
                                              modal.nextDossierTransactionInfo = node;
                                              modal.transaction = $scope.transaction;
                                              modal.extra = {proposal: $scope.proposal};
                                              AnnexaFormlyFactory.showModal("sendInspectionProposalDossierTransaction", modal,$scope.completeTransactionGuiat, false);
                                          }
                                      }
                                  });
            				  }else{
            					  TramFactory.createTramResposibleModal($scope.transaction, true, false, false);
            				  }
                		  }else if($scope.inspectionProposal){
                			  //lliure
                			  if ($scope.transaction.graphNodeId != undefined && $scope.transaction.graphNodeId != "") {
                				  TramFactory.getNextDossierTransactions($scope.transaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[13].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                              modal.annexaFormly.model = {};
                                              modal.annexaFormly.model.modal_body = {};
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = $scope.transaction;
                                              modal.nextDossierTransactionInfo = node;
                                              modal.transaction = $scope.transaction;
                                              modal.extra = {inspectionProposal: $scope.inspectionProposal};
                                              AnnexaFormlyFactory.showModal("sendInspectionProposalDossierTransaction", modal,$scope.completeTransactionGuiat, false);
                                          }
                                      }
                                  });
            				  }else{
            					  TramFactory.createTramResposibleModal($scope.transaction, true, false, true);
            				  }
                		  }
            		  }).catch(function () {
                          //Empty
                      });
            	  }else{
            		  DialogsFactory.error($filter('translate')('global.tram.literals.sendInspectionProposal'));
            	  }
            	  
              };
              
              $scope.createReparation = function(){
            	  if($scope.canCreateOmissionReparation()){
            		  DialogsFactory.confirm('global.tram.literals.createReparationText', 'global.tram.literals.confirmCreateReparationText').then(function () {
            			  if($scope.proposal){
            				  if ($scope.transaction.graphNodeId != undefined && $scope.transaction.graphNodeId != "") {
            					  TramFactory.getNextDossierTransactions($scope.transaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                        	  var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[12].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                              modal.annexaFormly.model = {};
                                              modal.annexaFormly.model.modal_body = {};
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = $scope.transaction;
                                              modal.nextDossierTransactionInfo = node;
                                              modal.transaction = $scope.transaction;
                                              modal.extra = {proposal: $scope.proposal};
                                              AnnexaFormlyFactory.showModal("sendInspectionProposalDossierTransaction", modal,$scope.completeTransactionGuiat, false);
                                          }
                                      }
                                  });
            				  }else{
            					  TramFactory.createTramResposibleModal($scope.transaction, false, true, false);
            				  }
                		  }else if($scope.inspectionProposal){
                			  //lliure
                			  if ($scope.transaction.graphNodeId != undefined && $scope.transaction.graphNodeId != "") {
                				  TramFactory.getNextDossierTransactions($scope.transaction.id).then(function (data) {
                                      if (data) {
                                          var node = JSOG.decode(data);
                                          if (node) {
                                              var dossiersAgreementTransactionsInfo = $linq(node.dossiersTransactionsInfo).where(function(x){
                                            	  var isOkDTI = function(dti){
                                            		  var dossiersAgreementTransactionsInfoAux = ((!dti.dossierTransactionDefinition || !dti.dossierTransactionDefinition.transactionType || dti.dossierTransactionDefinition.transactionType.transactionSubtype == null || dti.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (dti.dossierTransactionDefinition.transactionType != null && dti.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id))?true:false);
                                                      return dossiersAgreementTransactionsInfoAux;
                                            	  }
                                            	  if(x && !x.dossierTransactionDefinition ){
                                            		  if(x.dossiersTransactionsInfo){
                                            			  var isOk = false;
                                            			  _.forEach(x.dossiersTransactionsInfo, function(dti){
                                            				  if(!isOk){
                                                				isOk = isOkDTI(dti);
                                            				  }
                                            			  });
                                            			  return isOk;
                                            		  }else{
                                            			  return false;
                                            		  }
                                            	  }else{
                                            		  return ((!x.dossierTransactionDefinition.transactionType || x.dossierTransactionDefinition.transactionType.transactionSubtype == null || x.dossierTransactionDefinition.transactionType.transactionSubtype == undefined || (x.dossierTransactionDefinition.transactionType != null && x.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id))?true:false);
                                            	  }
                                              }).toArray();
                                              if(dossiersAgreementTransactionsInfo && dossiersAgreementTransactionsInfo.length == 1 && dossiersAgreementTransactionsInfo[0].graphNodeType == "SelectOne"){
                                                  if(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo && dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo.length > 0){
                                                  	dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo = getDtAux(dossiersAgreementTransactionsInfo[0].dossiersTransactionsInfo, apiAdmin.transactionSubtypes[14].id);
                                                  }	
                                              }
                                              node.dossiersTransactionsInfo = dossiersAgreementTransactionsInfo;
                                              var modal = angular.copy(globalModals.sendGuidedProposalDossierTransactionPopup);
                                              modal.annexaFormly.model = {};
                                              modal.annexaFormly.model.modal_body = {};
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = node;
                                              modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.transaction = $scope.transaction;
                                              modal.nextDossierTransactionInfo = node;
                                              modal.transaction = $scope.transaction;
                                              modal.extra = {inspectionProposal: $scope.inspectionProposal};
                                              AnnexaFormlyFactory.showModal("sendInspectionProposalDossierTransaction", modal,$scope.completeTransactionGuiat, false);
                                          }
                                      }
                                  });
            				  }else{
            					  TramFactory.createTramResposibleModal($scope.transaction, false, true, true);
            				  }
                		  }
            		  }).catch(function () {
                          //Empty
                      });
            	  }else{
            		  DialogsFactory.error($filter('translate')('global.tram.literals.sendInspectionProposal'));
            	  }
              };
              
              $scope.isInternalControl = function () {
                  if ($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.internal_control) {
                      return true;
                  } else {
                  	return false;
                  }
              };

              $scope.inspectionInterventionActive = function(){
            	  var active = false;
            	  if(TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.inspectionIntervention != undefined){
            		  if(TramFactory.Dossier.procedure.inspectionIntervention){
            			  active = true;
            		  }
            	  }else{
            		  var arcClassification = undefined;
            		  if(TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected && TramFactory.Dossier.archiveClassification.model.$selected.id){
            			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.archiveClassification.model.$selected.id);
            		  }
            		  if(arcClassification && arcClassification.inspectionIntervention){
            			  active = true;
            		  }else{
            			  if(TramFactory.Dossier && TramFactory.Dossier.procedure.archiveClassification && TramFactory.Dossier.procedure.archiveClassification.id){
                			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.procedure.archiveClassification.id);
                		  }
            			  if(arcClassification && arcClassification.inspectionIntervention){
                			  active = true;
                		  }
            		  }
            	  }
            	  return active;
              }
              
              $scope.isInspectionInterventionNext = function () {
            	  if($scope.inspectionInterventionActive()){
	                  if ($scope.transaction && $scope.transaction.transactionType 
	                  		&& ($scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id || $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id)) {
	                	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && !$rootScope.app.configuration.secretary_properties.inspection_department){
	                		  return true;
	                	  }else{
	                		  return false;
	                	  }
	                  }else if($scope.transaction && $scope.transaction.transactionType 
	                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[6].id){
	                	  return true;
	                  } else {
	                  	return false;
	                  }
            	  }else{
            		  return false;
            	  }
              };
              
              $scope.isInspectionDepartmentNext = function () {
            	  if($scope.inspectionInterventionActive()){
	            	  if ($scope.transaction && $scope.transaction.transactionType 
	                    		&& ($scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[1].id || $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[4].id)) {
	                  	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.inspection_department){
	                  		  return true;
	                  	  }else{
	                  		  return false;
	                  	  }
	                    } else {
	                    	return false;
	                    }
            	  }
              };
              
              $scope.isInspectionInterventionFreeNext = function () {
            	  if($scope.inspectionInterventionActive()){
	                  if ($scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
	                	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && !$rootScope.app.configuration.secretary_properties.inspection_department){
	                		  return true;
	                	  }else{
	                		  return false;
	                	  }
	                  }else if($scope.transaction && $scope.transaction.transactionType 
	                    		&& $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[9].id){
	                	  return true;
	                  } else {
	                  	return false;
	                  }
            	  }else{
            		  return false;
            	  }
              };
              
              $scope.isInspectionDepartmentFreeNext = function () {
            	  if($scope.inspectionInterventionActive()){
	            	  if ($scope.transaction && $scope.transaction.transactionType && $scope.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[7].id) {
	                  	  if($rootScope.app.configuration && $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.inspection_department){
	                  		  return true;
	                  	  }else{
	                  		  return false;
	                  	  }
	                    } else {
	                    	return false;
	                    }
            	  }
              };

              $scope.haveInspectionInterventionOpen = function () {
            	  if($scope.proposal && $scope.proposal.id){
	            	  var pdt = $linq(TramFactory.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'INSPECTION_INTERVENTION' && x.dossierTransaction && x.dossierTransaction.actual && x.proposal && x.proposal.id == "+$scope.proposal.id);
	            	  if(pdt && pdt.length > 0){
	            		  return true;
	            	  }
            	  }else if($scope.inspectionProposal && $scope.inspectionProposal.id){
            		  var pdt = $linq(TramFactory.inspectionProposalsDossierTransaction).lastOrDefault(undefined, "x => x.inspectionProposalDossierTransactionType == 'INSPECTION_INTERVENTION' &&  x.dossierTransaction && x.dossierTransaction.actual && x.inspectionProposal && x.inspectionProposal.id == "+$scope.inspectionProposal.id);
	            	  if(pdt && pdt.length > 0){
	            		  return true;
	            	  }
            	  }
            	  return false;
              };
              
              $scope.openInternalControl = function(){
            	  if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.internal_control_properties_client){
            		  if($rootScope.app.configuration.internal_control_properties_client.urlToCall
            				  && $rootScope.app.configuration.internal_control_properties_client.credentials
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrlParam 
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrl){
            			  var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlToCall);
                    	  var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
                    	  credentials.id = $rootScope.LoggedUser.id+"";
                    	  credentials.token = $rootScope.LoggedUser.token;
                    	  credentials = JSON.stringify(credentials);
                    	  var callbackUrlParam = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrlParam);
                    	  var callbackUrl = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrl);
                    	  var internalControlType = 'permanentControl';
                    	  if(TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.inspectionIntervention != undefined){
                    		  if(TramFactory.Dossier.procedure.inspectionIntervention){
                    			 if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    				 internalControlType = 'auditingFunction';
                    			 }
                    		  }
                    	  }else{
                    		  var arcClassification = undefined;
                    		  if(TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected && TramFactory.Dossier.archiveClassification.model.$selected.id){
                    			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.archiveClassification.model.$selected.id);
                    		  }
                    		  if(arcClassification && arcClassification.inspectionIntervention){
                    			  if(arcClassification.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    				  internalControlType = 'auditingFunction';
                     			 }
                    		  }else{
                    			  if(TramFactory.Dossier && TramFactory.Dossier.procedure.archiveClassification && TramFactory.Dossier.procedure.archiveClassification.id){
                        			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.procedure.archiveClassification.id);
                        		  }
                    			  if(arcClassification && arcClassification.inspectionIntervention){
                    				  if(arcClassification.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    					 internalControlType = 'auditingFunction';
                         			 }
                        		  }
                    		  }
                    	  }
                    	  callbackUrl = callbackUrl.replace('--permanentControl--',internalControlType);
                    	  if($scope.proposal){
                    		  callbackUrl = callbackUrl.replace('--externalRecordId--',$scope.proposal.externalRecordId);
                    	  }else if($scope.inspectionProposal){
                    		  callbackUrl = callbackUrl.replace('--externalRecordId--',$scope.inspectionProposal.externalRecordId);
                    	  }
                    	  window.open(url+encodeURIComponent(credentials)+callbackUrlParam+encodeURIComponent(callbackUrl), '_blank');            			  
            		  }
            	  }
              }
              
              $scope.openInternalControlRequest = function(){
            	  if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.internal_control_properties_client){
            		  if($rootScope.app.configuration.internal_control_properties_client.urlToCall
            				  && $rootScope.app.configuration.internal_control_properties_client.credentials
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrlParam 
            				  && $rootScope.app.configuration.internal_control_properties_client.requestDetail){
            			  var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlToCall);
                    	  var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
                    	  credentials.id = $rootScope.LoggedUser.id+"";
                    	  credentials.token = $rootScope.LoggedUser.token;
                    	  credentials = JSON.stringify(credentials);
                    	  var callbackUrlParam = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrlParam);
                    	  var callbackUrl = angular.copy($rootScope.app.configuration.internal_control_properties_client.requestDetail);
                    	  var internalControlType = 'permanentControl';
                    	  if(TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.inspectionIntervention != undefined){
                    		  if(TramFactory.Dossier.procedure.inspectionIntervention){
                    			 if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    				 internalControlType = 'auditingFunction';
                    			 }
                    		  }
                    	  }else{
                    		  var arcClassification = undefined;
                    		  if(TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected && TramFactory.Dossier.archiveClassification.model.$selected.id){
                    			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.archiveClassification.model.$selected.id);
                    		  }
                    		  if(arcClassification && arcClassification.inspectionIntervention){
                    			  if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    				  internalControlType = 'auditingFunction';
                     			 }
                    		  }else{
                    			  if(TramFactory.Dossier && TramFactory.Dossier.procedure.archiveClassification && TramFactory.Dossier.procedure.archiveClassification.id){
                        			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.procedure.archiveClassification.id);
                        		  }
                    			  if(arcClassification && arcClassification.inspectionIntervention){
                    				  if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    					 internalControlType = 'auditingFunction';
                         			 }
                        		  }
                    		  }
                    	  }
                    	  callbackUrl = callbackUrl.replace('--permanentControl--',internalControlType);
                    	  if($scope.proposal){
                    		  callbackUrl = callbackUrl.replace('--externalRecordId--',$scope.proposal.externalRecordId);
                    	  }else if($scope.inspectionProposal){
                    		  callbackUrl = callbackUrl.replace('--externalRecordId--',$scope.inspectionProposal.externalRecordId);
                    	  }
                    	  window.open(url+encodeURIComponent(credentials)+callbackUrlParam+encodeURIComponent(callbackUrl), '_blank');            			  
            		  }
            	  }
              }
              
              $scope.openInternalControlReparation = function(){
            	  if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.internal_control_properties_client){
            		  if($rootScope.app.configuration.internal_control_properties_client.urlToCall
            				  && $rootScope.app.configuration.internal_control_properties_client.credentials
            				  && $rootScope.app.configuration.internal_control_properties_client.callbackUrlParam 
            				  && $rootScope.app.configuration.internal_control_properties_client.requestRepair){
            			  var url = angular.copy($rootScope.app.configuration.internal_control_properties_client.urlToCall);
                    	  var credentials = angular.copy($rootScope.app.configuration.internal_control_properties_client.credentials);
                    	  credentials.id = $rootScope.LoggedUser.id+"";
                    	  credentials.token = $rootScope.LoggedUser.token;
                    	  credentials = JSON.stringify(credentials);
                    	  var callbackUrlParam = angular.copy($rootScope.app.configuration.internal_control_properties_client.callbackUrlParam);
                    	  var callbackUrl = angular.copy($rootScope.app.configuration.internal_control_properties_client.requestRepair);
                    	  var internalControlType = 'permanentControl';
                    	  if(TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.inspectionIntervention != undefined){
                    		  if(TramFactory.Dossier.procedure.inspectionIntervention){
                    			 if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    				 internalControlType = 'auditingFunction';
                    			 }
                    		  }
                    	  }else{
                    		  var arcClassification = undefined;
                    		  if(TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected && TramFactory.Dossier.archiveClassification.model.$selected.id){
                    			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.archiveClassification.model.$selected.id);
                    		  }
                    		  if(arcClassification && arcClassification.inspectionIntervention){
                    			  if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    				  internalControlType = 'auditingFunction';
                     			 }
                    		  }else{
                    			  if(TramFactory.Dossier && TramFactory.Dossier.procedure.archiveClassification && TramFactory.Dossier.procedure.archiveClassification.id){
                        			  arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.procedure.archiveClassification.id);
                        		  }
                    			  if(arcClassification && arcClassification.inspectionIntervention){
                    				  if(TramFactory.Dossier.procedure.inspectionInterventionType == 'AUDITING_FUNCTION'){
                    					 internalControlType = 'auditingFunction';
                         			 }
                        		  }
                    		  }
                    	  }
                    	  callbackUrl = callbackUrl.replace('--permanentControl--',internalControlType);
                    	  if($scope.proposal){
                    		  callbackUrl = callbackUrl.replace('--externalRecordId--',$scope.proposal.externalRecordId);
                    	  }else if($scope.inspectionProposal){
                    		  callbackUrl = callbackUrl.replace('--externalRecordId--',$scope.inspectionProposal.externalRecordId);
                    	  }
                    	  window.open(url+encodeURIComponent(credentials)+callbackUrlParam+encodeURIComponent(callbackUrl), '_blank');            			  
            		  }
            	  }
              }
              
              if (TramFactory.proposalsDossierTransaction && TramFactory.proposalsDossierTransaction.length > 0) {
              	var proposals = $linq(TramFactory.proposalsDossierTransaction).where("x => x.dossierTransaction.id == " + $scope.transaction.id).select("x => x.proposal").toArray();
              	if (proposals && proposals.length > 0) {
              		$scope.proposal = proposals[0];
              		if($scope.proposal && $scope.proposal.proposalType && $scope.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
                		$scope.transaction.proposalSubtype = 'DECREE';
                	}
              	}
              }
              
              if (TramFactory.inspectionProposalsDossierTransaction && TramFactory.inspectionProposalsDossierTransaction.length > 0) {
                	var inspectionProposals = $linq(TramFactory.inspectionProposalsDossierTransaction).where("x => x.dossierTransaction.id == " + $scope.transaction.id).select("x => x.inspectionProposal").toArray();
                	if (inspectionProposals && inspectionProposals.length > 0) {
                		$scope.inspectionProposal = inspectionProposals[0];
                	}
                }
              
              $scope.updateDossierTransactionLanguage = function(property, val){
                  TramFactory.updateDossierTransaction($scope.transaction)
                      .then(function(returnData) {
                    	  var returnDataDecoded = JSOG.decode(returnData);
                    	  var dtLanguage1 = (($scope.transaction)?$scope.transaction.language1:'');
                      	  var dtLanguage2 = (($scope.transaction)?$scope.transaction.language2:'');
                      	  var dtLanguage3 = (($scope.transaction)?$scope.transaction.language3:'');
                      }).catch(function (error) {
                    	  	if(property == 'language'){
                    	  		$scope.transaction.language1 = dtLanguage1;
                    	  		$scope.transaction.language2 = dtLanguage2;
                    	  		$scope.transaction.language3 = dtLanguage3;
	                      	}
	                      	if(error && error.data && error.data.message == 'Error call to SUITE'){
	                      		DialogsFactory.error($filter('translate')('global.errors.errorConnectSuite'));
	                      	}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                    		DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
	                    	}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
		                		DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
		                	}else{
	                      		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
	                      	}
                  });
              }      
              
              $scope.setDossierTransactionResponsibleUser = function(){
            	  $scope.transaction.responsibleUser = $scope.transaction.responsibleUser ? undefined : $rootScope.LoggedUser;
            	  
            	  TramFactory.updateDossierTransaction($scope.transaction)
	                  .then(function(returnData) {
	                      var returnDataDecoded = JSOG.decode(returnData);
	                      $scope.transaction.responsibleUser = returnDataDecoded.responsibleUser;
	                  }).catch(function (error) { 
						if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                    	DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
	                    }else{
							DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
						}
	              });
              }
              
              $scope.updateDossierTransactionResponsibleUser = function(property, val){
            	  if((property && val && !isNaN(val)) || (property == 'responsibleUser' && !val)) {
            		  if(val){
						$scope.transaction[property] = {id: val};
            		  }else{
						$scope.transaction[property] = undefined;
					  }
                      TramFactory.updateDossierTransaction($scope.transaction)
	                      .then(function(returnData) {
	                          var returnDataDecoded = JSOG.decode(returnData);
	                          $scope.transaction.responsibleUser = returnDataDecoded.responsibleUser;
	                          $scope.loadComboTransactionResponsibleUser();
	                      }).catch(function (error) { 
							if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                    		DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
		                    }else{
								DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
							}
	                  });
            	  }
              }
              
              $scope.printTransactionResponsibleUser = function(){
                  if($scope.transaction && $scope.transaction.responsibleUser){
                      var responsibleUser = "";
                      if ($scope.transaction.responsibleUser.name) {
                    	  responsibleUser = responsibleUser + $scope.transaction.responsibleUser.name + " ";
                      }
                      if ($scope.transaction.responsibleUser.surename1) {
                    	  responsibleUser = responsibleUser + $scope.transaction.responsibleUser.surename1 + " ";
                      }
                      if ($scope.transaction.responsibleUser.surename2) {
                    	  responsibleUser = responsibleUser + $scope.transaction.responsibleUser.surename2;
                      }
                      
                      return responsibleUser;
                  }else{
                      return "";
                  }
              }
              
              $scope.ammendmentProposal = function () {
                  var prop = this.proposal;
                  var modal = angular.copy(SecModals.amendmentProposal);
                  modal.alerts = [];
                  modal.annexaFormly.model = {};
                  modal.annexaFormly.model.modal_body = {};
                  modal.annexaFormly.options = {};
                  modal.annexaFormly.options.formState = {readOnly: false};

                  var confirmGenerateAmendment = function () {
                      if (this.annexaFormly.model.modal_body.reason) {
                      	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                      	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                          	SecFactory.updateStateProposal(prop.id, 'AMENDMENT', secretariatObservations).then(function(data) {
                          	    $state.reload();
                              }).catch(function(error) {
                              	DialogsFactory.notify('global.errors.unknownUpdate', $filter('translate')('DIALOGS_ERROR_MSG'));
                              })
                          }).catch(function (data) {
                          	//Empty
                          });        	
                          modal.close();
                      }
                  };

                  AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
              };
              
            }]
        }
    }])
    .directive('annexaDossierTransactionInformation', ['TramFactory', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', '$rootScope', function (TramFactory, AnnexaPermissionsFactory, GlobalDataFactory, Language, $rootScope) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '=',
                dossierResponsibleUser: '=',
                loggedUser: '='
            },
            link: function(scope, element, attr) {
            	scope.canEditResponsibleProfile = function() {
                	var res = AnnexaPermissionsFactory.havePermission('change_responsible_profile_dossier_transaction');
                	return res;
                };
            },
            templateUrl: './views/layout/viewdossier/general_left/information.html',
            controller: ['$scope', function ($scope) {
                $scope.isProfileOpen = false;
                $scope.updateTransaction = function (property, data) {
                    $scope.transaction[property] = data;
                    TramFactory.updateDossierTransaction($scope.transaction)
                        .then(function (data) {
                            var a = 0;
                        }).catch(function (error) {
                    });
                }
                $scope.informationLanguageColumn = "";
                if ($scope.languageColumn) {
                    $scope.informationLanguageColumn = "informationLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);
                }

                $scope.loadProcProfiles = function() {
	                $scope.procProfiles = [];
	
	                angular.forEach($scope.transaction.processProfiles, function (profile) {
	                	if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
        					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
        					if(dtpp){
		        				var users = $linq(dtpp.userProfiles).selectMany('x => x.user', '(p, obj) => p').toArray();
			                    users = $linq(users).where("x => !x.expiryDate && !x.deleted").distinctBy("x => x.id").toArray();
			
			                    $scope.procProfiles.push({profile: profile, users: users});
        					}
	                	}
	                });
                }
                $scope.loadProcProfiles();
                
                $scope.loadSelectableProfiles = function() {
                	var selectableOptions = [];
                    _.forEach(GlobalDataFactory.allProfiles, function (value, key) {
                    	var haveProfile = false;
                    	if($scope.transaction.processProfiles) {
                    		haveProfile = $scope.transaction.processProfiles.some(function (resProfile) {
                    			  return resProfile.profile.id === value.id;
                    			});
                    	}
                        if(value.expiryDate == null || haveProfile) {
                            selectableOptions.push(value);
                        }
                    });
                    return selectableOptions;
                }
                
                
                $scope.allProfiles = $linq($scope.loadSelectableProfiles()).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                
                $scope.updateResponsibleProfile = function (property, data) {  
                	TramFactory.updateDossierTransactionProcessProfile($scope.transaction.processProfiles[0].id, data.id)
	                    .then(function (data) {
	                    	$scope.transaction.processProfiles[0] = JSOG.decode(data);	 
	                    	if($scope.transaction.processProfiles[0] && $scope.transaction.processProfiles[0].profile && $scope.transaction.processProfiles[0].profile.id && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+$scope.transaction.processProfiles[0].profile.id) == 0){
	                    		TramFactory.dossierTransactionProcessProfiles.push($scope.transaction.processProfiles[0].profile);
	                    	}
	                    	$scope.loadProcProfiles();
							$rootScope.$broadcast('updatedResponsibleProfileLoadResponsableUser', {});
	                    	$scope.allProfiles = $linq($scope.loadSelectableProfiles()).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
	                    }).catch(function (error) {
	                });
                }
                
                $scope.updateOpenFrofileStatus = function () {
                    $scope.isProfileOpen = $scope.procProfiles.some(function (item) {
                        return item.isProfileOpen;
                    })
                }
            }]
        }
    }])
    .directive('annexaDossierTransactionCustomFields', ['TramFactory', '$filter', 'AnnexaEntityFactory', 'globalModals', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'AnnexaModalFactory', function (TramFactory, $filter, AnnexaEntityFactory, globalModals, $rootScope, AnnexaFormlyFactory, CommonAdminModals, AnnexaModalFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/customFields.html',
            controller: ['$scope', function ($scope) {
            	$scope.isArchivedDossier = false;
            	if(TramFactory.Dossier && TramFactory.isArchivedDossier && (TramFactory.Dossier.dossierStatus == 'TRANSFER' || TramFactory.Dossier.dossierStatus == 'REJECTED' || (TramFactory.Dossier.dossierStatus == 'ARCHIVED' && !TramFactory.Dossier.archiveDefinitiveDate))){
    				$scope.isArchivedDossier = true;
    			}
                angular.forEach($scope.transaction.customFields, function (value, key) {
                    if (value.value) {
                        var object = angular.fromJson(value.value);
                        value.valueFromJSON = object.value;
                        if (value.customField.backendType == "DATETIME") {
                            value.valueFromJSON = new Date(value.valueFromJSON);
                        }
                    } else {
                        if (value.customField.frontendType == 'MULTIPLESELECT' || value.customField.frontendType == 'CHECKBOX') {
                            value.valueFromJSON = [];
                        } else {
                            value.valueFromJSON = '';
                        }
                    }
                    if (value && value.customField && value.customField.backendType == "DECIMAL") {
                        if (value.customField.decimalPositions) {
                            value.step = '0.' + Array(value.customField.decimalPositions).join('0') + '1';
                        } else {
                            value.step = '0.01'
                        }
                    }
                });

                $scope.getFieldValue = function (field) {
                    var valueLiteral = '';
                    if (field.customField.frontendType == 'MULTIPLESELECT' || field.customField.frontendType == 'CHECKBOX') {
                        angular.forEach(field.valueFromJSON, function (valueJ, keyJ) {
                            angular.forEach(field.customField.listValues, function (value, key) {
                                if (value.value == valueJ) {
                                    if (valueLiteral) {
                                        valueLiteral = valueLiteral + ', ' + value[$scope.languageColumn];
                                    } else {
                                        valueLiteral = value[$scope.languageColumn];
                                    }
                                }
                            });
                        });
                    } else if (field.customField.frontendType == 'SELECT' || field.customField.frontendType == 'SELECT_LINKED' || field.customField.frontendType == 'RADIO') {
                        angular.forEach(field.customField.listValues, function (value, key) {
                            if (value.value == field.valueFromJSON) {
                                valueLiteral = value[$scope.languageColumn];
                            }
                        });
                    } else if (field.customField.frontendType == 'INPUT') {
                        if (field.customField.backendType == "DATETIME") {
                            valueLiteral = $filter('date')(field.valueFromJSON, 'dd/MM/yyyy');
                        } else if (field.customField.backendType == "DECIMAL") {
                            if (field.valueFromJSON) {
                                valueLiteral = field.valueFromJSON;
                                var valueLiteral_parts = ("" + valueLiteral).split('.');
                                if (valueLiteral_parts && valueLiteral_parts.length > 0) {
                                    if (valueLiteral_parts[1]) {
                                        if (field.customField && field.customField.decimalPositions) {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0." + valueLiteral_parts[1]).toFixed(field.customField.decimalPositions).replace('0.', ',');
                                        } else {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0." + valueLiteral_parts[1]).toFixed(2).replace('0.', ',');
                                        }
                                    } else {
                                        if (field.customField && field.customField.decimalPositions) {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + parseFloat("0.00").toFixed(field.customField.decimalPositions).replace('0.', ',');
                                        } else {
                                            valueLiteral = parseFloat(valueLiteral_parts[0]).toLocaleString() + ",00";
                                        }
                                    }
                                }
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        } else if (field.customField.backendType == "INTEGER") {
                            if (field.valueFromJSON) {
                                valueLiteral = field.valueFromJSON.toLocaleString();
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        } else if (field.customField.backendType == "ADDRESS"){
                        	if (field.valueFromJSON) {
                                valueLiteral = AnnexaEntityFactory.postalAddressRender(field.valueFromJSON);
                            } else {
                                valueLiteral = field.valueFromJSON;
                            }
                        } else {
                            valueLiteral = field.valueFromJSON;
                        }
                    } else if(field.customField.frontendType == 'JSON'){
                    	var maxLengthReadModeCustomFieldJson = 100;
                    	if ($rootScope.app.configuration.max_length_read_mode_custom_field_json && $rootScope.app.configuration.max_length_read_mode_custom_field_json.value > 0) {
                    		maxLengthReadModeCustomFieldJson = $rootScope.app.configuration.max_length_read_mode_custom_field_json.value;
                        }
                        if(field.valueFromJSON && field.valueFromJSON.length > maxLengthReadModeCustomFieldJson){
                            valueLiteral = field.valueFromJSON.substr(0,maxLengthReadModeCustomFieldJson) + '...';
                        }else{
                            valueLiteral = field.valueFromJSON;
                        }
                    }
                    return valueLiteral;
                }

                $scope.updateField = function (field, data) {
                    if (!field.required || (field.required && data)) {
                        var fieldValue = angular.fromJson(field.value);
                        if (fieldValue == null || fieldValue == undefined) {
                            fieldValue = {id: 'cf_' + field.customField.id, value: ''};
                        }
                        if (data && data instanceof Date) {
                            fieldValue.value = new Date(Date.UTC(data.getFullYear(), data.getMonth(), data.getDate(), 00, 00, 00));
                        } else {
                            fieldValue.value = data;
                        }
                        
                        if (fieldValue.value && field.customField.frontendType == 'JSON') {
                        	try {
                        		JSON.parse(fieldValue.value);
                            } catch (error) {
                    			return $filter('translate')("global.validation.json");
                    		}
                        }
                        
                        field.value = angular.toJson(fieldValue);
                        if (!field.dossierTransaction) {
                            field.dossierTransaction = {id: $scope.transaction.id};
                        }
                        TramFactory.updateDossierTransactionCustomField(field).then(function (data) {
                            if (field.customField.frontendType == 'SELECT') {
                                $scope.$broadcast('customFieldSelectSelected', {
                                    customField: field.customField.id,
                                    selectedValue: field.id
                                });
                            }
                        }).catch(function (error) {
                        });
                    }
                }

                $scope.getOptions = function (field) {
                    if (field.customField.frontendType == 'SELECT') {
                        return field.customField.listValues;
                    } else if (field.customField.frontendType == 'SELECT_LINKED') {
                        var options = [];

                        var parentField = $linq($scope.transaction.customFields).singleOrDefault(undefined, "x => x.customField.id == " + field.customField.linkedCustomField.id);

                        if (parentField) {
                            var parentValue = $linq(parentField.customField.listValues).singleOrDefault(undefined, "x => x.value == " + parentField.valueFromJSON);

                            if (parentValue) {
                                options = $linq(field.customField.listValues).where("x => x.parentValue.id == " + parentValue.id).toArray();
                            }
                        }

                        return options;
                    }
                }

                $scope.openJSONViewer = function(modelValue, name) {
        			var modal = angular.copy(CommonAdminModals.jsonViewer);
        			modal.data = modelValue;
        			modal.title = name;
        			AnnexaModalFactory.showModal('modalJsonViewer', modal);
                }
                
                $scope.openWindow = function(element, modelValue, field){
                	if($scope.canOpenWindow(modelValue)){
                		var modelValueString = ((modelValue && $scope.languageColumn && modelValue[$scope.languageColumn])?modelValue[$scope.languageColumn]:modelValue);
                		if(modelValueString){
                			window.open(modelValueString);
                		}else{
                			if((($scope.transaction && $scope.transaction.actual && $scope.isUpdateDossier) || $scope.isArchivedDossier) && field && !field.noEditable){
                				element.$show();
                			}
                		}
                	}else{
                		if((($scope.transaction && $scope.transaction.actual && $scope.isUpdateDossier) || $scope.isArchivedDossier) && field && !field.noEditable){
            				element.$show();
            			}
                	}
                }
                
                $scope.canOpenWindow = function(modelValue){
            		var exist = false;
                	if(modelValue){
                		var startUrls = ['http://','https://'];
                		if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.url_editable_inputs && $rootScope.app.configuration.url_editable_inputs.urls && $rootScope.app.configuration.url_editable_inputs.urls.length > 0){
                			_.forEach($rootScope.app.configuration.url_editable_inputs.urls, function(item){
                				startUrls.push(item);
                			});
                		}
                		var modelValueString = ((modelValue && $scope.languageColumn && modelValue[$scope.languageColumn])?modelValue[$scope.languageColumn]:modelValue);
                		if(modelValueString){
	                		_.forEach(startUrls, function(item){
	                			if(modelValueString.startsWith(item.toLowerCase())){
	                				exist = true;
	                			}
	                		});
                		}
                	}
                	return exist;
                }
                
                $scope.$on('customFieldSelectSelected', function (event, args) {
                    if (args.customField && args.selectedValue) {
                        var linkedFields = $linq($scope.transaction.customFields).where("x => x.customField.linkedCustomField && x.customField.linkedCustomField.id == " + args.customField).toArray();

                        _.forEach(linkedFields, function (field) {
                            $scope.updateField(field, '');
                            field.valueFromJSON = '';
                        });
                    }
                });
                
                $scope.openModalAddress = function (field){
                	var modal = angular.copy(globalModals.editAddressCF);
                	
                	modal.field = field;
                	modal.updateField = $scope.updateField;
                	
                	var defaultCountry = $rootScope.app.configuration.default_country;
                	if(defaultCountry && defaultCountry.value){
                        defaultCountry = defaultCountry.value;
                    }else{
                        defaultCountry = '';
                    }
                	
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    
                    modal.annexaFormly.model.modal_body = field.valueFromJSON;
                    
                    if(!modal.annexaFormly.model.modal_body.country && defaultCountry){
                        modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                    }
                    
                    modal.annexaFormly.model.modal_body.hasStates = true;
                    modal.annexaFormly.model.modal_body.hasCities = true;
                    modal.annexaFormly.model.modal_body.hasStreetTypes = true;
                    
                    modal.extra = this;
                    
                    
                    var submitFunction = function() { 
                    	var self = this;
                    	
                    	var address = {};
                        var addressAux = this.annexaFormly.model.modal_body;

                        address.country = addressAux.country;
                        if(addressAux.hasStates) {
                            if (addressAux.validState && addressAux.validState.id) {
                            	address.validState = addressAux.validState;
                            }
                        }else{
                        	address.stateAddress = addressAux.stateAddress;
                        }
                        if(addressAux.hasCities){
                            if(addressAux.validCity && addressAux.validCity.id) {
                            	address.validCity = addressAux.validCity;
                            }
                        }else{
                        	address.city = addressAux.city;
                        }
                        if(addressAux.hasStreetTypes && addressAux.streetType) {
                        	address.streetType = addressAux.streetType;
                        }
                        
                        address.street = addressAux.street;
                        address.isValidNumber = false;
                        address.numberAddres = addressAux.numberAddres;
                        address.floor = addressAux.floor;
                        address.block = addressAux.block;
                        address.door = addressAux.door;
                        address.stair = addressAux.stair;
                        address.postalCode = addressAux.postalCode;
                        
                        this.updateField(this.field, address);
                    	
                    	self.close();
                    }
                    
                    AnnexaFormlyFactory.showModal("modalNewAddress", modal, submitFunction, false);
                };
            }]
        }
    }])
    .directive('annexaDossierTransactionDocs', ['BoxFactory', 'TramFactory', '$filter', 'Language', 'AnnexaEntityFactory', 'RegFactory', 'HelperService', 'dialogs', 'ErrorFactory', 'NotificationFactory', 'DialogsFactory', 'DccumentsFactory',
        function (BoxFactory, TramFactory, $filter, Language, AnnexaEntityFactory, RegFactory, HelperService, dialogs, ErrorFactory, NotificationFactory, DialogsFactory, DccumentsFactory) {
            return {
                restrinct: 'E',
                replace: true,
                scope: {
                    transaction: '=',
                    order: '@',
                    languageColumn: '=',
                    viewData: '=',
                    isUpdateDossier: '=',
                    registerOutputBox: '=',
                    notificationBox: '='
                },
                templateUrl: './views/layout/viewdossier/general_left/docs.html',
                controller: ['$scope', 'HelperService', '$rootScope', 'GlobalDataFactory', function ($scope, HelperService, $rootScope, GlobalDataFactory) {
                    $scope.statuses = GlobalDataFactory.documentStatuses;
                    $scope.isArchivedDossier = false;
                	if(TramFactory.Dossier && TramFactory.isArchivedDossier && (TramFactory.Dossier.dossierStatus == 'TRANSFER' || TramFactory.Dossier.dossierStatus == 'REJECTED' || (TramFactory.Dossier.dossierStatus == 'ARCHIVED' && !TramFactory.Dossier.archiveDefinitiveDate))){
        				$scope.isArchivedDossier = true;
        			}
                    
                    if (($scope.transaction.actual && $scope.isUpdateDossier) || $scope.isArchivedDossier) {
                        $scope.documentBox = angular.copy(BoxFactory.DocumentChildBox).initialize('global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents', ($scope.isUpdateDossier || $scope.isArchivedDossier), ($scope.isUpdateDossier || $scope.isArchivedDossier), ($scope.isUpdateDossier || $scope.isArchivedDossier), ($scope.isUpdateDossier || $scope.isArchivedDossier));
                        if (TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected) {
                            $scope.documentBox.boxDefinition.archiveClassification = TramFactory.Dossier.archiveClassification;
                        } else if (TramFactory.Dossier && TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.archiveClassification) {
                            var arcClas = TramFactory.Dossier.procedure.archiveClassification;
                            $scope.documentBox.boxDefinition.archiveClassification = {
                                model: {
                                    $selected: {
                                        id: arcClas.id,
                                        title: arcClas[$scope.languageColumn]
                                    }
                                }
                            };
                        }
                    } else {
                        $scope.documentBox = angular.copy(BoxFactory.DocumentChildBox).initialize('global.literals.documents', 'global.literals.placeholser_search_documents', 'global.literals.advanced_search_documents');
                    }
                    $scope.documentBox.boxDefinition.transaction = $scope.transaction;
                    $scope.documentBox.boxDefinition.module = "EXPED";
                    $scope.documentBox.boxDefinition.pagination = true;
                    $scope.documentBox.boxDefinition.itemsPage = 15;
                    $scope.documentBox.boxDefinition.paginationAnchor = "exp-docs-" + $scope.transaction.id;
                    $scope.documentBox.boxDefinition.page = 0;
					$scope.documentBox.boxDefinition.showAssociateThirdDocuments = true;
					$scope.documentBox.boxDefinition.associateThirdDocuments = function(){
						DccumentsFactory.associateThirdDocuments($scope.documentBox.boxDefinition.content, TramFactory.Dossier.thirds, 'tram');
					}
                    //region Nous

                    var updateDocumentInList = function (document) {
                        if(document && document.id) {
                            var index = $linq($scope.documentBox.boxDefinition.content).indexOf("x => x.document.id == " + document.id);

                            if (index != -1) {
                                $scope.documentBox.boxDefinition.content[index].document.urgent = document.urgent;
                                $scope.documentBox.boxDefinition.content[index].document.docStatus = document.docStatus;
                                $scope.documentBox.boxDefinition.content[index].document.idPDFDocumentManager = document.idPDFDocumentManager;
                                $scope.documentBox.boxDefinition.content[index].document.filePDFTypeDoc = document.filePDFTypeDoc;
                                $scope.documentBox.boxDefinition.content[index].document.sizePDFDoc = document.sizePDFDoc;
                            }
                        }
                    }

                    $scope.$on('SendToSignModalFinished', function (event, args) {
                        if (args.data && args.data.length > 0) {
                            updateDocumentInList(args.data[0]);
                        }
                    });

                    $scope.$on('documentActionButtonExecuted', function (event, args) {
                        if (args.button) {
                            switch (args.button) {
                                case 'deleteDossierTransactionDocument':
                                    var indexDeleted = $linq($scope.documentBox.boxDefinition.content).indexOf("x => x.document.id == " + args.data.documentId);
                                    if (indexDeleted != -1) {
                                        $scope.documentBox.boxDefinition.content.splice(indexDeleted, 1);
                                        $rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
                                        $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                    }
                                    break;
                                case 'returnToDraft':
                                case 'complete':
                                    updateDocumentInList(args.data);
                                    break;
                            }
                        }
                    });
                    $scope.$on('associateThirdDocumentsTramFinish', function (event, args) {
	                    if (args.docs && args.docs.length > 0 && args.origin === 'tram') {
							_.forEach(args.docs, function(value){
		                        $scope.documentBox.boxDefinition.content.push({
		                            dossierTransaction: $scope.transaction,
                                    document:value,
		                            relationType: 'DOCUMENT',
		                            publicDocument: false
	                        	});
		                    });
							
							TramFactory.updateDossierTransaction($scope.transaction).then(function (data) {
	                            var docsTr = JSOG.decode(data).documents;
	                            if (docsTr) {
	                                $scope.transaction.documents.length = 0;
	                                angular.forEach(docsTr, function (value, key) {
	                                    $scope.transaction.documents.push(value);
	                                });
	                                $rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
	                                $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
	                                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: $scope.transaction.documents, relationType: 'DOCUMENT', transactionId: (($scope.transaction)?$scope.transaction.id:undefined) });
	                            }
								if(args.modal){
									args.modal.close()
								}
	                        }).catch(function (error) {
	                        	_.forEach(args.docs, function(value){
									$scope.transaction.documents.pop();
	                			});
					        	if(error && error.data && error.data.message == 'Error call to SUITE'){
	                        		DialogsFactory.error($filter('translate')('global.errors.errorConnectSuite'));
	                        	}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                        		DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
	                        	}else{
	                        		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
	                        	}
	                        });
	                    }
	                });
                    $scope.$on('created_multiple_notifications', function (event, args) {
              		   $rootScope.$broadcast('created_multiple_notifications_from_document', args);
                    });

                    $scope.$on('createdRegisterEntry', function (event, args) {
               		   $rootScope.$broadcast('createdRegisterEntry_from_document', args);
                    });
                    
                    //endregion

                    $scope.documentBox.boxDefinition.content = [];
                    if ($scope.transaction.documents) {
                        if ($scope.transaction && $scope.transaction.documents) {
                            var documentTrans = $linq($scope.transaction.documents).orderBy("x => x.createdDate").toArray();
                            $scope.transaction.documents.length = 0;
                            _.forEach(documentTrans, function (value) {
                                $scope.transaction.documents.push(value);
                            });

                        }
                        $scope.documentBox.boxDefinition.content = $scope.transaction.documents;
                    }
                    $scope.documentBox.boxDefinition.saveSearch = function (document) {
                        TramFactory.updateDossierTransaction($scope.transaction).then(function (data) {
                            var docsTr = JSOG.decode(data).documents;
                            if (docsTr) {
                                $scope.transaction.documents.length = 0;
                                angular.forEach(docsTr, function (value, key) {
                                    $scope.transaction.documents.push(value);
                                });
                                $rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
                                $rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: $scope.transaction.documents, relationType: 'DOCUMENT', transactionId: (($scope.transaction)?$scope.transaction.id:undefined) });
                            }
                        }).catch(function (error) {
                        	if(Array.isArray(document)){
                        		for(var i=0; i<document.length; i++){
                        			$scope.transaction.documents.pop();
                        		}
                        	}else{
                        		$scope.transaction.documents.pop();
                        	}
                        	if(error && error.data && error.data.message == 'Error call to SUITE'){
                        		DialogsFactory.error($filter('translate')('global.errors.errorConnectSuite'));
                        	}else if(error && error.data && error.data.message ==  'error-requestCompleteDossier-is-in-restictive-transaction not found'){
	                        	DialogsFactory.error($filter('translate')('global.tram.errors.inRestrictiveTransaction'));
                        	}else{
                    			DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
                    		}
                        });
                    };
                    if (!$scope.isUpdateDossier) {
                        $scope.documentBox.boxDefinition.showTemplates = false;
                    } else if (!$scope.transaction.actual) {
                        $scope.documentBox.boxDefinition.showTemplates = false;
                    } else {
                        $scope.documentBox.boxDefinition.showTemplates = true;
                    }
                    $scope.documentBox.boxDefinition.placeholderTemplate = $filter('translate')('global.tram.literals.placeholderTemplate');
                    $scope.documentBox.boxDefinition.fromTemplate = function () {
                        AnnexaEntityFactory.showNewDocumentFromTemplateModal($scope.transaction, new SortedArray($scope.viewData.tramTemplates, Language.getActiveColumn()).sort(), new SortedArray($scope.viewData.documentTypes, Language.getActiveColumn()).sort(), $scope.documentBox.boxDefinition);
                    };
                    $scope.documentBox.boxDefinition.actualTransaction = $scope.transaction.actual;
                    
                }]
            }
        }])
    .directive('annexaDossierTransactionTasks', ['$rootScope', 'BoxFactory', 'TramFactory', 'TaskFactory', 'RestService', 'AnnexaEntityFactory', 'DialogsFactory', function ($rootScope, BoxFactory, TramFactory, TaskFactory, RestService, AnnexaEntityFactory, DialogsFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/tasks.html',
            controller: ['$scope', function ($scope) {
            	$scope.isArchivedDossier = false;
            	if(TramFactory.Dossier && TramFactory.isArchivedDossier && (TramFactory.Dossier.dossierStatus == 'TRANSFER' || TramFactory.Dossier.dossierStatus == 'REJECTED' || (TramFactory.Dossier.dossierStatus == 'ARCHIVED' && !TramFactory.Dossier.archiveDefinitiveDate))){
    				$scope.isArchivedDossier = true;
    			}
                
            	var addTaskSave = function(newTask) {
                    if(newTask){
                        TramFactory.updateDossierTransaction($scope.transaction).then(function (data) {
                        }).catch(function (error) {
                        	console.error(error);
                        });
                    }
                }
                
                $scope.taskBox = {
                	// s'afegeixen aquestos permissos per poder veure els botons i la cerca (en cas que sigui el tràmit actual d'un expedient en edició)
                    permissions: (($scope.isUpdateDossier && $scope.transaction.actual) || $scope.isArchivedDossier) ? { new: 'process_dossier', view: 'process_dossier' } : {},
                    search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: $scope.isUpdateDossier ? addTaskSave : undefined },
                    content: $scope.transaction.tasks,
                    new: {},
                    isEdit: true,
                    config: { isTramBox: $scope.transaction },
                    origin: 'annexa-dossier-transaction-tasks'
                }

                $scope.$on('annexaBoxTasksDelete', function (event, args) {
                    if(args.origin && args.origin == 'annexa-dossier-transaction-tasks' && $scope.isUpdateDossier && $scope.transaction.actual) {
                        TramFactory.deleteTask($scope.transaction.id, args.removedId).then(function (data) {
                        }).catch(function (error) {
                        	console.error(error);
                        });
                     }
                });
            }]
        }
    }])
    .directive('annexaDossierTransactionProposal', ['TramFactory', 'RestService', 'GlobalDataFactory', 'SecFactory', 'DialogsFactory', 'ErrorFactory', 'apiAdmin', 'AnnexaFormlyFactory',
    		function (TramFactory, RestService, GlobalDataFactory, SecFactory, DialogsFactory, ErrorFactory, apiAdmin, AnnexaFormlyFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                proposal: '=?',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '=',
				operationsActive: '=?'
            },
            templateUrl: './views/layout/viewdossier/general_left/proposal.html',
            controller: ['$scope', '$filter', '$rootScope', function ($scope, $filter, $rootScope) {
				if($scope.proposal && $scope.proposal.id && TramFactory && TramFactory.proposalsDecree && TramFactory.proposalsDecree.length > 0){
					$scope.decree = $linq(TramFactory.proposalsDecree).firstOrDefault(undefined, "x => x.proposal && x.proposal.id == "+$scope.proposal.id); 
					if($scope.decree){// && $scope.decree.decreeDelegatedType && $scope.decree.decreeDelegatedType.id ){
						$scope.decreeDateSelected = (($scope.decree.decreeDate)?$filter('date')(new Date($scope.decree.decreeDate), 'dd/MM/yyyy HH:mm:ss'):'');
						$scope.decreeDelegatedTypeSelected = (($scope.decree.decreeDelegatedType && $scope.decree.decreeDelegatedType[$scope.languageColumn])?$scope.decree.decreeDelegatedType[$scope.languageColumn]:''); 
					}
				}
				//region basic info
            	$scope.proposalTypes = GlobalDataFactory.proposalTypes;
            	$scope.proposalTypeSelected = function() {
        			if($scope.proposal && $scope.proposal.proposalType) {
        				return $scope.proposal.proposalType[$scope.languageColumn];
                    } else {
            			return '';
                    }
                }
            	$scope.notificatorProfiles = [];
            	var notificatorProfilesTransaction = [];
            	if($scope.transaction && $scope.transaction.processProfiles){
            		angular.forEach($scope.transaction.processProfiles, function (profile) {
        				if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
        					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
        					if(dtpp){
        						notificatorProfilesTransaction.push(dtpp);        						
        					}
        				}
        			});  
                }
            	
            	var secretary = GlobalDataFactory.secretaryProfile;
            	if(!secretary){
            		secretary = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
            	}
            	if(notificatorProfilesTransaction) {
            		_.forEach(notificatorProfilesTransaction, function(notificatorProfileTransaction){
            			if(notificatorProfileTransaction && secretary 
            					&& notificatorProfileTransaction.id != secretary.id){
            				$scope.notificatorProfiles.push(notificatorProfileTransaction);
                        }
                    });
            		if(secretary){
            			$scope.notificatorProfiles.push(secretary);
            		}
            	}
            	
            	$scope.notificatorProfileSelected = function() {
        			if($scope.proposal && $scope.proposal.notificatorProfile) {
        				return $scope.proposal.notificatorProfile[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }

            	$scope.dtProfiles = [];
            	if($scope.transaction && $scope.transaction.processProfiles){
            		angular.forEach($scope.transaction.processProfiles, function (profile) {
        				if(TramFactory.dossierTransactionProcessProfiles && $linq(TramFactory.dossierTransactionProcessProfiles).count("x => x.id == "+profile.profile.id) > 0){
        					var dtpp = $linq(TramFactory.dossierTransactionProcessProfiles).firstOrDefault(undefined,"x => x.id == "+profile.profile.id)
        					if(dtpp){
        						$scope.dtProfiles.push(dtpp);        						
        					}
        				}
        			});
                }
            	
            	$scope.creatorProfileSelected = function() {
        			if($scope.proposal && $scope.proposal.profile) {
        				return $scope.proposal.profile[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }

            	$scope.notificatorResponsibles = [];

            	$scope.$on('getNotificatorResponsibles', function (event, args) {
            		$scope.notificatorResponsibles = [];
                    if (args.notificatorProfile && args.notificatorProfile.userProfiles) {
                        angular.forEach(args.notificatorProfile.userProfiles, function (userProfile) {
                        	if (userProfile && userProfile.user && userProfile.user.name) {
                        		userProfile.user.completeName = userProfile.user.name + ' ' + userProfile.user.surename1 + ((userProfile.user.surename2) ? ' ' + userProfile.user.surename2 + '\n' : '\n');
                        		$scope.notificatorResponsibles.push(userProfile.user);
                        	}
                        });
                    }	
                });
                
            	if ($rootScope.esetMode && $scope.proposal && $scope.proposal.notificatorProfile) $scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: $scope.proposal.notificatorProfile});

            	$scope.notificatorResponsibleSelected = function() {
        			if($scope.proposal && $scope.proposal.notificatorResponsible && $scope.proposal.notificatorResponsible.completeName) {
        				return $scope.proposal.notificatorResponsible.completeName;
                    } else {
            			return '';
                    }
                }

            	$scope.popupNotificatorResponsibles = function () {
                    var dataModal = {
                        row: true,
                        colClass: 'col-sm-12',
                        labelClass: ''
                    };
                    var modal = {
                        title: 'global.tram.literals.selectResponsible',
                        size: 'modal-sm',
                        icon: '',
                        submitModal: function () {
                        },
                        alerts: []		
                    }
                    modal.annexaFormly = new AnnexaFormly();
                    
                    var saveProfilesAndResponsibles = function(){
                    	if (modal.annexaFormly.model.modal_body.profile && modal.annexaFormly.model.modal_body.selectedResponsible) {
                    		$scope.proposal.notificatorProfile = modal.annexaFormly.model.modal_body.profile;
                    		$scope.proposal.notificatorResponsible = modal.annexaFormly.model.modal_body.selectedResponsible;
                    		
                            RestService.update('./api/sec/proposal/' + $scope.proposal.id, $scope.proposal).then(function (data) {
                            	$scope.proposal.proposalNumber = data.proposalNumber;
                            	if ($scope.proposal.notificatorResponsible) $scope.proposal.notificatorResponsible.completeName = $scope.proposal.notificatorResponsible.name + ' ' + $scope.proposal.notificatorResponsible.surename1 + (($scope.proposal.notificatorResponsible.surename2) ? ' ' + $scope.proposal.notificatorResponsible.surename2 + '\n' : '\n');
                            	$scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: $scope.proposal.notificatorProfile});
                            	modal.close();
                            }).catch(function (error) {
                                console.error(error);
                            });
                    	} else {
                    		modal.alerts.push({msg: 'global.tram.errors.noProfileNoResponsibleSelected'});
                    	} 
                    }
                    
                    var fields = [];
                    fields.push(modal.annexaFormly.createField('profile','annexaSelectResponsibleRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.tram.literals.selectProfileAndResponsible','id', $scope.languageColumn, $scope.notificatorProfiles, true), dataModal));
                    fields[0].templateOptions.profileId = $scope.proposal.notificatorProfile ? $scope.proposal.notificatorProfile.id : undefined;
                    fields[0].templateOptions.responsibleId = $scope.proposal.notificatorResponsible ? $scope.proposal.notificatorResponsible.id : undefined;
                    modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    AnnexaFormlyFactory.showModalPromise("updateNotificatorResponsible", modal, saveProfilesAndResponsibles, false, false, 'global.literals.select').then(function (modalAux) {
                        modal.modal = modalAux;
                        modal.close = modalAux.element.scope().close;
                        modalAux.element.modal();
                        angular.element(".modal-title").focus();
                    }).catch(function () {
                        var a = 0;
                    });
                
                }
            	
            	$scope.comboYesNo = [
                    { id: true, description: $filter('translate')('global.literals.yes')},
                    { id: false, description: $filter('translate')('global.literals.no')}
                ];

                $scope.printAnonymizeThirds = function(){
                    if($scope.proposal && $scope.proposal.anonymousThirds){
                        return $filter('translate')('global.literals.yes');
                    } else {
                        return $filter('translate')('global.literals.no');
                    }
                }
                
                $scope.printUrgentProposal = function(){
                    if($scope.proposal && $scope.proposal.urgent){
                        return $filter('translate')('global.literals.yes');
                    } else {
                        return $filter('translate')('global.literals.no');
                    }
                }
                
                $scope.allFooterClaims = GlobalDataFactory.footerClaims;
                $scope.footerClaimsAux = [];
                
            	if($scope.proposal && $scope.proposal.footerClaims){
            		$scope.footerClaimsAux = $linq($scope.proposal.footerClaims).select("x => x.footerClaim").toArray();
            	}

            	$scope.printFooterClaims = function() {
        			var content = '';
        			if($scope.footerClaimsAux && $scope.footerClaimsAux.length > 0) {
                        _.forEach($scope.footerClaimsAux, function(footerClaim){
                        	content += ((content == '')?'':', ') + footerClaim[$scope.languageColumn];
                        });
                    }
        			return content;
                }
            	
            	$scope.showComplementaryDocuments = function() {
            		if($scope.proposal && $scope.proposalSelectableDocuments && $scope.proposalSelectableDocuments.length > 0) {
            			if($scope.proposal.documents && $scope.proposal.documents.length > 0 && !$scope.canEdit) {
            				return $linq($scope.proposal.documents).contains("ADJ", "(x, y) => x.proposalDocumentType == y");
            			} else {
                			return true;
            			}
            		} else if($scope.proposal && $scope.proposal.documents && $scope.proposal.documents.length > 0) {
            			return $linq($scope.proposal.documents).contains("ADJ", "(x, y) => x.proposalDocumentType == y");
            		}
            		return false;
            	}
            	
                $scope.updateProposal = function(val, prop) {
                	if(prop == 'footerClaims') {
	               		var footerClaims = [];
	            		_.forEach(val, function(fc){
	            			var fcAct = $linq($scope.proposal.footerClaims).where("x => x.footerClaim.id == "+fc.id).toArray();
	            			if(fcAct && fcAct.length > 0){
	            				footerClaims.push(fcAct[0]);
	            			}else{
	            				footerClaims.push({proposal:{id:$scope.proposal.id}, footerClaim:{id:fc.id}});
	            			}
	            		});
	            		$scope.proposal.footerClaims = footerClaims;
                	} else if(prop == 'internalCommunications') {
                		$scope.proposal[prop] = [];
                		if(val) {
                    		_.forEach(val,function(profile){
                    			$scope.proposal[prop].push({profile:{id:profile.id}});
                    		});                			
                		}
                	} else if(prop == 'documents') {
                		if(val) {
                			var index = $linq($scope.proposal.documents).indexOf("x => x.proposalDocumentType == 'SPR'");
	                		if(index != -1) {
	                			$scope.proposal.documents.splice(index,1);
	                		}
	                		index = -1;
	                		index = $linq($scope.proposal.documents).indexOf("x => x.document.id == " + val.id + " && x.proposalDocumentType == 'ADJ'");
	                		if(index != -1) {
	                			$scope.proposal.documents.splice(index,1);
	                		}
                    		$scope.proposal[prop].push({document:{id:val.id}});
                		}
                	} else if(prop == 'complementaryDocument') {
                		if(val) {
                    		$scope.proposal[prop] = [{document:{id:val.id}, proposalDocumentType: 'ADJ'}];
                		} else {
                			$scope.proposal[prop] = [];
                		}
                	}
                	else if(prop == 'notificatorProfile' || prop == 'notificatorResponsible' || prop == 'profile') {
                		$scope.proposal[prop] = ((val && val.id)?{id:val.id}:undefined);
                	} else if(prop == 'amendmentTransactionType' || prop == 'transferTransactionType' || prop == 'cancelTransactionType') {
                		$scope.proposal[prop] = ((val && val.id)?{id:val.id}:((val)?{id:val}:undefined));
                	} else {
                    	$scope.proposal[prop] = val;
                	}
                    RestService.update('./api/sec/proposal/' + $scope.proposal.id, $scope.proposal)
                        .then(function (data) {
                        	$scope.proposal.proposalNumber = data.proposalNumber;
                        	if(prop == 'profile') {
                        		$scope.proposal[prop] = val;
                        	}else if(prop == 'notificatorProfile') {
                        		$scope.proposal[prop] = val;
                        		if ($rootScope.esetMode) $scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: $scope.proposal[prop]});
                        	} else if(prop == 'notificatorResponsible') {
                        		$scope.proposal[prop] = val;
                        		if ($rootScope.esetMode) $scope.$broadcast('getNotificatorResponsibles', {notificatorProfile: data.notificatorProfile});
                        	} else if (prop == 'amendmentTransactionType' || prop == 'transferTransactionType' || prop == 'cancelTransactionType') {
                        		$scope.proposal[prop] = $linq(GlobalDataFactory.transactiontypes).singleOrDefault(undefined, "x => x.id == " + ((val.id)?val.id:val));
                        	}else if(prop == 'proposalType'){
        	                	if($scope.proposal && $scope.proposal.proposalType && $scope.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
        	                		$scope.transaction.proposalSubtype = 'DECREE';
        	                	}else{
        	                		$scope.transaction.proposalSubtype = undefined;
        	                	}
                        	} else if(prop == 'documents') {
                        		$scope.proposal.documents = data.documents;
                        		$scope.propDocument = $linq($scope.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPR'").document;
                        		$rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});
                        	}
                        }).catch(function (error) {
                            console.error(error);
                    });
                }
                
                $scope.getIconClassTramStatus = function (status) {
    				if(status){
    					if(status == 'PENDING' || status == 'PENDING_SIGN'){
    						return 'blue';
    					}else if(status == 'SIGNED'){
    						return 'success';
    					}else if(status == 'CANCELLED'){
    						return 'warn';
    					}else if(status == 'REJECTED' || status == 'ANNULLED'){
    						return 'danger';
    					} else {
    						return'text-grey';
    					}
    				}else{
    					return 'text-grey';
    				}
                }
                
                $scope.getNameTramStatus = function (status) {
                    if(status){
                    	return $filter('translate')('global.sec.literals.status.' + status);
    				}else{
    					return '';
    				}                
                }

            	$scope.propDocument = null;
            	$scope.proposalDocuments = [];
            	$scope.proposalSelectableDocuments = [];
            	
            	$rootScope.$on('proposalDocumentsList', function (event, args) {
					if($scope.proposalDocuments){
            			$scope.proposalDocuments.length = 0;
            		} else {
            			$scope.proposalDocuments = [];
            		}
            		if($scope.proposalSelectableDocuments){
            			$scope.proposalSelectableDocuments.length = 0;
            		} else {
            			$scope.proposalSelectableDocuments = [];
            		}
            		
            		if($scope.proposal && $scope.proposal.proposalType && $scope.proposal.proposalType.proposalTypeSubtype &&  $scope.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
            			var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPD"];
            		}else{            			
            			var documentTypeProp = $rootScope.app.configuration.sec_document_types["SPR"];
            		}
					if(args.docs){
						if(args.docs && documentTypeProp){
							var docsNoOk = [];
							if(TramFactory.proposalsDossierTransaction && TramFactory.proposalsDossierTransaction.length > 0 && TramFactory.Dossier.dossierTransactions && TramFactory.Dossier.dossierTransactions.length > 0){
								var proposals = $linq(TramFactory.proposalsDossierTransaction).distinct("(x, y) => x.proposal.id == y.proposal.id").select("x => x.proposal").toArray()
								_.forEach(proposals, function(pro){
									if(pro.id !== $scope.proposal.id){
										var dns = $linq(pro.documents).where("x => x.proposalDocumentType && (x.proposalDocumentType == 'SPR' || x.proposalDocumentType == 'SDI')").select("x => x.document.id").toArray();
										if(dns && dns.length > 0){
											_.forEach(dns, function(dn){
												if(!_.contains(docsNoOk, dn)){
													docsNoOk.push(dn);
												}																								
											});
										}
									}
								});
							}
							
	                		_.forEach(args.docs, function(documentDT){
	                			if(documentDT && documentDT.document && documentDT.document.type && _.contains(documentTypeProp.id, documentDT.document.type.id+"")){
	                				if((!docsNoOk || docsNoOk.length == 0 || (docsNoOk && docsNoOk.length > 0 && !_.contains(docsNoOk, documentDT.document.id)))){
										$scope.proposalDocuments.push(documentDT.document);
									}
	                			} else if($scope.proposal && !$linq($scope.proposal.documents).contains(documentDT.document, "(x, y) => x.document.id == y.id")) {
	                    			$scope.proposalSelectableDocuments.push(documentDT.document);
	                			}
	                		});
	                		if($scope.proposalDocuments.length > 1) {
	                			_.forEach($scope.proposalDocuments, function(documentDT){
	                				if($scope.proposal && !$linq($scope.proposal.documents).contains(documentDT, "(x, y) => x.document.id == y.id")) {
	                        			$scope.proposalSelectableDocuments.push(documentDT);
	                    			}
	                			});
	                		}
	                	}
	            		if($scope.proposal && $scope.proposal.state && _.contains(['DRAFT'], $scope.proposal.state) 
	            				&& args.docs && $scope.proposal.documents && $scope.proposal.documents.length > 0) {
	            			var removeDocuments = [];
	            			_.forEach($scope.proposal.documents, function(proposalDocument){
	            				var found = false;
	                			if(proposalDocument && proposalDocument.document) {
	                        		_.forEach(args.docs, function(documentDT){
	                        			if(documentDT && documentDT.document && proposalDocument.document.id == documentDT.document.id){
	                        				found = true;
	                        			}
	                    			});
	                        		
	                        		if (!found) {
	                        			removeDocuments.push(proposalDocument.document)
	                        		}
	                			}
	                		});
	                    	_.forEach(removeDocuments, function(removeDocument){
	                    		var index = $linq($scope.proposal.documents).indexOf("x => x.document.id == " + removeDocument.id);
	                            if(index != -1) {
	                                $scope.proposal.documents.splice(index,1);
	                            }
	                    	});
	                    	
	                    	if (removeDocuments && removeDocuments.length > 0) {
	                			$scope.updateProposal($scope.proposal.documents.document, 'documents');
	                			$scope.propDocument = $linq($scope.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPR'").document;
	                    	}
	            		}							
						/*TramFactory.getAllDossierDocumentsNotShow($scope.transaction.dossierId, $scope.proposal.id).then(function(dataNotShow){
							addDocsFinalFunction(((dataNotShow && dataNotShow.length)?dataNotShow:[]));
						}).catch(function(error){
							addDocsFinalFunction([]);
						});*/
					}
            		
            	});
                
            	$rootScope.$broadcast('proposalDocumentsList', {docs: TramFactory.getAllDossierDocuments()});

            	if($scope.proposal && $scope.proposal.documents && $scope.proposal.documents.length > 0){
            		$scope.propDocument = $linq($scope.proposal.documents).firstOrDefault({document: undefined}, "x => x.proposalDocumentType == 'SPR'").document;
            	}

            	$scope.proposalDocumentSelected = function() {
        			if($scope.propDocument && $scope.propDocument.length > 0) {
        				return $scope.propDocument[0].name;
                    } else if ($scope.propDocument) {
                    	return $scope.propDocument.name;
                    } else {
                    	return '';
                    }
                }
            	
            	$scope.deleteComplementaryDocument = function($index, doc) {
            		var index = $linq($scope.proposal.documents).indexOf("x => x.document.id == " + doc.document.id);
            		var selected = $linq($scope.proposal.documents).single("x => x.document.id == " + doc.document.id);
            		if(index != -1) {
            			$scope.proposal.documents.splice(index,1);
            		}
            		$scope.updateProposal(selected, 'complementaryDocument');
            		$scope.proposalSelectableDocuments.push(doc.document);
            	}
            	
            	$scope.selectComplementaryDocument = function(selected) {
            		if($scope.proposal.documents) {
            			$scope.proposal.documents.push({document: selected, proposalDocumentType: 'ADJ'});
            		} else {
            			$scope.proposal.documents = [];
            			$scope.proposal.documents.push({document: selected, proposalDocumentType: 'ADJ'});
            		}

        			$scope.updateProposal(selected, 'complementaryDocument');
            		var index = $linq($scope.proposalSelectableDocuments).indexOf("x => x.id == " + selected.id);
            		if(index != -1) {
            			$scope.proposalSelectableDocuments.splice(index,1);
            		}
            	}
            	
                //endregion
                
                $scope.transaction_id = (($scope.transaction && $scope.transaction.id) ? $scope.transaction.id : '');
                
                //region Organs Box
                $scope.organsBox = {
                    content: (($scope.proposal && $scope.proposal.organs) ? $scope.proposal.organs : []),
                    permissions: {},
                    new: {},
                    config: {},
                    origin: 'transactionProposal-' + $scope.transaction_id
                }
                
                $scope.isEdit = true;
                $scope.canEdit = (($scope.proposal && $scope.proposal.state && _.contains(['DRAFT'], $scope.proposal.state) && $scope.transaction && $scope.transaction.actual && $scope.transaction.transactionType && ($scope.transaction.transactionType.transactionSubtype == 1 || $scope.transaction.transactionType.transactionSubtype == 4)) ? true : false);
                        
                $scope.$on('annexaBoxOrgansAddOpinionOrgan', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.organ && args.organ && args.modal){
                    		SecFactory.addProposalOrgan($scope.proposal.id, args.organ).then(function(data) {
    	                        if(data) {
    	                        	 $scope.organsBox.content.push(data);
    	                        }
    	                        args.modal.close();
    	                    }).catch(function(error) {
    	                    	args.modal.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
    	                    })
    	                }else{
    	            		DialogsFactory.error('global.sec.literals.error_required_files_organ');
    	            	}
                    }
                });
                
        		$scope.$on('annexaBoxOrgansModifyOpinionOrgan', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
    	                	SecFactory.updateProposalOrgan($scope.proposal.id, args.organ.id, args.organ.organ.id).then(function(data) {
    	                		if(data) {
    		                		var index = $linq($scope.organsBox.content).indexOf("x => x.id == " + data.id);
    		                        if(index != -1) {
    		                            $scope.organsBox.content[index] = data;
    		                        }
    	                		}
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
    	                    })
                    	}else{
                    		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                    	}
                    }
                });
    			
        		$scope.$on('annexaBoxOrgansModifyAgreementOrgan', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
    	                	SecFactory.updateProposalOrgan($scope.proposal.id, args.organ.id, args.organ.organ.id).then(function(data) {
    		                	if(data){
    		                		var index = $linq($scope.organsBox.content).indexOf("x => x.id == " + data.id);
    			                    if(index != -1) {
    			                        $scope.organsBox.content[index] = data;
    			                    }
    		                	}
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
    	                    })
                    	} else if (args.organ && args.organ.organ && args.organ.organ.id){
                    		SecFactory.addProposalOrgan($scope.proposal.id, args.organ.organ.id).then(function(data) {
    	                        if(data) {
    	                        	 $scope.organsBox.content.push(data);
    	                        }
    	                        
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
    	                    })                    		
                    	}else{
                    		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                    	}
                    }
                });
    			
        		$scope.$on('annexaBoxOrgansDelete', function (event, args) {
                    if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id) && $scope.isEdit) {
                    	if(args.removedIds && args.removedIds.length > 0){
    	                	SecFactory.deleteProposalOrgan($scope.proposal.id, args.removedIds).then(function(data) {
    	                        if(data) {
    	                        	_.forEach(args.removedIds, function(id){
    	                        		var index = $linq($scope.organsBox.content).indexOf("x => x.id == " + id);
    	                                if(index != -1) {
    	                                    $scope.organsBox.content.splice(index,1);
    	                                }
    	                        	});
    	                        	
    	                        }
    	                    }).catch(function(error) {
    	                    	$scope.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'deleteOrgan', error)});
    	                    })
                    	}else{
                    		DialogsFactory.error('global.sec.literals.error_required_files_removedIds');
                    	}
                    }
                });
        		
        		 $scope.$on('annexaBoxUpdateSaveProposal', function (event, args) {
    	            if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id)) {
    	            	$scope.canEdit = (($scope.proposal && $scope.proposal.state && _.contains(['DRAFT'],$scope.proposal.state) && $scope.transaction && $scope.transaction.actual && $scope.transaction.transactionType && ($scope.transaction.transactionType.transactionSubtype == 1 || $scope.transaction.transactionType.transactionSubtype == 4))?true:false);
    	            	HeaderService.changeState($state.current, true);
    	            }
    	        });
        		//endregion

                //region internal communication box
                $scope.internalCommunicationBox = {
            		content: $scope.proposal,
                    permissions: {},
                    new: {},
                    config: {},
                    origin: 'transactionProposal-' + $scope.transaction_id
                }

                $scope.$on('annexaBoxInternalCommunicationAdd', function (event, args) {
                	if(args.origin && args.origin == ('transactionProposal-' + $scope.transaction_id)) {
                		$scope.updateProposal(args.val, args.prop);
                	}
                });
                //endregion
                
                //region transactionTypes box
                if (TramFactory.Dossier && TramFactory.Dossier.dossierTramitationType && TramFactory.Dossier.dossierTramitationType.id) {
                	var selectableTransactionTypes = [];
                    _.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
                        if($linq(value.tramitationTypes).contains(TramFactory.Dossier.dossierTramitationType.id, "(x, y) => x.tramitationType.id == y")) {
                        	selectableTransactionTypes.push(value);
                        }
                    });
                    var selectTransferTransactionTypes = "x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[3].id;
                    $scope.transferTransactionTypes = $linq(selectableTransactionTypes).where(selectTransferTransactionTypes).toArray();
                    
                    var selectAmendmentTransactionTypes = "x => x.transactionSubtype == " + apiAdmin.transactionSubtypes[4].id;
                    $scope.amendmentTransactionTypes = $linq(selectableTransactionTypes).where(selectAmendmentTransactionTypes).toArray();
                    
                    var selectCancelTransactionTypes = "x => && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
                    $scope.cancelTransactionTypes = $linq(selectableTransactionTypes).where(selectCancelTransactionTypes).toArray();
                }
                
            	$scope.amendmentProposalSelected = function() {
                    if ($scope.proposal && $scope.proposal.amendmentTransactionType) {
                    	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+$scope.proposal.amendmentTransactionType.id);
                    	return ((tt)?tt[$scope.languageColumn]:'');
                    } else {
                    	return '';
                    }
                }
            	$scope.transferProposalSelected = function() {
                    if ($scope.proposal && $scope.proposal.transferTransactionType) {
                    	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+$scope.proposal.transferTransactionType.id);
                    	return ((tt)?tt[$scope.languageColumn]:'');
                    } else {
                    	return '';
                    }
                }
            	$scope.cancelProposalSelected = function() {
                    if ($scope.proposal && $scope.proposal.cancelTransactionType) {
                    	var tt = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == "+$scope.proposal.cancelTransactionType.id);
                    	return ((tt)?tt[$scope.languageColumn]:'');
                    } else {
                    	return '';
                    }
                }
				
				$scope.userAccountInstances = TramFactory.userAccountInstances;
        		var proposalOperationsAndInvoices = [];
				if($scope.proposal && $scope.proposal.operations &&$scope.proposal.operations.length > 0){
					proposalOperationsAndInvoices = $linq($scope.proposal.operations).select("x=>x.operation").toArray();
				}
	 			$scope.proposalOperationsAndInvoicesBox = {
	            	boxTitle: 'global.operationsAndInvoices.title',
	                permissions: (($scope.userAccountInstances && $scope.userAccountInstances.length > 0 && $scope.canEdit)?{new:'process_dossier'}:{}),
	                content:  proposalOperationsAndInvoices,
	                config: { proposal: $scope.proposal },
					onlyAddOperations: true,
					canRemoveOperations: ($scope.userAccountInstances && $scope.userAccountInstances.length > 0 && $scope.canEdit),
					newTitle: 'global.literals.add',
	                origin: 'tram'
	            };
            	//endregion
            }]
        }
    }])
    .directive('annexaDossierTransactionInspection', ['TramFactory', 'RestService', 'GlobalDataFactory', 'SecFactory', 'DialogsFactory', 'ErrorFactory', 'apiAdmin', 'AnnexaFormlyFactory',
    		function (TramFactory, RestService, GlobalDataFactory, SecFactory, DialogsFactory, ErrorFactory, apiAdmin, AnnexaFormlyFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                inspectionProposal: '=?',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/inspectionProposal.html',
            controller: ['$scope', '$filter', '$rootScope', function ($scope, $filter, $rootScope) {
            	$scope.canEdit = (($scope.transaction && $scope.transaction.actual && $scope.transaction.transactionType && ($scope.transaction.transactionType.transactionSubtype == 7 || $scope.transaction.transactionType.transactionSubtype == 11)) ? true : false);
                
                $scope.updateInspectionProposal = function(val, prop) {
                	var aux = $scope.inspectionProposal[prop];
                	$scope.inspectionProposal[prop] = val;
                	RestService.update('./api/tram/inspectionProposal/' + $scope.inspectionProposal.id, $scope.inspectionProposal)
                        .then(function (data) {
                        	
                        }).catch(function (error) {
                        	$scope.inspectionProposal[prop] = aux;
                    });
                }

            	$scope.transaction_id = (($scope.transaction && $scope.transaction.id) ? $scope.transaction.id : '');
                                
                //region transactionTypes box
                if (TramFactory.Dossier && TramFactory.Dossier.dossierTramitationType && TramFactory.Dossier.dossierTramitationType.id) {
//                    var select = "x => x.tramitationType.id == " + TramFactory.Dossier.dossierTramitationType.id;
//                    var selectAmendmentTransactionTypes = select + " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[11].id;
                    $scope.amendmentTransactionTypes = [];//$linq(TramFactory.transactionsTypes).where(selectAmendmentTransactionTypes).toArray();
                }
                
            	$scope.amendmentProposalSelected = function() {
                    if ($scope.inspectionProposal && $scope.inspectionProposal.amendmentTransactionType) {
                    	return $scope.inspectionProposal.amendmentTransactionType[$scope.languageColumn];
                    } else {
                    	return '';
                    }
                }
            	//endregion
            }]
        }
    }])
    .directive('annexaDossierTransactionOther', ['TramFactory', function (TramFactory) {
        return {
            restrinct: 'E',
            replace: true,
            scope: {
                transaction: '=',
                order: '@',
                languageColumn: '=',
                isUpdateDossier: '='
            },
            templateUrl: './views/layout/viewdossier/general_left/other.html',
            controller: ['$scope', function ($scope) {
            	$scope.isArchivedDossier = false;
            	if(TramFactory.Dossier && TramFactory.isArchivedDossier && (TramFactory.Dossier.dossierStatus == 'TRANSFER' || TramFactory.Dossier.dossierStatus == 'REJECTED' || (TramFactory.Dossier.dossierStatus == 'ARCHIVED' && !TramFactory.Dossier.archiveDefinitiveDate))){
    				$scope.isArchivedDossier = true;
    			}
                $scope.updateTransaction = function (property, data) {
                    $scope.transaction[property] = data;
                    TramFactory.updateDossierTransaction($scope.transaction)
                        .then(function (data) {
                            var a = 0;
                        }).catch(function (error) {
                    });
                }
            }]
        }
    }])
    .directive('annexaNextDossierTransactionInfo', ['$filter', '$compile', 'HelperService', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'TaskFactory', 'AnnexaModalFactory', 'RestService', 'NotificationFactory', 'Language', 'ABMShapesFactory', 'TramFactory', 'GlobalDataFactory', 'apiAdmin',
        function ($filter, $compile, HelperService, $rootScope, globalModals, AnnexaFormlyFactory, TaskFactory, AnnexaModalFactory, RestService, NotificationFactory, Language, ABMShapesFactory, TramFactory, GlobalDataFactory, apiAdmin) {
            var getTemplate = function (scope) {
                var level = scope.level;
                if (!level) {
                    level = 1;
                } else {
                    level++;
                }
                var template = '';
                if (scope.isParallelClose(scope.dossierTransactionInfo)) {
                    template += '   <div class="row">';
                    template += '       <div class="col-xs-12">';
                    template += '           <p><strong translate="global.tram.literals.nextStepNotFound">The Step finished</strong></p>';
                    template += '       </div>';
                    template += '   </div>';
                } else {
                    template += '   <div class="row" ng-if="!level">';
                    template += '       <div class="col-xs-12">';
                    template += '           <p><strong translate="global.tram.literals.selectNextStep">Select next steps</strong>:</p>';
                    template += '       </div>';
                    template += '   </div>';
                    template += '   <div class="row " ng-if="!level">';
                    template += '       <div class="col-xs-4">';
                    //check radio o stick fase inicial
                    template += '           <input ng-if="dossierTransactionInfo.dossierTransactionDefinition && selectType(1, dossierTransactionInfo, true)" ng-disabled="isDisabled(dossierTransactionInfo)" type="checkbox" name="checkInitial{{dossierTransactionInfo.graphNodeId}}" id="checkInitial{{dossierTransactionInfo.graphNodeId}}" ng-model="dossierTransactionInfo.active"/>';
                    template += '           <input ng-if="dossierTransactionInfo.dossierTransactionDefinition && selectType(2, dossierTransactionInfo, true)" ng-disabled="isDisabled(dossierTransactionInfo)" type="radio" name="radioInitial{{dossierTransactionInfo.graphNodeId}}" id="radioInitial{{dossierTransactionInfo.graphNodeId}}" ng-value="true" ng-model="dossierTransactionInfo.active" ng-click="clickObject(dossierTransactionInfo)"/>';
                    template += '           <i class="fa fa-check" ng-if="dossierTransactionInfo.dossierTransactionDefinition && selectType(3, dossierTransactionInfo, true)" />';
                    template += '           <span ng-if="!dossierTransactionInfo.dossierTransactionDefinition && level" translate="global.literals.otherOptions"></span>';
                    template += '           <div class="guidedProcedureText" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition" class="col-xs-12 col-md-12  m-b-0">';
                    template += '                   <span class="small" translate="global.literals.name">Name</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && isSubprocessFree(dossierTransactionInfo)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                   <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && dossierTransactionInfo.dossierTransactionDefinition.subprocedure && dossierTransactionInfo.dossierTransactionDefinition.subprocedure.id" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                   <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition.subprocedure[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '           </div>';
                    template += '       </div>';
                    template += '       <div class="col-xs-8">';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition && isFree(dossierTransactionInfo) && isSubprocessFree(dossierTransactionInfo)" class="col-xs-12 col-md-5">';
                    template += '               <span class="small " translate="global.literals.type">Type</span>';
                    template += '               <div class="pos-rlt ">';
                    template += '                   <button type="button" class="bt-deselect m-r-md" ng-click="clear()" ng-show="dossierTransactionInfo.transactionType && dossierTransactionInfo.transactionType.id" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                    template += '                   <ui-select fix-focus-on-touch ng-model="dossierTransactionInfo.transactionType" theme="bootstrap">';
                    template += '                       <ui-select-match>{{$select.selected[languageColumn]}}</ui-select-match>';
                    template += '                       <ui-select-choices repeat="item in transactionsTypes | filter: $select.search">';
                    template += '                           <div ng-bind-html="item[languageColumn]"></div>';
                    template += '                       </ui-select-choices>';
                    template += '                   </ui-select>';
                    template += '               </div>';
                    template += '           </div>';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition && (!isFree(dossierTransactionInfo) || !isSubprocessFree(dossierTransactionInfo))" class="col-xs-12 col-md-5">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && dossierTransactionInfo.dossierTransactionDefinition.transactionType" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                   <span class="small " translate="global.literals.type">Type</span>';
                    template += '                   <strong class="block _600  l-h-1x">{{dossierTransactionInfo.dossierTransactionDefinition.transactionType[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '           </div>';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition && isFree(dossierTransactionInfo)" class="col-xs-12 col-md-5 ico-floating-wrapper">';
                    template += '               <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '               <input type="text" class="form-control ico-floating-label" ng-model="dossierTransactionInfo.profile" uib-typeahead="result as result.value for result in loadProfilesFunction({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                    template += '               <span class="ico-floating">';
                    template += '                   <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                    template += '                   <i ng-show="noResultBox" class="fa fa-ban "></i>';
                    template += '               </span>';
                    template += '           </div>';
                    template += '           <div class="col-xs-12 col-md-5 m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition && !isFree(dossierTransactionInfo)">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition && !isFree(dossierTransactionInfo)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '               <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '                   <strong ng-repeat="processProfile in dossierTransactionInfo.dossierTransactionDefinition.processProfiles" class="block _600 l-h-1x">{{processProfile.profile[languageColumn]}}</strong>';
                    template += '               </p>';
                    template += '           </div>';
                    template += '       </div>';
                    template += '       <div class="col-xs-4">';
                    template += '           <p class="col-xs-12 col-md-12  m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <span class="small " translate="global.literals.expireType">Max days</span>';
                    template += '               <strong class="block _600 " ng-if="dossierTransactionInfo.dossierTransactionDefinition.expireType == \'BUSINESS\'" translate="global.literals.BUSINESS"></strong>';
                    template += '               <strong class="block _600 " ng-if="dossierTransactionInfo.dossierTransactionDefinition.expireType == \'NATURAL\'" translate="global.literals.NATURAL"></strong>';
                    template += '               <strong class="block _600 " ng-if="dossierTransactionInfo.dossierTransactionDefinition.expireType == \'MONTHS\'" translate="global.literals.MONTHS"></strong>';
                    template += '               <strong class="block _600 " ng-if="dossierTransactionInfo.dossierTransactionDefinition.expireType == \'YEARS\'" translate="global.literals.YEARS"></strong>';
                    template += '           </p>';
                    template += '       </div>';
                    template += '       <div class="col-xs-8">';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition" class="col-xs-12 col-md-5">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition" class="col-xs-12 col-md-12 m-b-0">';
                    template += '               	<span class="small " translate="global.literals.tramitationPhase">Phase</span>';
                    template += '               	<strong class="block _600 ">{{dossierTransactionInfo.dossierTransactionDefinition.phase[languageColumn]}}</strong>';
                    template += '           	</p>';
                    template += '       	</div>';
                    template += '           <div ng-if="dossierTransactionInfo.dossierTransactionDefinition" class="col-xs-12 col-md-5">';
                    template += '               <p ng-if="dossierTransactionInfo.dossierTransactionDefinition" class="col-xs-12 col-md-12 m-b-0">';
                    template += '               	<span class="small " translate="global.literals.expire">Max days</span>';
                    template += '               	<strong class="block _600 ">{{dossierTransactionInfo.dossierTransactionDefinition.maxDays}}</strong>';
                    template += '           	</p>';
                    template += '       	</div>';
                    template += '           <div class="col-xs-12 col-md-5" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <p class="col-xs-12 col-md-12" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               	<span class="small " translate="global.literals.daysComputeGlobal">Compute</span>';
                    template += '               	<strong class="block _600 " ng-if="dossierTransactionInfo.dossierTransactionDefinition.daysComputeGlobal" translate="global.literals.yes"></strong>';
                    template += '               	<strong class="block _600 " ng-if="!dossierTransactionInfo.dossierTransactionDefinition.daysComputeGlobal" translate="global.literals.no"></strong>';
                    template += '           	</p>';
                    template += '       	</div>';
                    template += '       </div>';
                    template += '       <div class="col-xs-12 col-sm-4 p-y-sm" ng-if="dossierTransactionInfo.dossierTransactionDefinition && !isFree(dossierTransactionInfo) && showUsersProfiles">';
                    template += '       	<p ng-if="dossierTransactionInfo.dossierTransactionDefinition && !isFree(dossierTransactionInfo)" class="m-b-0">';
                    template += '           	<span class="small " translate="global.literals.responsibleUser">Responsible User</span>';
                    template += '               <ui-select fix-focus-on-touch ng-model="dossierTransactionInfo.responsibleUser" theme="bootstrap">';
                    template += '               	<ui-select-match>{{$select.selected.user.name + \' \' + $select.selected.user.surename1}}</ui-select-match>';
                    template += '                   <ui-select-choices repeat="item in getUserProfiles(dossierTransactionInfo.dossierTransactionDefinition.processProfiles) | filter: $select.search">';
                    template += '                   	{{item.user.name + \' \' + item.user.surename1}}';
                    template += '                   </ui-select-choices>';
                    template += '               </ui-select>';
                    template += '           </p>';
                    template += '       </div>';
                    template += '       <div class="col-xs-12 col-sm-4 p-y-sm" ng-if="dossierTransactionInfo.dossierTransactionDefinition && isFree(dossierTransactionInfo) && showUsersProfiles">';
                    template += '       	<p ng-if="dossierTransactionInfo.dossierTransactionDefinition && isFree(dossierTransactionInfo)" class="m-b-0">';
                    template += '           	<span class="small " translate="global.literals.responsibleUser">Responsible User</span>';
                    template += '               <ui-select fix-focus-on-touch ng-model="dossierTransactionInfo.responsibleUser" theme="bootstrap">';
                    template += '               	<ui-select-match>{{$select.selected.user.name + \' \' + $select.selected.user.surename1}}</ui-select-match>';
                    template += '                   <ui-select-choices repeat="item in getUserProfilesDTIProfile(dossierTransactionInfo.profile) | filter: $select.search">';
                    template += '                   	{{item.user.name + \' \' + item.user.surename1}}';
                    template += '                   </ui-select-choices>';
                    template += '               </ui-select>';
                    template += '           </p>';
                    template += '       </div>';
                    
                    template += '       <div class="col-xs-12">';
                    template += '           <p class="m-b-0" ng-if="dossierTransactionInfo.dossierTransactionDefinition">';
                    template += '               <span class="small " translate="global.literals.information_message">Description</span>';
                    template += '               <textarea class="block _600 w-full"  maxlength="500" rows="2"  ng-model="dossierTransactionInfo.description"></textarea>';
                    template += '           </p>';
                    template += '       </div>';
                    template += '   </div>';
                    template += '   <div class="row degraded-list">';
                    template += '       <div class="col-xs-12 indentation ">';
                    template += '           <div ng-repeat="dossierTransactionInfoAux in dossierTransactionInfo.dossiersTransactionsInfo">';
                    template += '               <div class="row">';
                    template += '                   <div class=" col-xs-4 col-sm-4 p-y-sm d-flex" ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                       <div class="indent-padding"></div>';
                    //check radio o stick portes
                    template += '                       <input class=" m-r-sm" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition && selectType(1, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="checkbox" name="checkTrans{{dossierTransactionInfo.graphNodeId}}" id="checkTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-model="dossierTransactionInfoAux.active" />';
                    template += '                       <input class=" m-r-sm" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition && selectType(2, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="radio" name="radioTrans{{dossierTransactionInfo.graphNodeId}}" id="radioTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-value="true" ng-model="dossierTransactionInfoAux.active" ng-click="clickObject(dossierTransactionInfo, dossierTransactionInfoAux)"/>';
                    template += '                       <i class="fa fa-check m-r-sm" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition && selectType(3, dossierTransactionInfo, false)"/>';
                    //check radio o stick faese
                    template += '                       <input class=" m-r-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && selectType(1, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="checkbox" name="checkTrans{{dossierTransactionInfo.graphNodeId}}" id="checkTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-model="dossierTransactionInfoAux.active"/>';
                    template += '                       <input class=" m-r-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && selectType(2, dossierTransactionInfo, false)" ng-disabled="isDisabled(dossierTransactionInfoAux)" type="radio" name="radioTrans{{dossierTransactionInfo.graphNodeId}}" id="radioTrans{{dossierTransactionInfo.graphNodeId}}{{$index}}" ng-value="true" ng-model="dossierTransactionInfoAux.active" ng-click="clickObject(dossierTransactionInfo, dossierTransactionInfoAux)"/>';
                    template += '                       <i class="fa fa-check m-r-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && selectType(3, dossierTransactionInfo, false)"/>';
                    //literals portes o fases
                    template += '                       <span class="text-nowrap" ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition" translate="global.literals.otherOptions"></span>';
                    template += '                       <div class="guidedProcedureText" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition" class="col-xs-12 col-md-12  m-b-0">';
                    template += '                               <span class="small" translate="global.literals.name">Name</span>';
                    template += '                               <strong class="block _600 l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isSubprocessFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                               <strong class="block _600  l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && dossierTransactionInfoAux.dossierTransactionDefinition.subprocedure && dossierTransactionInfoAux.dossierTransactionDefinition.subprocedure.id" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.SUBPROCEDURE">SubProcedure</span>';
                    template += '                               <strong class="block _600  l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition.subprocedure[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '                  </div>';
                    //combo perfils o nom perfils
                    template += '                   <div class=" col-xs-8 col-sm-8 p-y-sm"  ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                       <div ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isFree(dossierTransactionInfoAux) && isSubprocessFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-4">';
                    template += '                           <span class="small " translate="global.literals.type">Type</span>';
                    template += '                           <div class="pos-rlt ">';
                    template += '                               <button type="button" class="bt-deselect m-r-md" ng-click="clear()" ng-show="dossierTransactionInfoAux.transactionType && dossierTransactionInfoAux.transactionType.id" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                    template += '                               <ui-select fix-focus-on-touch ng-model="dossierTransactionInfoAux.transactionType" theme="bootstrap" ng-change="selectTransactionType(dossierTransactionInfo, dossierTransactionInfoAux)">';
                    template += '                                   <ui-select-match>{{$select.selected[languageColumn]}}</ui-select-match>';
                    template += '                                   <ui-select-choices repeat="item in transactionsTypes | filter: $select.search">';
                    template += '                                       <div ng-bind-html="item[languageColumn]"></div>';
                    template += '                                   </ui-select-choices>';
                    template += '                               </ui-select>';
                    template += '                           </div>';
                    template += '                       </div>';
                    template += '                       <div ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && (!isFree(dossierTransactionInfoAux) || !isSubprocessFree(dossierTransactionInfoAux))" class="col-xs-12 col-md-4">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && dossierTransactionInfoAux.dossierTransactionDefinition.transactionType" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.type">Type</span>';
                    template += '                               <strong class="block _600  l-h-1x">{{dossierTransactionInfoAux.dossierTransactionDefinition.transactionType[languageColumn]}}</strong>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '                       <div ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-4 ico-floating-wrapper m-t-xs">';
                    template += '                           <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '                           <input type="text" class="form-control ico-floating-label" ng-model="dossierTransactionInfoAux.profile" uib-typeahead="result as result.value for result in loadProfilesFunction({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                    template += '                           <span class="ico-floating">';
                    template += '                               <i ng-show="loadingBox" class="fa fa-spinner fa-pulse "></i>';
                    template += '                               <i ng-show="noResultBox" class="fa fa-ban "></i>';
                    template += '                           </span>';
                    template += '                       </div>';
                    template += '                       <div class="col-xs-12 col-md-4 m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && !isFree(dossierTransactionInfoAux)">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && !isFree(dossierTransactionInfoAux)" class="col-xs-12 col-md-12 m-b-0">';
                    template += '                               <span class="small " translate="global.literals.profile">Profile</span>';
                    template += '                               <strong ng-repeat="processProfile in dossierTransactionInfoAux.dossierTransactionDefinition.processProfiles" class="block _600 l-h-1x">';
                    template += '									{{processProfile.profile[languageColumn]}}';
                    template += '								</strong>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '                       <p class="col-xs-12 col-md-3 m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                          <span class="small " translate="global.literals.daysComputeGlobal">Compute</span>';
                    template += '                           <strong class="block _600 " ng-if=" dossierTransactionInfoAux.dossierTransactionDefinition.daysComputeGlobal" translate="global.literals.yes"></strong>';
                    template += '                           <strong class="block _600 " ng-if="!dossierTransactionInfoAux.dossierTransactionDefinition.daysComputeGlobal" translate="global.literals.no"></strong>';
                    template += '                       </p>';
                    template += '                   </div>';
                    template += '                   <div class=" col-xs-12 col-sm-12 p-y-sm"  ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                       <p class="col-xs-4" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                          <span class="small " translate="global.literals.tramitationPhase">Phase</span>';
                    template += '                           <strong class="block _600 ">{{dossierTransactionInfoAux.dossierTransactionDefinition.phase[languageColumn]}}</strong>';
                    template += '                       </p>';
                    template += '       				<div class="col-xs-4">';
                    template += '           				<p class="col-xs-12 col-md-12  m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '               				<span class="small " translate="global.literals.expireType">Max days</span>';
                    template += '               				<strong class="block _600 " ng-if="dossierTransactionInfoAux.dossierTransactionDefinition.expireType == \'BUSINESS\'" translate="global.literals.BUSINESS"></strong>';
                    template += '               				<strong class="block _600 " ng-if="dossierTransactionInfoAux.dossierTransactionDefinition.expireType == \'NATURAL\'" translate="global.literals.NATURAL"></strong>';
                    template += '               				<strong class="block _600 " ng-if="dossierTransactionInfoAux.dossierTransactionDefinition.expireType == \'MONTHS\'" translate="global.literals.MONTHS"></strong>';
                    template += '               				<strong class="block _600 " ng-if="dossierTransactionInfoAux.dossierTransactionDefinition.expireType == \'YEARS\'" translate="global.literals.YEARS"></strong>';
                    template += '           				</p>';
                    template += '       				</div>';
                    template += '                       <p class="col-xs-4" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                          <span class="small " translate="global.literals.expire">Max days</span>';
                    template += '                           <strong class="block _600 ">{{dossierTransactionInfoAux.dossierTransactionDefinition.maxDays}}</strong>';
                    template += '                       </p>';
                    template += '                   </div>';
                    template += '                      	<div class="col-xs-12 col-sm-4 p-y-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && !isFree(dossierTransactionInfoAux) && showUsersProfiles">';
                    template += '                           <p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && !isFree(dossierTransactionInfoAux)" class="m-b-0">';
                    template += '                               <span class="small " translate="global.literals.responsibleUser">Responsible User</span>';
                    template += '                               		<ui-select fix-focus-on-touch ng-model="dossierTransactionInfoAux.responsibleUser" theme="bootstrap">';
                    template += '                                   		<ui-select-match>{{$select.selected.user.name + \' \' + $select.selected.user.surename1}}</ui-select-match>';
                    template += '                                   		<ui-select-choices repeat="item in getUserProfiles(dossierTransactionInfoAux.dossierTransactionDefinition.processProfiles) | filter: $select.search">';
                    template += '                                   	    	{{item.user.name + \' \' + item.user.surename1}}';
                    template += '                                   		</ui-select-choices>';
                    template += '                               		</ui-select>';
                    template += '                           </p>';
                    template += '                       </div>';
                    template += '       				<div class="col-xs-12 col-sm-4 p-y-sm" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isFree(dossierTransactionInfoAux) && showUsersProfiles">';
                    template += '       					<p ng-if="dossierTransactionInfoAux.dossierTransactionDefinition && isFree(dossierTransactionInfoAux)" class="m-b-0">';
                    template += '           					<span class="small " translate="global.literals.responsibleUser">Responsible User</span>';
                    template += '               				<ui-select fix-focus-on-touch ng-model="dossierTransactionInfoAux.responsibleUser" theme="bootstrap">';
                    template += '               					<ui-select-match>{{$select.selected.user.name + \' \' + $select.selected.user.surename1}}</ui-select-match>';
                    template += '                   				<ui-select-choices repeat="item in getUserProfilesDTIProfile(dossierTransactionInfoAux.profile) | filter: $select.search">';
                    template += '                   					{{item.user.name + \' \' + item.user.surename1}}';
                    template += '                   				</ui-select-choices>';
                    template += '               				</ui-select>';
                    template += '           				</p>';
                    template += '      					</div>';
                    template += '                   <div class=" col-xs-12 col-sm-12 p-y-sm"  ng-class="{\'disabled\': isDisabled(dossierTransactionInfoAux)}">';
                    template += '                      <p class="m-b-0" ng-if="dossierTransactionInfoAux.dossierTransactionDefinition">';
                    template += '                           <span class="small " translate="global.literals.information_message">Description</span>';
                    template += '                           <textarea class="block _600 w-full"  maxlength="500" rows="2"  ng-model="dossierTransactionInfoAux.description"></textarea>';
                    template += '                      </p>';
                    template += '                   </div>';
                    template += '               </div>';
                    //següents fases
                    template += '           <annexa-next-dossier-transaction-info ng-if="dossierTransactionInfoAux.dossiersTransactionsInfo && dossierTransactionInfoAux.dossiersTransactionsInfo.length > 0" dossier-transaction-info="dossierTransactionInfoAux" dossier-transaction-info-orig="dossierTransactionInfoOrig" actual-transaction="actualTransaction" level="' + level + '"/>';
                    template += '        </div>';
                    template += '    </div>';
                    template += '   </div>';
                }
                return template;
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    dossierTransactionInfoOrig: '=',
                    dossierTransactionInfo: '=',
                    actualTransaction: '=',
                    level: "="
                },
                link: function (scope, element, attr) {
                    scope.profile = '';
                    scope.transactionsTypes = [];
                    scope.tramitationPhases = [];
					scope.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
					
                    scope.languageColumn = Language.getActiveColumn();
                    scope.descriptionLanguageColumn = "descriptionL" + scope.languageColumn.substr(1, scope.languageColumn.length);
                    if (!scope.dossierTransactionInfoOrig) {
                        scope.dossierTransactionInfoOrig = scope.dossierTransactionInfo;
                    }

                    if (TramFactory.Dossier && TramFactory.Dossier.dossierTramitationType && TramFactory.Dossier.dossierTramitationType.id) {
                    	scope.transactionsTypes = [];
                        _.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
                            if(value.finallyType == false && value.active == true 
                                    && $linq(value.tramitationTypes).contains(TramFactory.Dossier.dossierTramitationType.id, "(x, y) => x.tramitationType.id == y")) {
                            	scope.transactionsTypes.push(value);
                            }
                        });
                    }
                    scope.addProfileFree = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                            if (dossierTransactionInfo.profiles && dossierTransactionInfo.profiles.length > 0) {
                                dossierTransactionInfo.profile = dossierTransactionInfo.profiles[0][scope.languageColumn];
                            }
                        }
                        if (dossierTransactionInfo.dossiersTransactionsInfo) {
                            angular.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                                scope.addProfileFree(value);
                            });
                        }
                    };

                    if (scope.dossierTransactionInfo) {
                        scope.addProfileFree(scope.dossierTransactionInfo);
                    }

                    scope.loadProfilesFunction = function (value) {
                        if (!value || !value.val) {
                            return [];
                        } else if (value.val != '*' && value.val.length < 3) {
                            return [];
                        } else {
                            if (value.val == '*') {
                                value.val = '';
                            }
                            return RestService.filterData('common', 'Profile', {}, {search: value.val, langColumn:Language.getActiveColumn()}, 0, 10, '', 3)
                                .then(function (data) {
                                    var items = [];

                                    if (data.data && data.data.content) {
                                        _.forEach(JSOG.decode(data.data.content), function (item) {
                                            items.push({id: item.id, value: item[Language.getActiveColumn()], userProfiles:item.userProfiles});
                                        })
                                    }

                                    return items;
                                }).catch(function () {
                                    return [];
                                });
                        }
                    };
                    
                    scope.selectTransactionType = function (dossierTransactionInfo, dossierTransactionInfoAux) {
                    	dossierTransactionInfoAux.dossierTransactionDefinition.expireType = dossierTransactionInfoAux.transactionType.expireType;
                    	dossierTransactionInfoAux.dossierTransactionDefinition.maxDays = dossierTransactionInfoAux.transactionType.days;
                    	scope.tramitationPhases = $linq(dossierTransactionInfoAux.transactionType.phases).select("x => x.tramitationPhase").toArray();
                    	if(scope.tramitationPhases.length == 1){
                    		dossierTransactionInfoAux.dossierTransactionDefinition.phase = scope.tramitationPhases[0];
                    	}else if(scope.tramitationPhases.length > 1){
                    		dossierTransactionInfoAux.dossierTransactionDefinition.phase = scope.tramitationPhases[0];
                    	}
                    };

                    scope.getUserProfiles = function (processProfile) {
                    	var userProfiles = [];
                    	angular.forEach(processProfile, function (value, key) {
                    		angular.forEach(value.profile.userProfiles, function (value, key) {
                    			var exist = false;
                    			for(var i = 0; i < userProfiles.length; i++) {
                    				if (userProfiles[i].user.id == value.user.id) {
                                		exist = true;
                        			}
                    			}
                    			if(!exist) {
                    				userProfiles.push(value);
                    			}
                    		});
                        });
                    	return userProfiles;
                    }
                    
                    scope.getUserProfilesDTIProfile = function (profile) {
                    	var userProfiles = [];
                    	if(profile && profile.userProfiles){
	                		angular.forEach(profile.userProfiles, function (value, key) {
	                			userProfiles.push(value);
	                		});
                    	}
                    	return userProfiles;
                    }
                    
                    scope.selectType = function (type, dossierTransactionInfo, actual) {
                        if (actual && dossierTransactionInfo.graphNodeType != 'SelectOne' && dossierTransactionInfo.graphNodeType != 'SelectMultipleOpen') {
                            return false
                        } else {
                            if (type == 1) {
                                if (dossierTransactionInfo.graphNodeType == 'SelectMultipleOpen') {
                                    return true;
                                } else {
                                    return false;
                                }

                            } else if (type == 2) {
                                if (dossierTransactionInfo.graphNodeType == 'SelectOne') {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                if (scope.selectType(1, dossierTransactionInfo, actual) || scope.selectType(2, dossierTransactionInfo, actual)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        }
                    };

                    scope.isFree = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                            return true;
                        } else {
                            return false;
                        }
                    };

                    scope.isSubprocessFree = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                            return true;
                        } else {
                            return false;
                        }
                    };

                    scope.clickObject = function (dossierTransactionInfo, dossierTransactionInfoAux) {
                        if (!dossierTransactionInfoAux) {
                            dossierTransactionInfo.active = true;
                        } else {
                            if (dossierTransactionInfo.dossiersTransactionsInfo) {
                                angular.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                                    if (dossierTransactionInfoAux.graphNodeId != value.graphNodeId) {
                                        value.active = false;
                                    }
                                });
                            }
                        }
                    };
                    var isReparation = function(dti){
                    	var isRep = false;
                    	if(dti && dti.dossiersTransactionsInfo && dti.dossiersTransactionsInfo.length > 0){
                    		_.forEach(dti.dossiersTransactionsInfo, function(dtiAux){
                    			if(!isRep){
                     				isRep = ((dtiAux.dossierTransactionDefinition && dtiAux.dossierTransactionDefinition.transactionType && (dtiAux.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id ||dtiAux.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id))?true:false);	
                    			}
                    		});
                    	}
                    	return isRep;
                    }
                    
                    scope.isDisabled = function (dossierTransactionInfo) {
                        if (scope.dossierTransactionInfo.graphNodeId == dossierTransactionInfo.graphNodeId) {
                            return false;
                        } else {
                            if (!scope.level) {
                                return false;
                            } else if (scope.dossierTransactionInfo.active) {
                                return false;
                            } else {
                                if (scope.getAfterChecked(scope.dossierTransactionInfoOrig, dossierTransactionInfo)) {
                                    return false;
                                } else {
                                	if(scope.getAfterClosed(scope.dossierTransactionInfoOrig, dossierTransactionInfo)){
                                		return false;
                                    
                                	}else if(dossierTransactionInfo.graphNodeType == "SelectAllOpen" && isReparation(dossierTransactionInfo)){
	                                    return false;
                                    }else{
	                                    dossierTransactionInfo.active = false;
	                                    return true;
                                    }
                                }
                            }
                        }
                    };

                    scope.getAfterClosed = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                        var disabled = true;
                        if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                            disabled = dossierTransactionInfo.active;
                        } else {
                            var parent = scope.getParent(scope.dossierTransactionInfoOrig, dossierTransactionInfo);
                            if (parent && parent.graphNodeType && _.contains(['SelectMultipleOpen', 'SelectOne', 'SelectAllOpen'], parent.graphNodeType)) {
                            	parent = scope.getParent(scope.dossierTransactionInfoOrig, parent);
                            	if(parent && parent.graphNodeType && _.contains(['SelectMultipleClose', 'SelectAllClose', 'SelectAllOpen'], parent.graphNodeType)){
                            	    disabled = true;
                            	}else{
                            		disabled = false;
                            	}
                            } else {
                                disabled = false;
                            }
                        }
                        return disabled;
                    };

                    scope.getAfterChecked = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                        var disabled = true;
                        if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                            disabled = dossierTransactionInfo.active;
                        } else {
                            var parent = scope.getParent(scope.dossierTransactionInfoOrig, dossierTransactionInfo);
                            if (parent && !scope.selectType(3, parent, false)) {
                                disabled = scope.getAfterChecked(scope.dossierTransactionInfoOrig, parent);
                            } else {
                                disabled = parent.active;
                            }
                        }
                        return disabled;
                    };

                    scope.getParent = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                        var object;
                        if (dossierTransactionInfoOrig) {
                            if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                                object = dossierTransactionInfo;
                            } else {
                                angular.forEach(dossierTransactionInfoOrig.dossiersTransactionsInfo, function (value, key) {
                                    if (!object) {
                                        if (value.graphNodeId == dossierTransactionInfo.graphNodeId) {
                                            object = dossierTransactionInfoOrig;
                                        } else {
                                            object = scope.getParent(value, dossierTransactionInfo);
                                        }
                                    }
                                });
                            }
                        }
                        return object;
                    };

                    scope.isParallelClose = function (dossierTransactionInfo) {
                        if (dossierTransactionInfo && dossierTransactionInfo.pending && (dossierTransactionInfo.graphNodeType == 'SelectMultipleClose' || dossierTransactionInfo.graphNodeType == 'SelectAllClose')) {
                            return true;
                        } else {
                            if (dossierTransactionInfo && dossierTransactionInfo.dossiersTransactionsInfo && dossierTransactionInfo.dossiersTransactionsInfo.length == 1) {
                                return scope.isParallelClose(dossierTransactionInfo.dossiersTransactionsInfo[0]);
                            } else {
                                return false;
                            }
                        }
                    }

                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                }
            }
        }])
    .directive('annexaCompleteDossierTransaction', ['$filter', '$compile', 'Language', 'TramFactory', '$rootScope', 'apiAdmin', 'GlobalDataFactory','HelperService', function ($filter, $compile, Language, TramFactory, $rootScope, apiAdmin, GlobalDataFactory, HelperService) {
            var getTemplate = function (scope) {
                var template = '';
                template += '<div class="row">';
                template += '   <div class="col-sm-12">';
                template += '       <label ><span  translate="{{options.label}}">{{options.label}}</span></label>';
                template += '   </div>';
                template += '</div>';
                template += '<div class="row d-flex"  ng-repeat="next in transactionNoGuiadaList " ng-class="{\'m-b\':$last}">';
                template += '   <div class="col-xs-11 col-md-11 ">';
                template += '       <div class="row " >';
                template += '           <p class="col-xs-12 col-md-12  m-b-0">';
                template += '              <span class="small" translate="global.literals.name">Name</span>';
                template += '              <strong class="block _600 ">{{next.name}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-12 m-b-0">';
                template += '               <span class="small " translate="global.literals.type">Type</span>';
                template += '               <strong class="block _600 ">{{next.transactionType[languageColumn]}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-7 m-b-0">';
                template += '               <span class="small " translate="global.literals.tramitationPhase">Phase</span>';
                template += '               <strong class="block _600 ">{{next.tramitationPhase[languageColumn]}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-5 m-b-0">';
                template += '               <span class="small " translate="global.literals.profile">Type</span>';
                template += '               <strong class="pointer  _600 block" title="{{getProfileUsers(next.profile)}}">{{getProfileName(next)}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-4 m-b-0">';
                template += '               <span class="small " translate="global.literals.expireType">Expire Type</span>';
                template += '               <strong class="block _600 ">{{next.expirationType.description | translate}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-3 m-b-0">';
                template += '               <span class="small " translate="global.literals.expire">Max days</span>';
                template += '               <strong class="block _600 ">{{next.maxDays}}</strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-2 m-b-0">';
                template += '               <span class="small " translate="global.literals.daysComputeGlobal">Compute</span>';
                template += '               <strong class="block _600 " ng-if="next.daysComputeGlobal" translate="global.literals.yes"></strong>';
                template += '               <strong class="block _600 " ng-if="!next.daysComputeGlobal" translate="global.literals.no"></strong>';
                template += '           </p>';
                template += '           <p class="col-xs-12 col-md-12  m-b-0">';
                template += '               <span class="small " translate="global.literals.information_message">Description</span>';
                template += '               <strong class="block _600 ">{{next.description}}</strong>';
                template += '           </p>';
                template += '       </div>';
                template += '   </div>';
                template += '   <div class="col-xs-1 col-md-1 p-r-0">';
                template += '       <button  ng-click="removeFunction($index)" class="btn btn-xs l-h pull-right h-full b-a-0"><i class="fa fa-remove text-white" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                template += '   </div>';
                template += '</div> <!-- /row -->';

                template += '<div class="row p-t-xs b-t d-flex" ng-if="!transactionNoGuiadaList || transactionNoGuiadaList.length == 0 || responsibleUserBoolean">';
                template += '   <div class="col-xs-11 col-md-11">';
                template += '       <div class="row">';
                template += '           <div class="col-xs-12 col-md-12 ">';
                template += '              <strong class="small _600" translate="global.literals.name">Name</strong>';
                template += '              <span class=""block"><input type="text" class="col-sm-12 form-control" ng-model="nextInfo.name" maxlength="255"></span>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-12">';
                template += '               <strong class="small _600" translate="global.literals.type">Type</strong>';
                template += '               <div class="pos-rlt ">';
                template += '                   <button type="button" class="bt-deselect m-r-md" ng-click="clearTransactionType()" ng-show="nextInfo.transactionType && nextInfo.transactionType.id" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                template += '                   <ui-select fix-focus-on-touch ng-model="nextInfo.transactionType" theme="bootstrap" ng-change="selectTransactionType()">';
                template += '                       <ui-select-match>{{$select.selected[languageColumn]}}</ui-select-match>';
                template += '                       <ui-select-choices repeat="item in transactionsTypes | filter: $select.search">';
                template += '                           <div ng-bind-html="item[languageColumn]"></div>';
                template += '                       </ui-select-choices>';
                template += '                   </ui-select>';
                template += '               </div>';
                template += '           </div>';
                template += ' 			<div class="col-xs-12 col-md-7">';
                template += '               <strong class="small _600" translate="global.literals.tramitationPhase">Phase</strong>';
                template += '				<div class="ico-floating-wrapper ng-scope" ng-if="tramitationPhase.length > 1">';
                template += '                   <button type="button" class="bt-deselect m-r-md" ng-click="clearTramitationPhase()" ng-show="nextInfo.tramitationPhase" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                template += '                   <ui-select fix-focus-on-touch ng-model="nextInfo.tramitationPhase" theme="bootstrap">';
                template += '                       <ui-select-match>{{$select.selected[languageColumn] | translate}}</ui-select-match>';
                template += '                       <ui-select-choices repeat="item in tramitationPhase | filter: $select.search">';
                template += '                           <div ng-bind-html="item[languageColumn] | translate"></div>';
                template += '                       </ui-select-choices>';
                template += '                   </ui-select>';
                template += '               </div>';
                template += '               <strong class="block _600" ng-if="nextInfo.tramitationPhase != undefined && tramitationPhase.length == 1">{{nextInfo.tramitationPhase[languageColumn] | translate}}</strong>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-5">';
                template += '               <strong class="small _600" translate="global.literals.profile">Profile</strong>';
                template += '               <div class="ico-floating-wrapper" ng-if="(!options.sendInspectionProposal && !options.sendProposal && !options.omission && !options.reparation && !isOmissionTransaction()) || !nextInfo.profile">';
                template += '                   <input type="{{options.type}}" class=" form-control" ng-model="nextInfo.profile" placeholder="{{options.placeholder | translate}}" ng-disabled="formState.readOnly"  uib-typeahead="result as result.value for result in options.loadFunction({val: $viewValue})" typeahead-loading="loadingBox" typeahead-no-results="noResultBox" typeahead-wait-ms="1000" typeahead-editable="false">';
                template += '                   <span class="ico-floating" aria-hidden="true">';
                template += '                       <i ng-show="loadingBox" class="fa fa-spinner fa-pulse " aria-hidden="true"></i>';
                template += '                       <i ng-show="noResultBox" class="fa fa-ban" title="{{ \'noResults\' | translate}}"></i>';
                template += '                   </span>';
                template += '               </div>';
                template += '               <span class="block p-y-sm" ng-if="(options.sendProposal || options.sendInspectionProposal || options.omission || options.reparation || isOmissionTransaction()) && nextInfo.profile">{{nextInfo.profile.value}}</span>';
                template += '               <div class="row m-b-125" ng-if="showUsersProfiles" ng-show="nextInfo.profile.id">';
                template += '                   <div class="col-sm-12">';
                template += '                       <div class="row" ng-repeat="userProfile in nextInfo.profile.profile.userProfiles" ng-if="userProfile && userProfile.user && !userProfile.user.expiryDate  && !userProfile.user.deleted">';
                template += '                           <div class="col-sm-12">';
                template += '                               <input type="radio" ng-model="nextInfo.selectedResponsible" ng-value="userProfile.user"/>';
                template += '                               <span class="text-sm">';
                template += '                                   {{userProfile.user.name + \' \' + userProfile.user.surename1 + (userProfile.user.surename2 ? \' \' + userProfile.user.surename2 : \'\');}}';
                template += '                               </span>';
                template += '                           </div>';
                template += '                       </div>';
                template += '                   </div>';
                template += '               </div>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-7">';
                template += '               <strong class="small _600" translate="global.literals.expireType">Expire Type</strong>';
                template += '               <div class="ico-floating-wrapper ng-scope" ng-if="nextInfo.allowUserChangeMaxDays == undefined || nextInfo.allowUserChangeMaxDays">';
                template += '                   <button type="button" class="bt-deselect m-r-md" ng-click="clearExpirationType()" ng-show="nextInfo.expirationType" role="button"><i class="fa fa-remove" title="{{\'global.literals.remove\' | translate}}" aria-hidden="true"></i><span class="sr-only">{{\'global.literals.remove\' | translate}}</span></button>';
                template += '                   <ui-select fix-focus-on-touch ng-model="nextInfo.expirationType" theme="bootstrap">';
                template += '                       <ui-select-match>{{$select.selected.description | translate}}</ui-select-match>';
                template += '                       <ui-select-choices repeat="item in expirationTypes | filter: $select.search">';
                template += '                           <div ng-bind-html="item.description | translate"></div>';
                template += '                       </ui-select-choices>';
                template += '                   </ui-select>';
                template += '               </div>';
                template += '               <strong class="block _600" ng-if="nextInfo.allowUserChangeMaxDays != undefined && !nextInfo.allowUserChangeMaxDays">{{nextInfo.expirationType.description | translate}}</strong>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-3">';
                template += '               <strong class="small _600" translate="global.literals.expire">Max days</strong>';
                template += '               <span class="block" ng-if="nextInfo.allowUserChangeMaxDays == undefined || nextInfo.allowUserChangeMaxDays"><input type="number" class=" form-control" ng-model="nextInfo.maxDays" ng-disabled="formState.readOnly" min="0"></span>';
                template += '               <strong class="block _600" ng-if="nextInfo.allowUserChangeMaxDays != undefined && !nextInfo.allowUserChangeMaxDays">{{nextInfo.maxDays}}</strong>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-2">';
                template += '               <strong class="small _600" translate="global.literals.daysComputeGlobal">Compute</strong>';
                template += '               <div class="pos-rlt" ng-if="nextInfo.allowUserChangeCountDays == undefined || nextInfo.allowUserChangeCountDays">';
                template += '                   <ui-select fix-focus-on-touch ng-model="nextInfo.daysComputeGlobal" theme="bootstrap">';
                template += '                       <ui-select-match>{{$select.selected.description}}</ui-select-match>';
                template += '                       <ui-select-choices repeat="item.id as item in daysComputed | filter: $select.search">';
                template += '                           <div ng-bind-html="item.description"></div>';
                template += '                       </ui-select-choices>';
                template += '                   </ui-select>';
                template += '               </div>';
                template += '               <strong class="block _600 " ng-if="nextInfo.allowUserChangeCountDays != undefined && !nextInfo.allowUserChangeCountDays && nextInfo.daysComputeGlobal" translate="global.literals.yes"></strong>';
                template += '               <strong class="block _600 " ng-if="nextInfo.allowUserChangeCountDays != undefined && !nextInfo.allowUserChangeCountDays && !nextInfo.daysComputeGlobal" translate="global.literals.no"></strong>';
                template += '           </div>';
                template += '           <div class="col-xs-12 col-md-12 ">';
                template += '              <strong class="small _600" translate="global.literals.information_message">Description</strong>';
                template += '              <textarea class="col-sm-12 form-control"  maxlength="500"  rows="2" ng-model="nextInfo.description"></textarea>';
                template += '           </div>';
                template += '       </div><!-- /row -->';
                template += '   </div>';
                template += '   <div class="col-xs-1 col-md-1 p-r-0">';
                template += '      <button type="button" ng-click="addFunction()" ng-disabled="!showAdd()" class="pull-right b-a-0 h-full btn btn-xs primary"><i class="fa fa-check text-white" title="{{\'global.literals.add\' | translate}}"></i></button>';
                template += '   </div>';
                template += '</div><!-- /row -->';
                return template;
            };

            return {
                restrict: 'E',
                replace: true,
                scope: {
                    ngModel: '=',
                    options: '='
                },
                link: function (scope, element, attr) {
                    scope.transactionNoGuiadaList = scope.ngModel;
                    scope.nextInfo = {};
                    scope.languageColumn = Language.getActiveColumn();
					scope.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
					var dossier = TramFactory.Dossier;
                    scope.responsibleUserBoolean = false;
                    if (TramFactory.isResponsible(dossier)) {
                    	scope.responsibleUserBoolean = true;
                    }
                    scope.haveFinalTransaction = false;
                    scope.isOmissionTransaction = function(){
                    	if(scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id){
                    		return true;
                    	}else if(scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){
                    		return true;
                    	}else{
                    		return false;
                    	}                	
                    }
                    var finalTransactions = $linq(dossier.dossierTransactions).where("x => x.transactionType != null && x.transactionType.finallyType == true").toArray();
                    if (finalTransactions && finalTransactions.length > 0) {
                    	_.forEach(finalTransactions, function(value){
                    		if(dossier.procedure && !dossier.procedure.guided){
								if(!value.subprocedure){
									if(value.endDate == null){
			                    		vm.haveFinalTransaction = true;
			                    	}
								}
							}else{
	                    		if(value.endDate == null){
	                    			vm.haveFinalTransaction = true;
	                    		}
							}
                    	});
                    }
                    if (dossier.dossierTramitationType) {
                        scope.transactionsTypes = [];
                        var select = "x => x.active == 1";
    					_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
    						if($linq(value.tramitationTypes).contains(dossier.dossierTramitationType.id, "(x, y) => x.tramitationType.id == y")) {
    							scope.transactionsTypes.push(value);
    						}
                        });
    	                if ($rootScope.esetMode) {
    	                    if (scope.haveFinalTransaction && dossier.dossierUrgent) {
    	                    	scope.transactionsTypes = [];
    	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
    	                    		if(value.finallyType == false && $linq(value.tramitationTypes).contains(true, function(x,y){
    									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == dossier.dossierTramitationType.id) { return true; } else { return false; } 
    								})) {
    	                    			scope.transactionsTypes.push(value);
    								}
    	                        });
    	                    } else if (scope.haveFinalTransaction) {
    	                    	select += " && x.finallyType == false";
    	                    } else if (dossier.dossierUrgent) {
    	                    	scope.transactionsTypes = [];
    	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
    	                    		if($linq(value.tramitationTypes).contains(true, function(x,y){
    									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == dossier.dossierTramitationType.id) { return true; } else { return false; } 
    								})) {
    	                    			scope.transactionsTypes.push(value);
    								}
    	                        });
    	                    }
    	                } else {
    	                    if (dossier.dossierUrgent) {
    	                    	scope.transactionsTypes = [];
    	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
    								if($linq(value.tramitationTypes).contains(true, function(x,y){
    									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == dossier.dossierTramitationType.id) { return true; } else { return false; } 
    								})) {
    									scope.transactionsTypes.push(value);
    								}
    	                        });
    	                    }
    	                }
                        if (scope.options.sendProposal) {
                        	if(scope.options.nextDepartment){
	                        	//si s'envia la proposta a fiscalització de departament es filtrarà només pel subtipus de tràmit 'INSPECTION_DEPARTMENT'
	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[6].id;
                        	}else if(scope.options.nextIntervention){
                        		//si s'envia la proposta a fiscalització d'intervenció es filtrarà només pel subtipus de tràmit 'INSPECTION_INTERVENTION'
                        		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[5].id;
                        	}else{
	                            //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Acord'
	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
                        	}
                        } else if (scope.options.sendInspectionProposal) {
                        	if(scope.options.nextDepartment){
	                        	//si s'envia la proposta a fiscalització de departament es filtrarà només pel subtipus de tràmit 'INSPECTION_DEPARTMENT'
	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[9].id;
                        	}else if(scope.options.nextIntervention){
                        		//si s'envia la proposta a fiscalització d'intervenció es filtrarà només pel subtipus de tràmit 'INSPECTION_INTERVENTION_FREE'
                        		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[8].id;
                        	}else{
	                            //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Finalització de fiscalització'
	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[10].id;
                        	}
                        }else if(scope.options.omission){
                        	if (scope.options.free) {
                        		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[13].id;
                        	}else{
                        		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[11].id;
                        	}
                        }else if(scope.options.reparation){
                        	if (scope.options.free) {
                            	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[14].id;
                        	}else{
                        		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[12].id;
                        	}
                        }else if(scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id){  
                           	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
                    	}else if(scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){  
                   			select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[10].id;
                        } else {
                        	//en cas contrari es filtrarà pels subtipus de tràmit 'Estandard' i 'Proposta' i si el dossier permet fiscalització 'START_INSPECTION_FREE'
                        	var active = false;
                     	    var showDepartmentOrInterventionFree = undefined;
                       	    if(TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.inspectionIntervention != undefined){
                       		    if(TramFactory.Dossier.procedure.inspectionIntervention){
                       			    active = true;
                       		    }
                       	    }else{
                       		    var arcClassification = undefined;
                       		    if(TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected && TramFactory.Dossier.archiveClassification.model.$selected.id){
                       			    arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.archiveClassification.model.$selected.id);
                       		    }
                       		    if(arcClassification && arcClassification.inspectionIntervention){
                       			    active = true;
                       		    }else{
                       			    if(TramFactory.Dossier && TramFactory.Dossier.procedure.archiveClassification && TramFactory.Dossier.procedure.archiveClassification.id){
                           			    arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.procedure.archiveClassification.id);
                           		    }
                       			    if(arcClassification && arcClassification.inspectionIntervention){
                           			    active = true;
                           		    }
                       		    }
                       	    }
                         	if(active){
                        		select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[7].id + ")";
                         	}else{
                        		select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
                        	}
                        }
                        scope.transactionsTypes = $linq(scope.transactionsTypes).where(select).toArray();
                    }
                    if (scope.options && scope.options.atributes && scope.options.atributes.backbutton && dossier.procedure && dossier.procedure.transactionTypes) {
                        scope.transactionsTypes = $linq(dossier.procedure.transactionTypes).select("x => x.transactionType && x.active == 1").orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                    }
                    if (!scope.transactionsTypes) {
                        scope.transactionsTypes = [];
                    }
                    scope.expirationTypes = apiAdmin.expirationTypeList;
                    scope.tramitationPhase = GlobalDataFactory.tramitationPhase;
                    scope.daysComputed = [
                    	{id:true, description:$filter("translate")("global.literals.yes")},
                    	{id:false, description:$filter("translate")("global.literals.no")}
                    ];
                    scope.showAdd = function () {
                        var show = false;
                        if (scope.nextInfo && scope.nextInfo.profile && scope.nextInfo.profile.id && scope.nextInfo.transactionType && scope.nextInfo.transactionType.id && scope.nextInfo.maxDays > 0 && scope.nextInfo.expirationType) {
                            if (($rootScope.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							$rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')) && scope.nextInfo.selectedResponsible) {
                                show = true;
                            } else if (!$rootScope.esetMode && ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria')) {
                                show = true;
                            }
                        }
                        return show;
                    };

                    scope.addFunction = function () {
                        if (scope.nextInfo && scope.nextInfo.transactionType && scope.nextInfo.transactionType.finallyType  && !inSubprocedureNotGuided(scope.nextInfo.transactionType)) {
                            scope.createTransactionTypeList();
                        }
                        scope.transactionNoGuiadaList.push(scope.nextInfo);
                        scope.nextInfo = {};
                        scope.responsibleUserBoolean = false;
                        if (TramFactory.isResponsible(dossier)) {
                        	if (!scope.options.sendProposal){
                        		scope.responsibleUserBoolean = true;
                        	}else{
                        		if(!scope.transactionNoGuiadaList || scope.transactionNoGuiadaList.length == 0){
                        			scope.responsibleUserBoolean = true;
                        		}
                        	}
                        }
                    };

                    scope.clearTransactionType = function () {
                        scope.nextInfo.transactionType = undefined;
                        scope.nextInfo.expirationType = undefined;
                    };
                    scope.clearExpirationType = function () {
                        scope.nextInfo.expirationType = undefined;
                    };

                    scope.completeNextInfoSendProposal = function(){
                    	var pdt = $linq(TramFactory.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'PROPOSAL'");
                    	if(scope.options.transaction && scope.options.transaction.id){
                          	var proposals = $linq(TramFactory.proposalsDossierTransaction).where("x => x.dossierTransaction.id == " + scope.options.transaction.id).select("x => x.proposal").toArray();
                          	if (proposals && proposals.length > 0) {
                          		var proposal = $linq(TramFactory.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'PROPOSAL' && x.proposal && x.proposal.id == "+proposals[0].id);
                          		if(proposal && proposal.id){
                          			pdt = proposal;
                          		}
                          	}
                    	}
                    	//si s'envia la proposta s'hauran d'omplir els camps
                    	if(scope.options.nextDepartment){
                    		var prof =((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined); 
	                		scope.nextInfo = {
	                    		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[scope.languageColumn],
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
	                    		profile: {
	    	                    	id: ((prof)?prof.id:undefined),
	    	                    	value:((prof)?prof[scope.languageColumn]:undefined),
	    	                    	profile: prof
	    	                    }
	                    	};
                    	}else if(scope.options.nextIntervention){
                    		var prof = GlobalDataFactory.interventionProfile;
                    		if(!GlobalDataFactory.interventionProfile){
                    			prof = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.intervention_profile);
                    		}
	                		scope.nextInfo = {
	                    		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[scope.languageColumn],
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
	            				profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		}
	                    	};
                    	}else if(scope.options.omission || scope.options.reparation){
                    		var prof =((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined); 
                    		scope.nextInfo = {
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
                				profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		}
	                    	};
                    	}else{
							var secretaryProfileAux = GlobalDataFactory.secretaryProfile;
                    		if(!secretaryProfileAux){
                    			secretaryProfileAux = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
                    		}
                    		var prof = secretaryProfileAux
                    		scope.nextInfo = {
	                    		name: $filter('translate')('global.literals.manageOf') + pdt.dossierTransaction[scope.languageColumn],
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
                				profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		}
	                    	};
                    	}
                    	if (scope.nextInfo.transactionType) scope.selectTransactionType();
                    };
                    
                    scope.completeNextInfoSendInspectionProposal = function(){
                    	var pdt = {dossierTransaction : {}};
                    	if(scope.options && scope.options.transaction){
	                    	var inspectionProposals = $linq(TramFactory.inspectionProposalsDossierTransaction).where("x => x.dossierTransaction.id == " + scope.options.transaction.id).select("x => x.inspectionProposal").toArray();
	                    	if (inspectionProposals && inspectionProposals.length > 0) {
	                    		pdt = $linq(TramFactory.inspectionProposalsDossierTransaction).lastOrDefault(undefined, "x => x.inspectionProposalDossierTransactionType == 'PROPOSAL' && x.inspectionProposal && x.inspectionProposal.id == "+ inspectionProposals[0].id); 
	                    	}else{
	                    		pdt = {dossierTransaction: scope.options.transaction.id}
	                    	}
                    	}
                    	//si s'envia la proposta s'hauran d'omplir els camps
                    	if(scope.options.nextDepartment){
                    		var prof = ((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined); 
	                		scope.nextInfo = {
	                    		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[scope.languageColumn],
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
	                    		profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		} 
	                    	};
                    	}else if(scope.options.nextIntervention){
                    		var prof = GlobalDataFactory.interventionProfile;
                    		if(!GlobalDataFactory.interventionProfile){
                    			prof = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.intervention_profile);
                    		}
	                		scope.nextInfo = {
	                    		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[scope.languageColumn],
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
                				profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		}
	                    	};
                    	}else if(scope.options.omission || scope.options.reparation){
                    		var prof = ((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined);
                    		scope.nextInfo = {
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
	            				profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		}
	                    	};
                        }else{
                        	var prof = ((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined);
	                		scope.nextInfo = {
	                    		name: $filter('translate')('global.literals.finallyOf') + pdt.dossierTransaction[scope.languageColumn],
	                    		transactionType: (scope.transactionsTypes && scope.transactionsTypes.length == 1) ? scope.transactionsTypes[0] : '',
                				profile: {
	                    			id: ((prof)?prof.id:undefined),
	                    			value:((prof)?prof[scope.languageColumn]:undefined),
	                    			profile: prof
	                    		}
	                    	};
                    	}
                    	if (scope.nextInfo.transactionType) scope.selectTransactionType();
                    };

                    scope.createTransactionTypeList = function () {
                        var isFinal = false;
                        var finalTransactions = $linq(dossier.dossierTransactions).where("x => x.transactionType != null && x.transactionType.finallyType == true").toArray();
                        if (finalTransactions && finalTransactions.length > 0) {
                            if(dossier.procedure && !dossier.procedure.guided){
								_.forEach(finalTransactions, function(ft){
									if(!ft.subprocedure){
										isFinal = true;		
									}
								})
							}else{
		                    	isFinal = true;
							}
                        }
                        if (dossier.dossierTramitationType) {
                            scope.transactionsTypes = [];
                            var select = "x => x.active == 1";
        					_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
        						if($linq(value.tramitationTypes).contains(dossier.dossierTramitationType.id, "(x, y) => x.tramitationType.id == y")) {
        							scope.transactionsTypes.push(value);
        						}
                            });
        	                if ($rootScope.esetMode) {
        	                    if (isFinal && dossier.dossierUrgent) {
        	                    	scope.transactionsTypes = [];
        	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
        	                    		if(value.finallyType == false && $linq(value.tramitationTypes).contains(true, function(x,y){
        									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == dossier.dossierTramitationType.id) { return true; } else { return false; } 
        								})) {
        	                    			scope.transactionsTypes.push(value);
        								}
        	                        });
        	                    } else if (isFinal) {
        	                    	select += " && x.finallyType == false";
        	                    } else if (dossier.dossierUrgent) {
        	                    	scope.transactionsTypes = [];
        	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
        	                    		if($linq(value.tramitationTypes).contains(true, function(x,y){
        									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == dossier.dossierTramitationType.id) { return true; } else { return false; } 
        								})) {
        	                    			scope.transactionsTypes.push(value);
        								}
        	                        });
        	                    }
        	                } else {
        	                    if (dossier.dossierUrgent) {
        	                    	scope.transactionsTypes = [];
        	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
        								if($linq(value.tramitationTypes).contains(true, function(x,y){
        									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == dossier.dossierTramitationType.id) { return true; } else { return false; } 
        								})) {
        									scope.transactionsTypes.push(value);
        								}
        	                        });
        	                    }
        	                }
                            if (scope.options.sendProposal) {
                            	if(scope.options.nextDepartment){
    	                        	//si s'envia la proposta a fiscalització de departament es filtrarà només pel subtipus de tràmit 'INSPECTION_DEPARTMENT'
    	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[6].id;
                            	}else if(scope.options.nextIntervention){
                            		//si s'envia la proposta a fiscalització d'intervenció es filtrarà només pel subtipus de tràmit 'INSPECTION_INTERVENTION'
                            		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[5].id;
                            	}else{
    	                            //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Acord'
    	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
                            	}
                            } else if (scope.options.sendInspectionProposal) {
                            	if(scope.options.nextDepartment){
    	                        	//si s'envia la proposta a fiscalització de departament es filtrarà només pel subtipus de tràmit 'INSPECTION_DEPARTMENT'
    	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[9].id;
                            	}else if(scope.options.nextIntervention){
                            		//si s'envia la proposta a fiscalització d'intervenció es filtrarà només pel subtipus de tràmit 'INSPECTION_INTERVENTION_FREE'
                            		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[8].id;
                            	}else{
    	                            //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Finalització de fiscalització'
    	                        	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[10].id;
                            	}
                            }else if(scope.options.omission){
                            	if (scope.options.free) {
                            		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[13].id;
                            	}else{
                            		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[11].id;
                            	}
                            }else if(scope.options.reparation){
                            	if (scope.options.free) {
                                	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[14].id;
                            	}else{
                            		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[12].id;
                            	}
                            }else if(scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id){
	                           	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
	                    	}else if(scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){  
	                   			select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[10].id;
	                        } else {
                            	//en cas contrari es filtrarà pels subtipus de tràmit 'Estandard' i 'Proposta' i si el dossier permet fiscalització 'START_INSPECTION_FREE'
                            	var active = false;
                         	    var showDepartmentOrInterventionFree = undefined;
                           	    if(TramFactory.Dossier && TramFactory.Dossier.procedure && TramFactory.Dossier.procedure.inspectionIntervention != undefined){
                           		    if(TramFactory.Dossier.procedure.inspectionIntervention){
                           			    active = true;
                           		    }
                           	    }else{
                           		    var arcClassification = undefined;
                           		    if(TramFactory.Dossier && TramFactory.Dossier.archiveClassification && TramFactory.Dossier.archiveClassification.model && TramFactory.Dossier.archiveClassification.model.$selected && TramFactory.Dossier.archiveClassification.model.$selected.id){
                           			    arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.archiveClassification.model.$selected.id);
                           		    }
                           		    if(arcClassification && arcClassification.inspectionIntervention){
                           			    active = true;
                           		    }else{
                           			    if(TramFactory.Dossier && TramFactory.Dossier.procedure.archiveClassification && TramFactory.Dossier.procedure.archiveClassification.id){
                               			    arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+TramFactory.Dossier.procedure.archiveClassification.id);
                               		    }
                           			    if(arcClassification && arcClassification.inspectionIntervention){
                               			    active = true;
                               		    }
                           		    }
                           	    }
                             	if(active){
                            		select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[7].id + ")";
                             	}else{
                            		select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
                            	}
                            }
                            scope.transactionsTypes = $linq(scope.transactionsTypes).where(select).toArray();
                        }
                        if (scope.options && scope.options.atributes && scope.options.atributes.backbutton && dossier.procedure && dossier.procedure.transactionTypes) {
                            scope.transactionsTypes = $linq(dossier.procedure.transactionTypes).select("x => x.transactionType && x.active == 1").orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
                        }
                        
                        if (scope.options.sendProposal || ((scope.options.omission || scope.options.reparation) && !scope.options.free) || (scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id)) {
                        	scope.completeNextInfoSendProposal();
                        }
                        
                        if (scope.options.sendInspectionProposal || ((scope.options.omission || scope.options.reparation) && scope.options.free) || (scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id)) {
                        	scope.completeNextInfoSendInspectionProposal();
                        }
                    }
                    scope.removeFunction = function (index) {
                        if (scope.transactionNoGuiadaList && scope.transactionNoGuiadaList.length > index) {
                            scope.transactionNoGuiadaList.splice(index, 1);
                            scope.createTransactionTypeList();
                        }
                    };
                    scope.selectTransactionType = function () {
                        scope.nextInfo.maxDays = scope.nextInfo.transactionType.days;
                        scope.nextInfo.expirationType = {
                        	description: "global.literals." + scope.nextInfo.transactionType.expireType,
                        	id: scope.nextInfo.transactionType.expireType
                        }
                        if (scope.nextInfo.maxDays && dossier && dossier.dossierUrgent) {
                            scope.nextInfo.maxDays = Math.round(scope.nextInfo.maxDays / 2);
                        }
                        scope.nextInfo.daysComputeGlobal = scope.nextInfo.transactionType.countDays;
                        scope.tramitationPhase = $linq(scope.nextInfo.transactionType.phases).select("x => x.tramitationPhase").toArray();
                        if(scope.tramitationPhase.length == 1){
                        	scope.nextInfo.tramitationPhase = scope.tramitationPhase[0];
                        }else if(scope.tramitationPhase.length > 1){
                        	scope.nextInfo.tramitationPhase = undefined;
                        }
                        scope.nextInfo.allowUserChangeCountDays = scope.nextInfo.transactionType.allowUserChangeCountDays;
                        scope.nextInfo.allowUserChangeMaxDays = scope.nextInfo.transactionType.allowUserChangeMaxDays;
                    };
                    scope.getProfileUsers = function (profile) {
                        var names = '';
                        if (profile && profile.profile && profile.profile.userProfiles) {
                            angular.forEach(profile.profile.userProfiles, function (value, key) {
                                if (value.user && value.user.name) {
                                    names += value.user.name + '  ' + value.user.surename1 + ((value.user.surename2) ? ' ' + value.user.surename2 + '\n' : '\n');
                                }
                            });
                        }
                        return names;
                    };
                    scope.getProfileName = function (nextInfo) {
                        var name = '';
                        if (nextInfo && nextInfo.profile) {
                            name = nextInfo.profile.value;
                            if (nextInfo.selectedResponsible && nextInfo.selectedResponsible.id) {
                                name += " (" + nextInfo.selectedResponsible.name + '  ' + nextInfo.selectedResponsible.surename1 + ((nextInfo.selectedResponsible.surename2) ? ' ' + nextInfo.selectedResponsible.surename2 : '') + ")";
                            }
                        }
                        return name;
                    };
                    element.html(getTemplate(scope)).show();
                    $compile(element.contents())(scope);
                    
                    if (scope.options.sendProposal || ((scope.options.omission || scope.options.reparation) && !scope.options.free) || (scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id)) {
                    	scope.completeNextInfoSendProposal();
                    }

                    if (scope.options.sendInspectionProposal || ((scope.options.omission || scope.options.reparation) && scope.options.free) || (scope.options.transaction && scope.options.transaction.transactionType && scope.options.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id)) {
                    	scope.completeNextInfoSendInspectionProposal();
                    }
                }
            }
        }]);