
/**
 * Created by osirvent on 14/07/2016.
 */
angular
    .module('annexaApp')
    .factory('TramNewFactory',['$state', '$q', '$http', '$rootScope', 'Language', '$filter', 'AnnexaFormlyFactory', 'globalModals', 'HelperService', '$translate', 'ErrorFactory', 'dialogs', 'DccumentsFactory', 'ABMShapesFactory', 'RestService', 'SignLocalFactory', 'GlobalDataFactory', 'RegFactory', 'AnnexaModalFactory', 'AnnexaBoxDossierFactory', 'CommonService', 'DialogsFactory', 'CommonAdminModals','CacheFactory', 'SecFactory', 'AnnexaPermissionsFactory',
        function ($state, $q, $http, $rootScope, Language, $filter, AnnexaFormlyFactory, globalModals, HelperService, $translate, ErrorFactory, dialogs, DccumentsFactory, ABMShapesFactory, RestService, SignLocalFactory, GlobalDataFactory, RegFactory, AnnexaModalFactory, AnnexaBoxDossierFactory, CommonService, DialogsFactory, CommonAdminModals, CacheFactory, SecFactory, AnnexaPermissionsFactory) {
            var factory = {};
			factory.startedTramTemplateResponse = false;
        	factory.endedTramTemplateResponse = false;
        	$rootScope.$on('ModifyTramTemplatesGlobalDataCache', function(event,args){
        		var callTimeOut = function(step){
    				if(factory.endedTramTemplateResponse || step > 30){
        				if(factory.TramTemplates > 0){
                    		factory.TramTemplates = $linq(GlobalDataFactory.publictramtemplates).union(factory.TramTemplates,"(x,y) => x.id == y.id").toArray();
                    	}else{
                    		factory.TramTemplates = angular.copy(GlobalDataFactory.publictramtemplates);
                    	}
    				}else{
    					callTimeOut(step+1);
    				}
    			}
        		if(factory.startedTramTemplateResponse){
        			callTimeOut(1);
        		}
        	});

        	var calculateGroupListValues = function(groups, cfgs){
				_.forEach(groups, function(cf){
					if(cf.relatedCustomField && cf.relatedCustomField.fromQuery && cf.relatedCustomField.query && cf.relatedCustomField.query.id && cfgs && cfgs[cf.id]){
						var labelPropField = [];
						if(cf.labelPropField){
							labelPropField = cf.labelPropField.split("--@--@--");
						}
						var lvaluesActual = [];
						if(cf.identifierField && labelPropField && labelPropField.length > 0){
							_.forEach(cfgs[cf.id], function(lv){
								if(lv[cf.identifierField]){
									var label = '';
									_.forEach(labelPropField, function(lp){
										label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
									});
									if(cf.relatedCustomField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
										var cflvadded = {
											id:lv[cf.identifierField],
											language1:label,
											language2:label,
											language3:label,
											value:lv[cf.identifierField],
											parentValue:{id:lv[cf.parentField]}
										}
										lvaluesActual.push(cflvadded);
									}else{
										var cflvadded = {
											id:lv[cf.identifierField],
											language1:label,
											language2:label,
											language3:label,
											value:lv[cf.identifierField]
										}
										lvaluesActual.push(cflvadded);
									}
									if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
										_.forEach(cf.relatedCustomFields, function(rc){
											if(rc.customFieldValue && rc.customFieldTemplateTag){
												cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
											}
										})
									}
								}
							});
							cf.relatedCustomField = angular.copy(cf.relatedCustomField);
							cf.relatedCustomField.listValues = lvaluesActual
						}
					} else if(cf.relatedCustomField && cf.relatedCustomField.frontendType === 'CF_GROUP' && cf.groups && cfgs){
						calculateGroupListValues(cf.groups, cfgs);
					} 
				});
			}
        	
            factory.Dossier = undefined;
			factory.DossierArchiveClassification = undefined;
			factory.DossierCouncillor = undefined;
			factory.rightAccess = undefined;
			factory.isArchivedDossier = false;
			factory.emgdeDossierMetadata = undefined;
            factory.procedureStartProfiles = [];
            factory.canUpdateDossier = false;
			factory.userAccountInstances = [];
			factory.procedureOperationTypes = [];
            factory.TramTemplates = [];
			factory.initialTransactioTypeCustomFields = undefined;
			factory.Register = undefined;
			factory.Diligence = undefined;
			factory.Procedure = undefined;
			factory.userDefaultEntityProfile = undefined;
			factory.expireDate = undefined;
			factory.maxDays = undefined;
			factory.initialTransaction = undefined;
			factory.procedureDocumentationToProvide = undefined;
			factory.dossierDocumentationToProvide = undefined;
			
			factory.getProcedure = function (id, entity, register, diligence) {
                factory.Register = register;
                factory.Diligence = diligence;
                factory.Dossier = undefined;
				factory.procedureDocumentationToProvide = undefined;
				
                var promises = [];
                promises.push($http({url: './api/tram/procedure/start', method: 'GET', params: {idProcedure: id, idEntity: entity.id, idRegisterEntry:((register && register.id)?register.id:undefined) }}));
                promises.push($http({url: './api/transactiontypes/transactionTypesCustomFieldsByProcedure/'+id, method: 'GET'}));
				promises.push($http({url: './api/tram/new/dossier/procedureDocumentationToProvide/'+id, method: 'GET'}));
				promises.push($http({url: './api/procedures/calculateCustomFields/'+id+'/true/-1', method: 'GET'}));
				return $q.all(promises).then(function(data) {
                    var decData = JSOG.decode(data[0].data);
					factory.initialTransactioTypeCustomFields = undefined;
                    factory.Procedure = decData.procedure;
					factory.userDefaultEntityProfile = decData.userDefaultEntityProfile;
                    if (!factory.Procedure.archiveClassification) {
                        factory.Procedure.archiveClassification = {};
                    }
                    factory.expireDate = decData.expireDate;
                    factory.maxDays = decData.maxDays;
                    factory.nextNumSeqDossier = decData.nextNumSeqDossier;
                    if(factory.Procedure.guided){
                        var initialTransactions = $linq(factory.Procedure.transactions)
                            .where("x => x.transaction.graphNodeType == '"+ABMShapesFactory.TI+"'")
                            .toArray();
                        if(initialTransactions && initialTransactions.length > 0){
                            factory.initialTransaction = initialTransactions[0].transaction;
                        }else{
						    factory.initialTransaction = undefined;
                        }
                    }else {
                        var initialTransactions = $linq(factory.Procedure)
                            .join(factory.Procedure.transactionTransitions, "x => x.id", "x => x.procedure.id", "(outer, inner) => inner")
                            .where("x => x.currentTransaction == null")
                            .toArray();
                        factory.initialTransaction = null;
                        if (initialTransactions.length != 0) {
                            factory.initialTransaction = initialTransactions[0].nextTransaction;
                        }else{
	    					factory.initialTransaction = undefined;
						}
                    }
					var dataAux = JSOG.decode(data[1].data);
					factory.initialTransactioTypeCustomFields = dataAux;
					factory.procedureDocumentationToProvide = JSOG.decode(data[2].data);
					var cfsCalculated = JSOG.decode(data[3].data);
					if(cfsCalculated){
						if((cfsCalculated.queryCFs || cfsCalculated.queryCFGs) && factory.Procedure.customFields){
							_.forEach(factory.Procedure.customFields, function(cf){
								if(cf.customField && cf.customField.fromQuery && cf.customField.query && cf.customField.query.id && cfsCalculated.queryCFs && cfsCalculated.queryCFs[cf.id]){
									var labelPropField = [];
									if(cf.labelPropField){
										labelPropField = cf.labelPropField.split("--@--@--");
									}
									var lvaluesActual = [];
									if(cf.identifierField && labelPropField && labelPropField.length > 0){
										_.forEach(cfsCalculated.queryCFs[cf.id], function(lv){
											if(lv[cf.identifierField]){
												var label = '';
												_.forEach(labelPropField, function(lp){
													label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
												});
												if(cf.customField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField],
														parentValue:{id:lv[cf.parentField]}
													}
													lvaluesActual.push(cflvadded);
												}else{
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField]
													}
													lvaluesActual.push(cflvadded);
												}
												if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
													_.forEach(cf.relatedCustomFields, function(rc){
														if(rc.customFieldValue && rc.customFieldTemplateTag){
															cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
														}
													})
												}
											}
										});
										cf.customField = angular.copy(cf.customField);
										cf.customField.listValues = lvaluesActual
									}
								} else if(cf.customField && cf.customField.frontendType === 'CF_GROUP' && cf.groups && cfsCalculated.queryCFGs){
									calculateGroupListValues(cf.groups, cfsCalculated.queryCFGs);
								} 
							});
						}
						if((cfsCalculated.queryInitialCFs || cfsCalculated.queryInitialCFGs) && factory.initialTransactioTypeCustomFields){
							var keys = Object.keys(factory.initialTransactioTypeCustomFields);
                            _.forEach(keys, function(key){
								if(key && factory.initialTransactioTypeCustomFields[key]){
									_.forEach(factory.initialTransactioTypeCustomFields[key], function(cf){
										if(cf.customField && cf.customField.fromQuery && cf.customField.query && cf.customField.query.id && cfsCalculated.queryInitialCFGs && cfsCalculated.queryInitialCFGs[key] && cfsCalculated.queryInitialCFGs[key][cf.id]){
											var labelPropField = [];
											if(cf.labelPropField){
												labelPropField = cf.labelPropField.split("--@--@--");
											}
											var lvaluesActual = [];
											if(cf.identifierField && labelPropField && labelPropField.length > 0){
												_.forEach(cfsCalculated.queryCFs[key][cf.id], function(lv){
													if(lv[cf.identifierField]){
														var label = '';
														_.forEach(labelPropField, function(lp){
															label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
														});
														if(cf.customField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
															var cflvadded = {
																id:lv[cf.identifierField],
																language1:label,
																language2:label,
																language3:label,
																value:lv[cf.identifierField],
																parentValue:{id:lv[cf.parentField]}
															}
															lvaluesActual.push(cflvadded);
														}else{
															var cflvadded = {
																id:lv[cf.identifierField],
																language1:label,
																language2:label,
																language3:label,
																value:lv[cf.identifierField]
															}
															lvaluesActual.push(cflvadded);
														}
														if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
															_.forEach(cf.relatedCustomFields, function(rc){
																if(rc.customFieldValue && rc.customFieldTemplateTag){
																	cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
																}
															})
														}
													}
												});
												cf.customField = angular.copy(cf.customField);
												cf.customField.listValues = lvaluesActual
											}
										} else if(cf.customField.frontendType === 'CF_GROUP' && cf.groups && cfsCalculated.queryInitialCFGs && cfsCalculated.queryInitialCFGs[key]){
											calculateGroupListValues(cf.groups, cfsCalculated.queryInitialCFGs[key]);
										}
									});
								}
							})
						}
					}
		        }).catch(function (error) {
					factory.initialTransactioTypeCustomFields = undefined;
					factory.Register = undefined;
					factory.Diligence = undefined;
					factory.Procedure = undefined;
					factory.userDefaultEntityProfile = undefined;
					factory.expireDate = undefined;
					factory.maxDays = undefined;
					factory.initialTransaction = undefined;
					factory.procedureDocumentationToProvide = undefined;
                });
            };
				
			factory.newDossier = function (dossier, cfvalues, user, thirds, registerEntryInput, dossiers, registerEntryOutput, transactionType, documents, responsibleTransactionUser, profiles, addresses, cfDossierValues) {
                var deferred = $q.defer();
                var send = {};
                send.dossier = dossier;
                send.cfValues = cfvalues;
				send.cfDossierValues = cfDossierValues;
                send.user = user;
                if (transactionType) {
                    send.transactionType = {id: transactionType};
                }
                if (responsibleTransactionUser) {
                    send.responsibleTransactionUser = {id: responsibleTransactionUser};
                }
                if(documents && documents.length > 0){
                    send.documents = [];
                    angular.forEach(documents, function (value, key) {
                        value.document = {id: value.document.id};
                        send.documents.push(value);
                    });
                }
                if (factory.Register) {
                    var fixed_thirds = []
                    angular.forEach(thirds, function (value, key) {
                        value.third = {id: value.third.id};
                        if(value.representated && value.representated.id){
                            value.representated = {id: value.representated.id};
                        }
                        fixed_thirds.push(value);
                    });
                    send.dossierThirds = fixed_thirds;

                    var fixed_ri = []
                    angular.forEach(registerEntryInput, function (value, key) {
                        fixed_ri.push({id: value.id});
                    });
                    send.registerEntryInput = fixed_ri;

                    var fixed_ro = []
                    angular.forEach(registerEntryOutput, function (value, key) {
                        fixed_ro.push({id: value.id});
                    });
                    send.registerEntryOutput = fixed_ro;

                    send.register = {id: factory.Register.id};
                } else {
                    send.dossierThirds = thirds;
                    send.registerEntryInput = registerEntryInput;
                    send.registerEntryOutput = registerEntryOutput;
                    send.register = {id: -1};
                }
                if (factory.Diligence) {
                    send.diligence = {id: factory.Diligence.id};
                }
                if(addresses && addresses.length > 0){
                    send.addresses = [];
                    angular.forEach(addresses, function (value, key) {
                        value.address = {id: value.address.id};
                        send.addresses.push(value);
                    });
                }
                send.dossiers = dossiers;

                $http({
                    url: './api/tram/dossier',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };
			
			factory.getDossier = function (id, archiveModule, rightAccess) {
                factory.isArchivedDossier = ((archiveModule && archiveModule == true)?true:false);
				factory.dossierDocumentationToProvide = undefined;
                var promises = [];
                promises.push($http({url: './api/tram/new/dossier/'+id, method: 'GET', headers:{"callFromAnnexa":true}}));
                promises.push($http({url: './api/tramEMGDE/' + id+'/emgdeDossierMetadata', method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossier/procedureStartProfiles/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossier/canUpdateDossier/'+id, method: 'GET'}));
				promises.push($http({url: './api/tram/archive/rightAccess/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossier/userAccountInstances', method: 'GET'}));
				promises.push($http({url: './api/tram/new/dossier/procedureOperationTypes', method: 'GET', params: {idDossier: id}}));
				promises.push($http({url: './api/tram/new/dossier/documentationToProvide/'+id, method: 'GET'}));
				promises.push($http({url: './api/tram/new/dossier/calculateCustomFields/'+id+'/-1', method: 'GET'}));
				return $q.all(promises)
                    .then(function(data) {
						factory.Procedure = undefined;
                    	var dos = JSOG.decode(data[0].data);
                    	if(dos && $state.current && !archiveModule &&  (dos.dossierStatus == 'ARCHIVED' || dos.dossierStatus == 'REJECTED' || dos.dossierStatus == 'TRANSFER')){
                    		factory.Dossier = undefined;
							factory.DossierArchiveClassification = undefined;
							factory.DossierCouncillor = undefined;
							factory.rightAccess = undefined
							factory.emgdeDossierMetadata = undefined;
							factory.procedureStartProfiles = [];
							factory.canUpdateDossier = false;
            				factory.userAccountInstances = [];
                            factory.procedureOperationTypes = [];
							factory.dossierDocumentationToProvide = undefined;
            				dialogs.error(
                                    $filter('translate')('DIALOGS_ERROR_MSG'),
                                    $filter('translate')('global.archive.errorArchiveds'),
                                    {
                                        animation: true,
                                        backdrop: 'static',
                                        keyboard: true,
                                        size: ''
                                    }
                                );
                            $state.transitionTo('annexa.dashboard');
                    	}else{
							factory.Dossier = dos;
	                        factory.DossierArchiveClassification = angular.copy(factory.Dossier.archiveClassification);
	                        if (!factory.Dossier.archiveClassification) {
	                            factory.Dossier.archiveClassification = {};
	                        }
							factory.DossierCouncillor = angular.copy(factory.Dossier.councillor);
	                        if (!factory.Dossier.councillor) {
	                            factory.Dossier.councillor = {};
	                        }
							factory.emgdeDossierMetadata = JSOG.decode(data[1].data);
							factory.procedureStartProfiles =  JSOG.decode(data[2].data);
	                        if(factory.Dossier && factory.Dossier.procedure){
	                        	factory.Dossier.procedure.procedureStartProfiles = factory.procedureStartProfiles;
							}
							factory.canUpdateDossier = false;
							if(JSOG.decode(data[3].data)){
								if(HelperService.isSuperAdmin()){
									factory.canUpdateDossier = true;
								}
								if(!factory.canUpdateDossier && factory.Dossier && factory.Dossier.responsibleUser && factory.Dossier.responsibleUser.id && $rootScope.LoggedUser && $rootScope.LoggedUser.id && factory.Dossier.responsibleUser.id === $rootScope.LoggedUser.id){
									 factory.canUpdateDossier = true;
								}
								if(!factory.canUpdateDossier && factory.Dossier && factory.Dossier.dossierResponsibleProfiles && factory.Dossier.dossierResponsibleProfiles.length > 0 ){
									var profilesOk = $linq(factory.Dossier.dossierResponsibleProfiles).firstOrDefault(undefined, function(x){
										if(x.profile && x.profile.id && $rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
											var psOk = $linq($rootScope.LoggedUser.userProfiles).firstOrDefault(undefined, "x=>x.profile.id == "+x.profile.id); 
											if(psOk){
												return true
											}else{
												return false
											}
										}else{
											return false;
										}
									});
									if(profilesOk){
										factory.canUpdateDossier = true;
									}
								}
								if(!factory.canUpdateDossier && factory.Dossier && factory.Dossier.dossierTransactions){
									var actualsWithPermissions = $linq(factory.Dossier.dossierTransactions).firstOrDefault(undefined,function(x){ 
										if(x.actual){ 
											if(x.processProfiles){
												var ps = $linq(x.processProfiles).firstOrDefault(undefined, function(y){
													if(y.profile && y.profile.id && $rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
														var psOk = $linq($rootScope.LoggedUser.userProfiles).firstOrDefault(undefined, "x=>x.profile.id == "+y.profile.id); 
														if(psOk){
															return true
														}else{
															return false
														}
													}else{
														return false
													}
												}); 
												if(ps){
													return true;
												}else{
													return false;
												}
											}else{
												return false;
											}
										}else{
											return false
										}
									});
									if(actualsWithPermissions){
					                	factory.canUpdateDossier = true;
									}		
								}
							}
							factory.rightAccess = JSOG.decode(data[4].data);
							factory.userAccountInstances = JSOG.decode(data[5].data);
							factory.procedureOperationTypes = JSOG.decode(data[6].data);
							factory.dossierDocumentationToProvide = JSOG.decode(data[7].data);
                            var cfsCalculated = JSOG.decode(data[8].data);
        					if(factory.Dossier.customFields){
        						if(cfsCalculated.showCFs){
        							var keys = Object.keys(cfsCalculated.showCFs);
                                    _.forEach(keys, function(key){
                                    	var indexCFk = $linq(factory.Dossier.customFields).indexOf("x => x.id == "+key );
                                    	if(indexCFk > -1){
                                    		if(cfsCalculated.showCFs[key]){
        	            						factory.Dossier.customFields[indexCFk].invisible = true;
        	            					}else{
        	            						factory.Dossier.customFields[indexCFk].invisible = false;
        	            					}
                                    	}
                                    });
        						}
        						if(cfsCalculated.showCFGs){
        							var deleteGCFFromGroup = function(groups, key, show){
        								var deleted = false;
        								if(groups){
        									var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
											if(indexCFk > -1){
												if(show){
													groups[indexCFk].invisible = true;
												}else{
													groups[indexCFk].invisible = false;
												}
												deleted = true;
											}else{
												_.forEach(groups, function(cfAux){
													if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
														deleted = deleteGCFFromGroup(cfAux.groups, key, show);
													}
												});
        									}
        								}
        								return deleted;
        							}
        							var keys = Object.keys(cfsCalculated.showCFGs);
                                    _.forEach(keys, function(key){
                                    	var deleted = false;
                                    	_.forEach(factory.Dossier.customFields, function(cfAux){
                                    		if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
                                    			deleted = deleteGCFFromGroup(cfAux.groups, key, cfsCalculated.showCFGs[key]);
                                    		}
                                    	});
                                    });
        						}
    							_.forEach(factory.Dossier.customFields, function(cf){
    								if(cfsCalculated && cfsCalculated.queryCFs){ 
	    								if(cf.customField && cf.customField.fromQuery && cf.customField.query && cf.customField.query.id && cfsCalculated.queryCFs && cfsCalculated.queryCFs[cf.id]){
	    									var labelPropField = [];
	    									if(cf.labelPropField){
	    										labelPropField = cf.labelPropField.split("--@--@--");
	    									}
	    									var lvaluesActual = [];
	    									if(cf.identifierField && labelPropField && labelPropField.length > 0){
	    										_.forEach(cfsCalculated.queryCFs[cf.id], function(lv){
	    											if(lv[cf.identifierField]){
	    												var label = '';
	    												_.forEach(labelPropField, function(lp){
	    													label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
	    												});
	    												if(cf.customField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
	    													var cflvadded = {
	    														id:lv[cf.identifierField],
	    														language1:label,
	    														language2:label,
	    														language3:label,
	    														value:lv[cf.identifierField],
	    														parentValue:{id:lv[cf.parentField]}
	    													}
	    													lvaluesActual.push(cflvadded);
	    												}else{
	    													var cflvadded = {
	    														id:lv[cf.identifierField],
	    														language1:label,
	    														language2:label,
	    														language3:label,
	    														value:lv[cf.identifierField]
	    													}
	    													lvaluesActual.push(cflvadded);
	    												}
	    												if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
	    													_.forEach(cf.relatedCustomFields, function(rc){
	    														if(rc.customFieldValue && rc.customFieldTemplateTag){
	    															cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
	    														}
	    													})
	    												}
	    											}
	    										});
	    										cf.customField = angular.copy(cf.customField);
	    										cf.customField.listValues = lvaluesActual
	    									}
	    								} else if(cf.customField && cf.customField.frontendType === 'CF_GROUP' && cf.groups && cfsCalculated.queryCFGs){
	    									calculateGroupListValues(cf.groups, cfsCalculated.queryCFGs);
	    								}
    								}
    							});
    							if(cfsCalculated.calculatedCFs){
    								var keys = Object.keys(cfsCalculated.calculatedCFs);
    	                            _.forEach(keys, function(key){
    	                            	var cfToChange = $linq(factory.Dossier.customFields).firstOrDefault(undefined, "x => x.id == "+key );
    	            					if(cfToChange){
    	            						cfToChange.value = cfsCalculated.calculatedCFs[key];
    	            					}
    	                            });
    							}
    							if(cfsCalculated.calculatedCFGs){
        							var calculateGCFFromGroup = function(groups, key, calculateds){
        								var calc = false;
        								if(groups){
        									var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
											if(cFk){
												cFk.value = calculateds[key];
												calc = true;
											}else{
												_.forEach(groups, function(cfAux){
													if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
														calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
													}
												});
        									}
        								}
        								return calc;
        							}
        							var keys = Object.keys(cfsCalculated.calculatedCFGs);
                                    _.forEach(keys, function(key){
            	 						var calc = false;
	            						_.forEach(factory.Dossier.customFields, function(cfAux){
	            							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
	            								calc = calculateGCFFromGroup(cfAux.groups, key, cfsCalculated.calculatedCFGs);
	            							}
	            						});
                                	});
        						}
        					}
        					var permissionDossierView = false;
        					if(!factory.canUpdateDossier){
        						var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
        						var procedureViewProfiles = $linq(factory.Dossier.procedure.procedureViewProfiles).select("x =>x.profile.id").toArray();
    							var dossierProcessProfiles = [];
    							_.forEach(factory.Dossier.dossierTransactions, function(dossierTransaction){
    								var dossierTransactionProfiles = $linq(dossierTransaction.processProfiles).select("x =>x.profile.id").toArray();
    								dossierProcessProfiles = dossierProcessProfiles.concat(dossierTransactionProfiles);
    							});
    							dossierProcessProfiles = $linq(dossierProcessProfiles).distinct().toArray();
        						var procedureTramitationProfiles = $linq(factory.Dossier.procedure.procedureTramitationProfiles).select("x => x.profile.id").toArray();
        						var dossierResponsibleProfiles = $linq(factory.Dossier.dossierResponsibleProfiles).select("x => x.profile.id").toArray();
        						
        						var profileView = $linq(userProfiles).intersect(procedureViewProfiles, "(x,y)=> x == y").toArray();
        						var profileProcess = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y)=> x == y").toArray();
        						var profileResponsible = $linq(userProfiles).intersect(dossierResponsibleProfiles, "(x,y)=> x == y").toArray();
        						var profileTramitation = $linq(userProfiles).intersect(procedureTramitationProfiles, "(x,y)=> x == y").toArray();
        						var isResponsible = (( factory.Dossier.responsibleUser && factory.Dossier.responsibleUser.id)? factory.Dossier.responsibleUser.id == $rootScope.LoggedUser.id : false);
				
        						if(profileView > 0 || profileProcess > 0 || profileTramitation > 0 || profileResponsible > 0 || isResponsible ){
        							permissionDossierView = true;
        						}else if(AnnexaPermissionsFactory.havePermission('see_all_dossier')){
        							permissionDossierView = true;
        						}
        					}else{
        						permissionDossierView = true;
        					}
        					if(!factory.rightAccess && !permissionDossierView){
        						$state.transitionTo('annexa.dashboard');
        					}
                    	}
                    }).catch(function() {
						factory.Dossier = undefined;
						factory.Procedure = undefined;
						factory.DossierArchiveClassification = undefined;
						factory.DossierCouncillor = undefined;
						factory.rightAccess = undefined
						factory.emgdeDossierMetadata = undefined;
						factory.procedureStartProfiles = [];
						factory.canUpdateDossier = false;
						factory.userAccountInstances = [];
                        factory.procedureOperationTypes = [];
						factory.dossierDocumentationToProvide = undefined;
            			$state.transitionTo('annexa.dashboard');
                    })
         	};
			factory.calculateCanUpdateDossier = function(dossier){
				factory.canUpdateDossier = false; 
				if(HelperService.isSuperAdmin()){
					factory.canUpdateDossier = true;
				}
				if(!factory.canUpdateDossier && dossier && dossier.responsibleUser && dossier.responsibleUser.id && $rootScope.LoggedUser && $rootScope.LoggedUser.id && dossier.responsibleUser.id === $rootScope.LoggedUser.id){
					 factory.canUpdateDossier = true;
				}
				if(!factory.canUpdateDossier && dossier && dossier.dossierResponsibleProfiles && dossier.dossierResponsibleProfiles.length > 0 ){
					var profilesOk = $linq(dossier.dossierResponsibleProfiles).firstOrDefault(undefined, function(x){
						if(x.profile && x.profile.id && $rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
							var psOk = $linq($rootScope.LoggedUser.userProfiles).firstOrDefault(undefined, "x=>x.profile.id == "+x.profile.id); 
							if(psOk){
								return true
							}else{
								return false
							}
						}else{
							return false;
						}
					});
					if(profilesOk){
						factory.canUpdateDossier = true;
					}
				}
				if(!factory.canUpdateDossier && dossier && dossier.dossierTransactions){
					var actualsWithPermissions = $linq(dossier.dossierTransactions).firstOrDefault(undefined,function(x){ 
						if(x.actual){ 
							if(x.processProfiles){
								var ps = $linq(x.processProfiles).firstOrDefault(undefined, function(y){
									if(y.profile && y.profile.id && $rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
										var psOk = $linq($rootScope.LoggedUser.userProfiles).firstOrDefault(undefined, "x=>x.profile.id == "+y.profile.id); 
										if(psOk){
											return true
										}else{
											return false
										}
									}else{
										return false
									}
								}); 
								if(ps){
									return true;
								}else{
									return false;
								}
							}else{
								return false;
							}
						}else{
							return false
						}
					});
					if(actualsWithPermissions){
	                	factory.canUpdateDossier = true;
					}		
				}
			}
			
			factory.getDossierTransaction = function (id, dossierId) {
                var deferrend = $q.defer();
	            var promises = [];
                promises.push($http({url: './api/tram/new/dossierTransaction/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossier/dossierTransactionProcessProfiles', method: 'GET', params: {idDossier: dossierId}}));
                promises.push($http({url: './api/tram/new/dossierTransaction/proposalsDossierTransaction/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossierTransaction/inspectionProposalsDossierTransaction/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/procedure_proposal_types/inspectionInterventionActive/'+id, method: 'GET'}));
                $q.all(promises)
                    .then(function(data) {
						deferrend.resolve(data);
                    }).catch(function(error) {
						deferrend.reject(error);
					})
				return deferrend.promise;
            };

			factory.getProposal = function (id, dossierId) {
                var deferrend = $q.defer();
	            var promises = [];
                promises.push(RestService.findOne('Proposal', id, 'dossierTransactionTab'));
                promises.push($http({url: './api/tram/new/dossierTransaction/proposalsDecree/'+id, method: 'GET'}));
				promises.push($http({url: './api/tram/new/dossier/allDossierDocuments/'+dossierId, method: 'GET'}));
				promises.push($http({url: './api/sec/sec_notification/canAddedSecConfiguration/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossier/proposalsDocuments/'+dossierId+'/'+id, method: 'GET'}));
                promises.push($http({url: './api/tram/procedure_proposal_types/configuration_by_proposal/'+dossierId+'/'+id, method: 'GET'}));
				promises.push($http({url: './api/sec/sec_notification/isNotificableFromSecretary/'+id, method: 'GET'}));
				promises.push($http({url: './api/sec/sec_notification/getSecNotificationsConfiguration/proposal/'+id, method: 'GET'}));
				$q.all(promises)
                    .then(function(data) {
						deferrend.resolve(data);
                    }).catch(function(error) {
						deferrend.reject(error);
					})
				return deferrend.promise;
            };

			factory.getDossierThirds = function (dossierId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/dossierThirds/'+dossierId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
			factory.getDossierThird = function (dossierId,thirdId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/dossierThirds/'+dossierId+'/'+thirdId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
			factory.getDossierDocuments = function (dossierId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/dossierDocuments/'+dossierId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
			factory.getDossierTransactionDocuments = function (dossierId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/allDossierDocuments/'+dossierId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
			factory.getThisDossierTransactionDocuments = function (dossierTransactionId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/dossierTransactionDocuments/'+dossierTransactionId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
			factory.updateDossierTransactionProcessProfile = function (idDossierTransactionProcessProfile, idProfile) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/dossierTransactionProcessProfile/'+idDossierTransactionProcessProfile+'/'+idProfile,
                    method: 'PUT'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };  

			factory.getDossierTransactionCustomFields = function (dossierTransactionId) {
                var deferred = $q.defer();
                var promises = [];
                promises.push($http({url: './api/tram/new/dossierTransaction/dossierTransactionCustomFields/'+dossierTransactionId,method: 'GET'}));
                promises.push($http({url: './api/tram/new/dossierTransaction/calculateCustomFields/'+dossierTransactionId+'/-1', method: 'GET'}));
				$q.all(promises).then(function(data) {
					var customFields = JSOG.decode(data[0].data);
					var cfsCalculated = JSOG.decode(data[1].data);
					if(customFields){
						if(cfsCalculated.showCFs){
							var keys = Object.keys(cfsCalculated.showCFs);
                            _.forEach(keys, function(key){
                            	var indexCFk = $linq(customFields).indexOf("x => x.id == "+key );
                            	if(indexCFk > -1){
                            		if(cfsCalculated.showCFs[key]){
	            						customFields[indexCFk].invisible = true;
	            					}else{
	            						customFields[indexCFk].invisible = false;
	            					}
                            	}
                            });
						}
						if(cfsCalculated.showCFGs){
							var deleteGCFFromGroup = function(groups, key, show){
								var deleted = false;
								if(groups){
									var indexCFk = $linq(groups).indexOf("x => x.id == "+key );
									if(indexCFk > -1){
										if(show){
											groups[indexCFk].invisible = true;
										}else{
											groups[indexCFk].invisible = false;
										}
										deleted = true;
									}else{
										_.forEach(groups, function(cfAux){
											if(!deleted && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
												deleted = deleteGCFFromGroup(cfAux.groups, key, show);
											}
										});
									}
								}
								return deleted;
							}
							var keys = Object.keys(cfsCalculated.showCFGs);
                            _.forEach(keys, function(key){
                           		var deleted = false;
           						_.forEach(customFields, function(cfAux){
           							if(!deleted && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
           								deleted = deleteGCFFromGroup(cfAux.groups, key, cfsCalculated.showCFGs[key]);
           							}
           						});
                            });
						}
						_.forEach(customFields, function(cf){
							if(cfsCalculated && cfsCalculated.queryCFs){ 
								if(cf.customField && cf.customField.fromQuery && cf.customField.query && cf.customField.query.id && cfsCalculated.queryCFs && cfsCalculated.queryCFs[cf.id]){
									var labelPropField = [];
									if(cf.labelPropField){
										labelPropField = cf.labelPropField.split("--@--@--");
									}
									var lvaluesActual = [];
									if(cf.identifierField && labelPropField && labelPropField.length > 0){
										_.forEach(cfsCalculated.queryCFs[cf.id], function(lv){
											if(lv[cf.identifierField]){
												var label = '';
												_.forEach(labelPropField, function(lp){
													label = label + ((label)?((cf.labelFieldsSeparator)?cf.labelFieldsSeparator:'')+" "+((lv[lp])?lv[lp]:''):((lv[lp])?lv[lp]:''));
												});
												if(cf.customField.frontendType === 'STRUCTURAL_SELECT' && cf.parentField && lv[cf.parentField]){
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField],
														parentValue:{id:lv[cf.parentField]}
													}
													lvaluesActual.push(cflvadded);
												}else{
													var cflvadded = {
														id:lv[cf.identifierField],
														language1:label,
														language2:label,
														language3:label,
														value:lv[cf.identifierField]
													}
													lvaluesActual.push(cflvadded);
												}
												if(cf.relatedCustomFields && cf.relatedCustomFields.length > 0){
													_.forEach(cf.relatedCustomFields, function(rc){
														if(rc.customFieldValue && rc.customFieldTemplateTag){
															cflvadded[rc.customFieldTemplateTag] = ((lv[rc.customFieldValue])?lv[rc.customFieldValue]:undefined);
														}
													})
												}
											}
										});
										cf.customField = angular.copy(cf.customField);
										cf.customField.listValues = lvaluesActual
									}
								} else if(cf.customField && cf.customField.frontendType === 'CF_GROUP' && cf.groups && cfsCalculated.queryCFGs){
									calculateGroupListValues(cf.groups, cfsCalculated.queryCFGs);
								}
							}
						});
						if(cfsCalculated.calculatedCFs && customFields){
							var keys = Object.keys(cfsCalculated.calculatedCFs);
                            _.forEach(keys, function(key){
                            	var cfToChange = $linq(customFields).firstOrDefault(undefined, "x => x.id == "+key );
            					if(cfToChange){
            						cfToChange.value = cfsCalculated.calculatedCFs[key];
            					}
                            });
						}
						if(cfsCalculated.calculatedCFGs){
							var calculateGCFFromGroup = function(groups, key, calculateds){
								var calc = false;
								if(groups){
									var cFk = $linq(groups).firstOrDefault(undefined, "x => x.id == "+key );
									if(cFk){
										cFk.value = calculateds[key];
										calc = true;
									}else{
										_.forEach(groups, function(cfAux){
											if(!calc && cfAux.relatedCustomField && cfAux.relatedCustomField.frontendType === 'CF_GROUP'){
												calc = calculateGCFFromGroup(cfAux.groups, key, calculateds);
											}
										});
									}
								}
								return calc;
							}
							var keys = Object.keys(cfsCalculated.calculatedCFGs);
                            _.forEach(keys, function(key){
    	 						var calc = false;
        						_.forEach(customFields, function(cfAux){
        							if(!calc && cfAux.customField && cfAux.customField.frontendType === 'CF_GROUP'){
        								calc = calculateGCFFromGroup(cfAux.groups, key, cfsCalculated.calculatedCFGs);
        							}
        						});
                        	});
						}
					}
					deferred.resolve(JSOG.encode(customFields));
                }).catch(function(error) {
                	deferred.reject(error);
				})
				return deferred.promise;
            };  

			factory.updateDossierTransactionCustomField = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossierTransaction/dossierTransactionCustomFields',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.calculateDossierTransactionDocumentsCounters = function(dossierTransactionId){
				var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/calculateDossierTransactionDocumentsCounters/'+dossierTransactionId,
                    method: 'GET'
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
			}
			
			factory.deleteTask = function (transaction, taskId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossierTransaction/deleteTask/'+transaction+'/'+taskId,
                    method: 'POST'
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

			factory.addTasks = function (transaction, taskId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/addTasks/'+transaction+'/'+taskId,
                    method: 'POST'
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            };
			
			factory.createDossierThird = function(dossierThird) {
                var deferred = $q.defer();
                $http({
                    url: './api/dossier_third/',
                    method: 'POST',
                    data: JSOG.encode(dossierThird)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
			factory.updateDossierThird = function(id, dossierThird) {
                var deferred = $q.defer();
                $http({
                    url: './api/dossier_third/'+id,
                    method: 'PUT',
                    data: JSOG.encode(dossierThird)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }
			factory.deleteDossierThird = function(dossierThirdId) {
                var deferred = $q.defer();
                $http({
                    url: './api/dossier_third/'+dossierThirdId,
                    method: 'DELETE'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }

			factory.setPrincipalDossierThird = function(dossierThirdId) {
                var deferred = $q.defer();
                $http({
                    url: './api/dossier_third/principal/'+dossierThirdId,
                    method: 'PUT'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
			
			factory.getPrincipalDossierThird = function(dossierId) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/principal/'+dossierId,
                    method: 'GET'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
			
			factory.createRelatedRegisterDossier = function(dossierId, relatedRegisters) {
				var deferred = $q.defer();
				 $http({
                    url: './api/tram/new/dossier/' + dossierId + '/add_register_entry/' + CommonService.getParameterList(relatedRegisters),
                    method: 'POST'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
			
			factory.createRelatedRegisterDossierWithDocsAndReceivers = function(dossierTransactionId, addedRegisters, selectedReceivers, selectedDocuments){
				var deferred = $q.defer();
				$http({
	                url: './api/tram/new/dossier/attach_register_to_dossier_transaction/' + dossierTransactionId+'/'+ CommonService.getParameterList(addedRegisters)+'/'+ CommonService.getParameterList(selectedReceivers)+'/'+CommonService.getParameterList(selectedDocuments),
	                method: 'POST'
	            }).then(function(data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
			
			
			factory.deleteRegister = function (dossierId, registerId, registerType) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/deleteRelatedEntriesDossier/'+dossierId+'/'+registerId+'/'+registerType,
                    method: 'PUT'
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

			factory.newRelatedDossier = function(dossierId, dossierIds, relation){
                var deferrend = $q.defer();
                $http({
                    method: 'PUT',
                    url: './api/tram/new/dossier/' + dossierId + '/addRelatedDossier/'+CommonService.getParameterList(dossierIds)+'/'+relation,
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };
            
            factory.deleteRelatedDossier = function(dossierId, relatedDossierId){
                var deferrend = $q.defer();
                $http({
                    method: 'PUT',
                    url: './api/tram/new/dossier/' + dossierId + '/deleteRelatedDossier/'+relatedDossierId,
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };
			
			factory.addAddresses = function(dossierId, addresses){
                var deferrend = $q.defer();
                $http({
                    url: './api/tram/new/dossier/' + dossierId + '/address/' + $linq(addresses).select("x => x.address && x.address.id").toArray(),
                    method: 'POST'
                }).success(function(data, status) {
                    deferrend.resolve(JSOG.decode(data));
                }).error(function(msg,code) {
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };

			factory.deleteAddress = function(dossierId, addressId){
                var deferrend = $q.defer();
                $http({
                    url: './api/tram/new/dossier/' + dossierId + '/address/' + addressId,
                    method: 'DELETE'
                }).then(function (data) {
                    deferrend.resolve(JSOG.decode(data));
                }).catch(function(error) {
					console.log(error);
                    deferrend.reject(msg);
                });
                return deferrend.promise;
            };

			factory.addAccessRight = function(){
            	if(factory.Dossier && factory.Dossier.id){
            		 var modal = angular.copy(globalModals.addAccessRights);
                     modal.annexaFormly.model = {};
                     modal.annexaFormly.model.modal_body = {};
                     modal.annexaFormly.options = {};
                     modal.annexaFormly.options.formState = { readOnly: false};
                     modal.annexaFormly.model.modal_body.accessRightType = "USER";
                     var submitFunction = function() {
                         var self = this;
                         self.alerts.length = 0;
                         var model = ((this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body)?this.annexaFormly.model.modal_body:{});
                         var send = [];
                         if(model.accessRightType){
                        	 var today = new Date();
                        	 var m = today.getMonth();
                        	 var d = today.getDate();
                        	 var y = today.getFullYear();
                        	 if(model.startDate > model.endDate || model.startDate < new Date(y,m,d)){
                        		 this.alerts.push({ msg: $filter('translate')('global.archive.accessRightDateError') });
                        	 }else{
                        		 var existObject = false;
                        		 if(model.accessRightType == 'USER'){
                        			 if(model.user && model.user.id){
                        				 existObject = true;
                        				 send.push({
                        					 startDate: model.startDate,
                        					 endDate:model.endDate,
                        					 user: {id:model.user.id},
                        					 dossier: {id:factory.Dossier.id}
                        				 });	                        		 
                        			 }
                        		 } else if(model.accessRightType == 'THIRD'){
                        			 if(model.third && model.third.id){
                        				 existObject = true;
                        				 send.push({
                        					 startDate: model.startDate,
                        					 endDate:model.endDate,
                        					 third: {id:model.third.id},
                        					 dossier: {id:factory.Dossier.id} 
                        				 });
                        			 }
                        		 } else if(model.accessRightType == 'PROFILE'){
                        			 if(model.profiles && model.profiles.length > 0){
                        				 _.forEach(model.profiles, function(profile){
                        					 existObject = true;
                        					 send.push({
                        						 startDate: model.startDate,
                        						 endDate:model.endDate,
                        						 profile: {id:profile},
                        						 dossier: {id:factory.Dossier.id} 
                        					 });	                        			 
                        				 });
                        			 }
                        		 }
                        		 if(existObject){
                        			 $http({
                                         url: './api/tram/archive/generateRightAccess',
                                         method: 'POST',
                                         data: send, 
										 headers:{"callFromAnnexa":true}
                                     }).then(function (data, status, headers) {
										 $rootScope.$broadcast('annexaBoxDossierRightAccessReloadTable', {});
										 self.close();
                                     }).catch(function (error) {
                                         self.alerts.push({msg:  $filter('translate')('global.archive.accessRightError')});
                                     });
                        		 }
                        	 }
                         }
                     }
                     AnnexaFormlyFactory.showModal('addAccessRightsModal', modal, submitFunction, false, undefined);
            	}
            };

			factory.acceptRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/acceptRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		deferrend.resolve(JSOG.decode(data.data));
                	}else{
                		deferrend.resolve({});
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.denyRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/denyRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		deferrend.resolve(JSOG.decode(data.data));
                	}else{
                		deferrend.resolve({});
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.deleteRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/deleteRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	deferrend.resolve(true);
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.revokeRightAccess = function(id) {
                var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/revokeRightAccess/'+id,
                    method: 'PUT'
                }).then(function(data) {
                	if(data && data.data){
                		deferrend.resolve(JSOG.decode(data.data));
                	}else{
                		deferrend.resolve({});
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;
            }
			factory.viewRightAccess = function(item, state) {
				var modal = angular.copy(globalModals.editAccessRights);
				modal.data = angular.copy(item);
				modal.languageColumn = Language.getActiveColumn();
				modal.seeAudit = function(){
	                window.open(state.href('annexa.admin.audit_right_access', {"objectId": item.id }), '_blank');
				}
				modal.submitFunction = function(val, prop) {
                    var ra = angular.copy(modal.data);
					ra[prop] = val;
					$http({
	                    url: './api/tram/archive/updateRightAccess/'+item.id,
	                    method: 'PUT',
						data: ra 
	                }).then(function(data) {
	                	$rootScope.$broadcast('annexaBoxDossierRightAccessReloadTable', {});
	                }).catch(function(error) {
	                    console.log(error);
						if(error && error.data && error.data.message == 'end date after start date'){
							DialogsFactory.error($filter('translate')('global.archive.accessRightDateError2'));
						}else{
  							DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
						}
	                });
                }
                AnnexaModalFactory.showModal('editAccessRightsModal', modal);                 
            }

 			factory.getRightAccessRemainingDays = function (dossierId) {
				var deferrend = $q.defer();
                var httpPromise = $http({
                    url: './api/tram/archive/rightAccessRemainingDays/'+dossierId,
                    method: 'GET'
                }).then(function(data) {
                	if(data){
                		deferrend.resolve(data.data);
                	}else{
                		deferrend.reject(0);
                	}
                }).catch(function(error) {
                    console.log(error);
                    deferrend.reject(error);
                });

                return deferrend.promise;                
            };

			factory.updateDossierCustomField = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossier/dossierCustomField',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.updateDossierCustomFieldGroup = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossier/dossierCustomFieldGroup',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.addDossierCustomFieldGroup = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossier/dossierCustomFieldGroup',
                    method: 'POST',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.deleteDossierCustomFieldGroup = function (fieldId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossier/dossierCustomFieldGroup/'+fieldId,
                    method: 'DELETE'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.updateDossierTransactionCustomFieldGroup = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossierTransaction/dossierTransactionCustomFieldGroup',
                    method: 'PUT',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.addDossierTransactionCustomFieldGroup = function (field) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossierTransaction/dossierTransactionCustomFieldGroup',
                    method: 'POST',
                    data: JSOG.encode(field)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };
            
            factory.deleteDossierTransactionCustomFieldGroup = function (fieldId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossierTransaction/dossierTransactionCustomFieldGroup/'+fieldId,
                    method: 'DELETE'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.getOperationInvoice = function(operationInvoiceId){
            	var deferred = $q.defer();
            	$http({
                    url: './api/tram/new/dossier/getOperationInvoice/'+operationInvoiceId,
                    method: 'GET'
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
            	return deferred.promise;
            }

			factory.getProjects = function(instanceCode){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/projects/'+instanceCode,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }

            factory.getBudgets = function(instanceCode, areaGes){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/budgets/'+instanceCode+((areaGes)?'/'+areaGes:''),
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            
            factory.getBudgetItems = function(accountingReference, instanceCode){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/budgetItems/'+instanceCode,
                    method: 'GET',
                    params: {accountingReference: accountingReference}
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            
            factory.createOperationInvoice = function(operationInvoice){
            	var deferred = $q.defer();
            	$http({
                    url: './api/tram/new/dossier/createOperationInvoice',
                    method: 'POST',
                    data: JSOG.encode(operationInvoice)
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data))
                }).catch(function(error) {
                    deferred.reject(error);
                })
            	return deferred.promise;
            }
            
            factory.getManagementCenters = function(instanceCode){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/managementCenters/'+instanceCode,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
            
            factory.getAccountingParameters = function(){
            	var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/accountingParameters',
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            }
			
			factory.getOperationReferences = function(dossierId){
				var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/operationReferences/'+dossierId
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
			}
			
			factory.isResponsible = function (dossier) {
                var responsible = false;
                if(dossier && $rootScope.LoggedUser){
                    if(dossier.responsibleUser && dossier.responsibleUser.id == $rootScope.LoggedUser.id){
                        responsible = true;
                    }else if(dossier.dossierResponsibleProfiles && dossier.dossierResponsibleProfiles.length > 0 && $rootScope.LoggedUser.userProfiles){
                        angular.forEach(dossier.dossierResponsibleProfiles, function(value){
                            var pr = $linq($rootScope.LoggedUser.userProfiles).singleOrDefault(undefined, "x => x.profile.id == " + value.profile.id);
                            if(pr){
                                responsible = true;
                            }
                        });
                    }
                }
                return responsible;
            };

			factory.endDossier = function (transaction) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = JSOG.encode(transaction);

                $http({
                    url: './api/tram/new/dossier/endDossier',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.cancelDossier = function (dossierId, reason) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossier/cancelDossier/'+dossierId,
                    method: 'DELETE',
                    params: {reason: reason}
                }).then(function (data) {
                    deferred.resolve(data);
                }).catch(function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            }

			factory.generateFoliate = function (dossier) {
                if (dossier && dossier.id) {
					factory.getDossierTransactionDocuments(dossier.id).then(function(dataDocuments) {
	                    var modal = angular.copy(globalModals.generateFoliate);
	                    modal.annexaFormly.model = {};
	                    modal.annexaFormly.model.modal_body = {};
	                    modal.annexaFormly.options = {};
	                    modal.annexaFormly.options.formState = {readOnly: false};
	                    modal.annexaFormly.model.modal_body.documents = {};
	                    var selectableDocuments = [];
						if(dataDocuments && dataDocuments.length > 0){
	                        dataDocuments = JSOG.decode(dataDocuments);
		                    _.forEach(dataDocuments, function (value2, key2) {
	                        	var type = $linq(GlobalDataFactory.documentRelationTypes).firstOrDefault({ id: 'DOCUMENT', language1: $filter('translate')('global.enums.documentRelationTypes.document'), language2: $filter('translate')('global.enums.documentRelationTypes.document'), language3: 'DOCUMENT' }, "x => x.id == '"+value2.relationType+"'");
	                        	selectableDocuments.push({ id: value2.id, value: value2.document.name, type:type.id, typeName:$filter('translate')(type[Language.getActiveColumn()]), order:value2.document.createdDate});
	                            modal.annexaFormly.model.modal_body.documents[value2.id] = false;
	                        });
						}
                        modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.options = $linq(selectableDocuments).orderBy("x => x.order").toArray();
	                    modal.alerts = [];
	                    var submitFunction = function () {
	                        var model = this.annexaFormly.model.modal_body;
	                        var self = this;
	                        self.alerts.length = 0;
	                        var send = {};
	                        send.dossier = JSOG.encode(angular.copy(dossier));
	                        if (model.documents) {
	                            var docs = [];
	                            angular.forEach(model.documents, function (value, key) {
	                                if (value) {
	                                    var docToPush  = $linq(selectableDocuments).where("x => x.id == "+key).toArray();
	                                    if(docToPush && docToPush.length > 0 && docToPush[0].id) {
	                                        docs.push({id:docToPush[0].id});
	                                    }
	                                }
	                            });
	                            docs = $linq(docs).orderBy("x => x.id").toArray();
	                            send.documents = JSOG.encode(docs);
	                        }
	                        send.languageColumn = Language.getActiveColumn();
	                        if(!docs || docs.length == 0){
	                            var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', {message: 'Not found documents'})};
	                            self.alerts.push({msg: errorTranslate.msg});
	                        }else {
	                            $http({
	                                url: './api/tram/new/dossier/foliate',
	                                method: 'POST',
	                                data: send,
	                                responseType: 'arraybuffer',
	                                cache: false
	                            }).then(function (data, status, headers) {
	                                var contentType = data.headers('content-type');
	                                if (!contentType) {
	                                    contentType = 'application/zip';
	                                }
	                                var name = data.headers('content-disposition');
	                                if (name) {
	                                    name = name.split(";");
	                                    if (name && name.length > 1) {
	                                        name = name[1].trim().split("=");
	                                        if (name && name.length > 1) {
	                                            name = name[1];
	                                            name = name.replace(/"/g, '');
	                                        } else {
	                                            name = "foliateExp.zip";
	                                        }
	                                    } else {
	                                        name = "foliateExp.zip";
	                                    }
	                                } else {
	                                    name = "foliateExp.zip";
	                                }
	                                var file = new Blob([data.data], {type: contentType});
	                                if (window.navigator.msSaveOrOpenBlob) {
	                                    try {
	                                        window.navigator.msSaveOrOpenBlob(file, name);
	                                    } catch (e) {
	                                        console.log(e.message);
	                                    }
	                                } else {
	
	                                    var linkElement = document.createElement('a');
	                                    try {
	                                        var url = window.URL.createObjectURL(file);
	                                        linkElement.setAttribute('href', url);
	                                        linkElement.setAttribute("download", name);
	                                        var clickEvent = new MouseEvent("click", {
	                                            "view": window,
	                                            "bubbles": true,
	                                            "cancelable": false
	                                        });
	                                        linkElement.dispatchEvent(clickEvent);
	                                    } catch (ex) {
	                                        console.log(ex);
	                                    }
	                                }
	                                self.close();
	                            }).catch(function (error) {
	                                var errorAux = ((error.data)?error.data:undefined);
									if(errorAux){
										errorAux = String.fromCharCode.apply(null, new Uint8Array(errorAux));
										if(errorAux){
											errorAux = angular.fromJson(errorAux);
										}else{
											errorAux = undefined;
										}
									}else{
										errorAux = undefined;
									}
									if(errorAux && errorAux.code && errorAux.code.toUpperCase().endsWith('Template Exception'.toUpperCase())){
										self.alerts.push({msg: 'global.tram.errors.processTemplate', msgAux:((errorAux.message)?errorAux.message : undefined), show:function(alert){if(alert){alert.showAux = ((alert.showAux)?false:true)}}, showAux:false});
									}else{
										var errorTranslate = {msg: ErrorFactory.getErrorMessage('dossier', 'foliate', error.data)};
	                                	self.alerts.push({msg: errorTranslate.msg});
									}
	                            });
	                        }
	                    };
	                    AnnexaFormlyFactory.showModal('modalFoliateTram', modal, submitFunction, false, undefined, 'global.literals.generate_foliate');
					}).catch(function() {
                    })
                } else {
                    //ERROR no hi ha dossier
                }
            };

			factory.createTramResposibleModal = function (dossier, transaction, omission, reparation, free, proposalsDossierTransaction, inspectionProposalsDossierTransaction, procedureProposalTypeInspectionInterventionActive) {
        	   var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var modal = angular.copy(globalModals.createDossierTransactionPopup);
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
                var fields = [];
				fields.push({
                    key: 'completeDossierTransaction',
                    type: 'annexaComponent',
                    templateOptions: {
                        type: 'annexa-complete-unguided-dossier-transaction'
                    },
					data: {
                        dossier: dossier,
                        transaction: transaction,	
                        omission: omission,
                        reparation: reparation,
						free: free, 
						omissionOrReparation: ((omission || reparation)?true:false),
						loadFunction: factory.searchProfiles,
						saveFunction: function(){},
						inputType:  'Profile',
					    buttonLabel:  'global.literals.select',
					    placeholder: 'global.reg.literals.profileOrUserName',
					    showTitle: false,
					    showOr: false,
						type: 'text',
						proposalsDossierTransaction: proposalsDossierTransaction,
						inspectionProposalsDossierTransaction: inspectionProposalsDossierTransaction,
						procedureProposalTypeInspectionInterventionActive: procedureProposalTypeInspectionInterventionActive
                    }
            	});
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("createTramResponsible", modal, factory.createTramResposible, false, false, 'global.literals.create').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            };

			factory.createTramResposible = function(){
				var deferred = $q.defer();
                var self = this;
				if(self && self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup && self.annexaFormly.fields[0].fieldGroup.length > 0){
					var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
					if(completeDossierTransactionField && completeDossierTransactionField.data && completeDossierTransactionField.data.transactionNoGuiadaList && completeDossierTransactionField.data.transactionNoGuiadaList.length > 0){
						 var send = {};
                        send.dossierTransaction = JSOG.encode(completeDossierTransactionField.data.transaction);
                    	var tngl = angular.copy(completeDossierTransactionField.data.transactionNoGuiadaList);
                        _.forEach( tngl, function(value){
                        	if(value.selectedResponsible && value.selectedResponsible.id){
                                value.selectedResponsible = {id:value.selectedResponsible.id};
                            }
                            if(value.expirationType) {
                                value.expirationType = value.expirationType.id;
                            }
                        });
                        send.transactionNoGuiadaList = JSOG.encode(tngl);
                        send.omissionOrReparation = ((completeDossierTransactionField.data.omissionOrReparation)?true:false);
                        $http({
                            url: './api/tram/new/dossierTransaction/create',
                            method: 'POST',
                            data: send
                        }).then(function (data) {
                            deferred.resolve(data.data);
                            self.close();
                            if (data.data.length > 0 && data.data[0].id && $state.params.dossierTransaction != data.data[0].id) {
	                            $state.transitionTo('annexa.tram.pending.viewdossier', {
	                                "dossier": completeDossierTransactionField.data.dossier.id,
	                                "dossierTransaction": data.data[0].id,
	                                "selectedTab": "center"
	                            });
	                        } else{
	                        	$state.transitionTo('annexa.tram.pending.viewdossier', {
		                        	"dossier": completeDossierTransactionField.data.dossier.id,
	                                "dossierTransaction": completeDossierTransactionField.data.transaction.id,
	                                "selectedTab": "center"
	                        	});
	                        }
                        }).catch(function (error) {
                            deferred.reject(error);
                            if(error && error.data && error.data.message && error.data.message.includes("error-requestCompleteDossier-invalid-preSpel")) {
                        		var message = ErrorFactory.getErrorMessage('dossier', 'complete', error.data);
	                            if(completeDossierTransactionField.data.dossier && completeDossierTransactionField.data.dossier.procedure 
	                                    && completeDossierTransactionField.data.dossier.procedure.createTransactionTypes) {
                                    var splitMessage = error.data.message.split("+");
                                    message = ErrorFactory.getErrorMessage('dossier', 'complete', splitMessage[0]);
                                    if(splitMessage && splitMessage.length > 1) {
                                        var ctt = $linq(completeDossierTransactionField.data.dossier.procedure.createTransactionTypes).firstOrDefault(undefined, "x => x.transactionType.id == " + splitMessage[1]);
                                        if(ctt && ctt.preSpel) {
                                        	if(ctt.descriptionNoPreSpel){
                                        		message = $filter('translate')(message) + " (" + ctt.transactionType[Language.getActiveColumn()] + "). '" + ctt.descriptionNoPreSpel + "'";
                                        	}else{
                                        		message = $filter('translate')(message) + " (" + ctt.transactionType[Language.getActiveColumn()] + "). '" + ctt.preSpel + "'";
                                        	}
                                            
                                        }
                                    }
	                            }
	                            self.alerts.push({msg: message});
	                        } else{
	                        	self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
	                        }
                        })
					}else{
						self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    	deferred.reject('global.tram.errors.noTransactions');
					}
				}else{
					self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
				}
                return deferred.promise;
			}
			
			factory.completeDossierTransaction = function (transaction, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();
                 var send = {};
                send.transaction = JSOG.encode(transaction);
                if (profile && profile.id) {
                    send.profile = JSOG.encode(profile);
                }

                if (transactionNoGuiadaList && transactionNoGuiadaList.length > 0) {
                	var tngl = angular.copy(transactionNoGuiadaList);
                    _.forEach(tngl, function(value){
                        if(value.selectedResponsible && value.selectedResponsible.id){
                            value.selectedResponsible = {id:value.selectedResponsible.id};
                        }
                        if(value.expirationType) {
                        	value.expirationType = value.expirationType.id;
                        }
                    });
                    send.transactionNoGuiadaList = JSOG.encode(tngl);
                }
                if (completeTransaction) {
                    send.completeTransaction = completeTransaction;
                }
                send.pendingUser = puser;
                $http({
                    url: './api/tram/new/dossierTransaction/complete',
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
            };

			factory.reopenDossierTransaction = function (idTransaction) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/reopen/'+idTransaction,
                    method: 'POST'
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.searchProfiles = function (value, only) {
                if (!value || !value.val) {
                    return [];
                } else if (value.val != '*' && value.val.length < 3) {
                    return [];
                } else {
                    if (value.val == '*') {
                        value.val = '';
                    }
                    return RestService.filterData('common', 'Profile', {}, { search: value.val, langColumn:Language.getActiveColumn() }, 0, 10, '', 3)
                        .then(function(data) {
                            var items = [];

                            if(data.data && data.data.content) {
                                _.forEach(JSOG.decode(data.data.content), function(item) {
                                	if(only && only.length > 0) {
                                        if($linq(only).contains(item.id)) {
                                        	items.push({ id: item.id, value: item[Language.getActiveColumn()], profile: item});
                                        }
                                	} else {
                                        items.push({ id: item.id, value: item[Language.getActiveColumn()], profile: item});
                                	}
                                })
                            }

                            return items;
                        }).catch(function () {
                            return [];
                        });
                }
            };

			factory.getCompleteData = function(dossierTransactionId){
            	var deferrend = $q.defer();
	            $http({
	                method: 'GET',
	                url: './api/tram/new/dossierTransaction/getCompleteData/' + dossierTransactionId
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
            }

			factory.deleteDossierTransaction = function (idTransaction, transactionLists, reason, dossierTransactionToReopen) {
                var deferred = $q.defer();
                var send = {};
                send.idDossierTransaction = idTransaction;
                send.reason = reason;
                send.dossierTransactionToReopen = dossierTransactionToReopen;
                if (transactionLists && transactionLists.length > 0) {
                	var tlist = angular.copy(transactionLists);
                    _.forEach( tlist, function(value){
                    	if(value.selectedResponsible && value.selectedResponsible.id){
                            value.selectedResponsible = {id:value.selectedResponsible.id};
                        }
                        if(value.expirationType) {
                            value.expirationType = value.expirationType.id;
                        }
                        if(value.expirationType) {
                        	value.expirationType = value.expirationType.id;
                        }
                    });
                    send.transactionNoGuiadaList = JSOG.encode(tlist);
                }
                $http({
                    url: './api/tram/new/dossierTransaction/deleteDossierTransaction/'+idTransaction,
                    method: 'POST',
                    data: send
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.validateSendProposalTransaction = function(dossierTransactionId, proposalId){
				var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/validateSendProposalTransaction/'+dossierTransactionId+'/'+proposalId,
                    method: 'GET',
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })
				return deferred.promise;
			}

			factory.sendProposalDossierTransaction = function (modal, transaction, transactionNoGuiadaList, proposal) {
                var deferred = $q.defer();
                if (transactionNoGuiadaList && transactionNoGuiadaList.length > 0) {
                    var send = {};
                    send.dossierTransaction = {id:transaction.id};
                    var tngl = angular.copy(transactionNoGuiadaList);
                	_.forEach(tngl, function(value){
                    	if(value.selectedResponsible && value.selectedResponsible.id){
                            value.selectedResponsible = {id:value.selectedResponsible.id};
                        }
                        if(value.expirationType) {
                            value.expirationType = value.expirationType.id;
                        }
                        if(value.expirationType) {
                        	value.expirationType = value.expirationType.id;
                        }
                    });
                	if(modal && modal.annexaFormly && modal.annexaFormly.extra) {
                		send.isPublish = modal.annexaFormly.extra.isPublish ? modal.annexaFormly.extra.isPublish : false;
                		if(modal.annexaFormly.extra.isPublish && modal.annexaFormly.extra.publishSite) {
                			send.publishSite = [];
                			_.forEach(modal.annexaFormly.extra.publishSite, function(pSite){
                				send.publishSite.push({id:pSite});
                			});
                		}
                		if(modal.annexaFormly.extra.thirdsSelected && modal.annexaFormly.extra.thirdsSelected.length > 0) {
                			send.proposalNotificationThirdConfiguration = [];
                			_.forEach(modal.annexaFormly.extra.thirdsSelected, function(thi){
                				var sendThird = {
                						dossierThird:{id:thi.id},
                						proposalNotificationThirdDocumentConfiguration:[],
                						thirdAddress: undefined,
                						notificationType: undefined
                					};
                				var addressSelected = $linq(thi.addressesOrig).firstOrDefault(undefined, "x => x.selected");
                				if(addressSelected.id) {
                					sendThird.thirdAddress = {id:addressSelected.id};
                				}
                				if(addressSelected.selectedNotificationType && addressSelected.selectedNotificationType.id) {
                					sendThird.notificationType = {id:addressSelected.selectedNotificationType.id};
                				}
                				var documentsSelected = $linq(thi.documentsToSelect).where("x => x.selected").toArray();
                				if(documentsSelected && documentsSelected.length > 0) {
	                				_.forEach(documentsSelected, function(dSel){
	                					sendThird.proposalNotificationThirdDocumentConfiguration.push({document:{id:dSel.id}});
	                				});
	              				}
                				send.proposalNotificationThirdConfiguration.push(sendThird);
                			});
                		}
                	}
                    send.transactionNoGuiadaList = JSOG.encode(tngl);
                    send.proposal = {id:proposal.id};
					send.workWithDossierSons = ((modal && modal.annexaFormly && modal.annexaFormly.extra && modal.annexaFormly.extra.sendSecType && modal.annexaFormly.extra.sendSecType === 'SON')?true:false);
                    $http({
                        url: './api/tram/new/dossierTransaction/sendProposalDossierTransaction',
                        method: 'POST',
                        data: send
                    }).then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (error) {
                        deferred.reject(error);
                    })
                } else {
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

			factory.sendInspectionProposalDossierTransaction = function (modal, transaction, transactionNoGuiadaList, inspectionProposal) {
                var deferred = $q.defer();
                if (transactionNoGuiadaList && transactionNoGuiadaList.length > 0) {
                    var send = {};
                    send.dossierTransaction = {id:transaction.id};
                    var tngl = angular.copy(transactionNoGuiadaList);
                	_.forEach(tngl, function(value){
                    	if(value.selectedResponsible && value.selectedResponsible.id){
                            value.selectedResponsible = {id:value.selectedResponsible.id};
                        }
                        if(value.expirationType) {
                            value.expirationType = value.expirationType.id;
                        }
                    });
                    send.transactionNoGuiadaList = JSOG.encode(tngl);
                    send.inspectionProposal = {id: inspectionProposal.id};
                    $http({
                        url: './api/tram/new/dossierTransaction/sendInspectionProposalDossierTransaction',
                        method: 'POST',
                        data: send
                    }).then(function (data) {
                        deferred.resolve(data.data);
                    }).catch(function (error) {
                        deferred.reject(error);
                    })
                } else {
                    deferred.reject('global.tram.errors.noTransactions');
                }
                return deferred.promise;
            };

			factory.getNextDossierTransactions = function (transactionId) {
                var deferred = $q.defer();

                $http({
                    url: './api/tram/new/dossierTransaction/getNextDossierTransactions/'+transactionId,
                    method: 'GET'
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.isFinalTransaction = function (transaction) {
                if (ABMShapesFactory.isTransactionFinal(transaction) == true) {
                	DialogsFactory.confirm('global.literals.confirmEndDossierTitle', 'global.literals.confirmEndDossierBody').then(function (btn) {
						factory.endDossier(transaction).then(function (data) {
	                        $state.transitionTo('annexa.tram.pending');
	                    }).catch(function (error) {
							if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
								var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
								DialogsFactory.error(msg);
							}else if (error && error.data && error.data.message == 'No Required Custom Field Dossier Value data received in creation'){
								 DialogsFactory.error($filter('translate')('global.tram.errors.dossierCustomFields'));
							}else if (error && error.data && error.data.message == 'No Required Custom Field Value data received in creation'){
								DialogsFactory.error($filter('translate')('global.tram.errors.dossierTransactionCustomFields'));
							}else if(error && error.data && error.data.message && error.data.message == 'Is not valid document from template not found'){
								DialogsFactory.error($filter('translate')('global.documents.definitiveNotificationNoValidError'));
							}else{
			                    DialogsFactory.error($filter('translate')(ErrorFactory.getErrorMessage('dossier', 'complete', error.data)));
							}
                        });
                    }).catch(function (error) {
                    });
                } else {
					DialogsFactory.error($filter('translate')('global.literals.errorCompletNotFinalTransaction'));
                }
            };

			factory.completeDossierTransactionGuiat = function (transaction, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.transaction = transaction;
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/new/dossierTransaction/completeDossierTransactionGuiat',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.sendGuidedProposalDossierTransaction = function (transaction, proposal, nextDossierTransactionInfo, workWithDossierSons, isPublish, publishSite, proposalNotificationThirdConfiguration) {
                var deferred = $q.defer();
                var send = {};
                send.dossierTransaction = transaction;
                send.proposal = {id:proposal.id};
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;
				send.workWithDossierSons = workWithDossierSons;
				send.isPublish = isPublish;
				send.publishSite = publishSite;
				send.proposalNotificationThirdConfiguration = proposalNotificationThirdConfiguration;
                $http({
                    url: './api/tram/new/dossierTransaction/sendGuidedProposalDossierTransaction',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.sendGuidedInspectionProposalDossierTransaction = function (transaction, inspectionProposal, nextDossierTransactionInfo) {
                var deferred = $q.defer();
                var send = {};
                send.dossierTransaction = transaction;
                send.inspectionProposal = {id:inspectionProposal.id};
                send.nextDossierTransactionInfo = nextDossierTransactionInfo;

                $http({
                    url: './api/tram/new/dossierTransaction/sendGuidedInspectionProposalDossierTransaction',
                    method: 'POST',
                    data: JSOG.encode(send)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.addDocs = function (transaction, docs) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/addDocuments/'+transaction,
                    method: 'POST',
					data:JSOG.encode(docs)
                }).then(function (data) {
                    deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            };

			factory.getDossierTransactionByDossierId = function(ids) {
                var httpPromise = $http({
                    url: './api/tram/new/dossierTransaction/dossiersId/'+CommonService.getParameterList(ids),
                    method: 'GET'
                }).then(function(data) {
                	if(data && data.data){
                		return JSOG.decode(data.data);
                	}else{
                		return [];
                	}
                }).catch(function(error) {
                    console.log(error);
                    return error;
                });

                return httpPromise;
            }

			factory.getDossierByNumber = function (numberDossier) {
				factory.byNumberCanUpdate = undefined;
				factory.byNumberId = undefined;
				factory.byNumberDtId = undefined;
				factory.byNumberRightAccess = undefined;
				return $http({url: './api/tram/new/dossier/byNumber/' + numberDossier, method: 'GET'}).then(function(data) {
					if(data && data.data){
						var result = JSOG.decode(data.data);
		        		factory.byNumberCanUpdate = ((result.canUpdate)?true:false);
						factory.byNumberId = result.dossierId;
						factory.byNumberDtId = result.dossierTransactionId;
						factory.byNumberRightAccess = ((result.seeWithRightAccess)?true:false);
						if(!factory.byNumberId){
		                	$state.go('annexa.dashboard');
		                }
					}else{
		                if(!factory.byNumberId){
		                	$state.go('annexa.dashboard');
		                }
					}
	            }).catch(function (error) {
	           	   	factory.byNumberCanUpdate = undefined;
					factory.byNumberId = undefined;
					factory.byNumberDtId = undefined;
					factory.byNumberRightAccess = undefined;
					$state.go('annexa.dashboard');
					console.error(error);
	            });
	        }

			factory.modifyFavorite = function (idDossierTransaction, idUser) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/modifyFavorite',
                    method: 'POST',
                    params: {idDossierTransaction: idDossierTransaction, idUser: idUser}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }
			
			factory.editTransactionType = function (idDossierTransaction, idTransactionType) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/editTransactionType',
                    method: 'POST',
                    params: {idDossierTransaction: idDossierTransaction, idTransactionType: idTransactionType}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }

			factory.getUserAccountInstance = function(id){
				var promises = [];
                promises.push($http({url: './api/tram/new/dossier/userAccountInstances', method: 'GET'}));
				promises.push($http({url: './api/tram/new/dossier/operationReferences/proposal', method: 'GET', params: {idProposal: id}}));
                return $q.all(promises).then(function(data) {var dos = JSOG.decode(data[0].data);
                   	factory.userAccountInstances = JSOG.decode(data[0].data);
                    factory.accountingReferences = JSOG.decode(data[1].data);
                }).catch(function() {
                    factory.userAccountInstances = [];
                    factory.accountingReferences = [];
                })
			}   
			
			factory.getAccountingReferences = function(id){
				var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossier/operationReferences/proposal',
                    method: 'GET',
                    params: {idProposal: id}
                }).then(function (data) {
                    deferred.resolve(JSOG.decode(data.data));
                }).catch(function (error) {
                    deferred.reject(error);
                })
                return deferred.promise;
			}   

			factory.reopenTramModal = function (dossier, transaction, procedureProposalTypeInspectionInterventionActive) {
                var data = {
                    row: true,
                    colClass: ' col-sm-12',
                    labelClass: ''
                };
                var modal = angular.copy(globalModals.createDossierTransactionPopup);
				modal.title = $filter('translate')('global.literals.reopenTram');
                modal.alerts = [];
                modal.annexaFormly = new AnnexaFormly();
                var fields = [];
				fields.push({
                    key: 'completeDossierTransaction',
                    type: 'annexaComponent',
                    templateOptions: {
                        type: 'annexa-complete-unguided-dossier-transaction'
                    },
					data: {
                        dossier: dossier,
                        transaction: transaction,	
                        omission: false,
                        reparation: false,
						free: false, 
						omissionOrReparation: false,
						loadFunction: factory.searchProfiles,
						saveFunction: function(){},
						inputType:  'Profile',
					    buttonLabel:  'global.literals.select',
					    placeholder: 'global.reg.literals.profileOrUserName',
					    showTitle: false,
					    showOr: false,
						type: 'text',
						procedureProposalTypeInspectionInterventionActive: procedureProposalTypeInspectionInterventionActive
                    }
            	});
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("reopenTram", modal, factory.reopenTram, false, false, 'global.literals.create').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            };

            factory.reopenTram = function (component, profile, puser, transactionNoGuiadaList, completeTransaction) {
                var deferred = $q.defer();
                var self = this;
				if(self && self.annexaFormly && self.annexaFormly.fields && self.annexaFormly.fields.length > 0 && self.annexaFormly.fields[0].fieldGroup && self.annexaFormly.fields[0].fieldGroup.length > 0){
					var completeDossierTransactionField = $linq(self.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined,"x => x.key == 'completeDossierTransaction'");
					if(completeDossierTransactionField && completeDossierTransactionField.data && completeDossierTransactionField.data.transactionNoGuiadaList && completeDossierTransactionField.data.transactionNoGuiadaList.length > 0){
						var send = {};
                        send.dossier = {id:completeDossierTransactionField.data.dossier.id};
                    	var tngl = angular.copy(completeDossierTransactionField.data.transactionNoGuiadaList);
                        _.forEach( tngl, function(value){
                        	if(value.selectedResponsible && value.selectedResponsible.id){
                                value.selectedResponsible = {id:value.selectedResponsible.id};
                            }
                            if(value.expirationType) {
                                value.expirationType = value.expirationType.id;
                            }
                        });
                        send.transactionNoGuiadaList = JSOG.encode(tngl);
                        send.omissionOrReparation = ((completeDossierTransactionField.data.omissionOrReparation)?true:false);
                        $http({
                            url: './api/tram/new/dossier/reopen/'+completeDossierTransactionField.data.dossier.id,
                            method: 'POST',
                            data: send
                        }).then(function (data) {
                            deferred.resolve(data.data);
                            self.close();
                            $state.reload();
                        }).catch(function (error) {
                            deferred.reject(error);
                            self.alerts.push({msg: ErrorFactory.getErrorMessage('dossier', 'complete', error.data)});
                        })
					}else{
						self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    	deferred.reject('global.tram.errors.noTransactions');
					}
				}else{
					self.alerts.push({msg: 'global.tram.errors.noTransactions'});
                    deferred.reject('global.tram.errors.noTransactions');
				}
                return deferred.promise;
            };

			factory.calculateDossierCounters = function(id, counter){
				var deferrend = $q.defer();
	            $http({
	                method: 'GET',
	                url: './api/tram/new/dossier/counters/' + id + '/'+((counter)?counter:'all')
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
			}
			factory.updateMetadata = function(metadata){
	            var deferrend = $q.defer();
	            $http({
	                method: 'PUT',
	                url: './api/tramEMGDE/' + metadata.id + '/emgdeDossierMetadata',
					data: JSOG.encode(metadata)
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
	        };
	
			factory.updateMetadataObj = function(metadataObj, metadataId, metadataType){
	            var deferrend = $q.defer();
	            $http({
	                method: 'POST',
	                url: './api/tramEMGDE/' + metadataId + '/'+metadataType,
					data: JSOG.encode(metadataObj)
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
	        };
	
			factory.deleteMetadataObj = function(metadataObjId, metadataId, metadataType){
	            var deferrend = $q.defer();
	            $http({
	                method: 'DELETE',
	                url: './api/tramEMGDE/' + metadataId + '/'+metadataType+'/'+metadataObjId
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
	        };

			factory.updateThirdEMGDE = function(dossierId){
	            $http({
	                method: 'GET',
	                url: './api/tramEMGDE/' + dossierId+'/emgdeDossierMetadataInteresteds'
	            }).success(function(data, status) {
					if(factory.emgdeDossierMetadata){
						if(factory.emgdeDossierMetadata.interesteds){
							factory.emgdeDossierMetadata.interesteds.length = 0;
						}else{
							factory.emgdeDossierMetadata.interesteds = [];
						}
						if(data && data.length > 0){
							var dataAux = JSOG.decode(data);
							_.forEach(dataAux, function(interested){
								factory.emgdeDossierMetadata.interesteds.push(interested);	
							});
						}
					}
	            }).error(function(msg,code) {
					var a = 0;
	            });
	        };

			factory.getTramTemplates = function (dossierId, procedureId) {
            	factory.startedTramTemplateResponse = true;
            	factory.endedTramTemplateResponse = false;
                return $http({
                    url: './api/tram/template/procedure',
                    method: 'GET',
                    params: {dossierId: dossierId, procedureId: (procedureId?procedureId:-1)}
                }).then(function (data) {
                	if(data && data.data && data.data.length > 0){
                		var tempProd = JSOG.decode(data.data);
                		factory.TramTemplates = $linq(GlobalDataFactory.publictramtemplates).union(tempProd,"(x,y) => x.id == y.id").toArray();;
                	}else{
                		factory.TramTemplates = angular.copy(GlobalDataFactory.publictramtemplates);
                	}
                	factory.endedTramTemplateResponse = true;
                }).catch(function (error) {
                	factory.endedTramTemplateResponse = true;
                });
            }
			
			factory.getProcedureCreateTransactionTypeTemplates = function (procedureId, dossierTransactionId, transactionTypeId) {
                var deferred = $q.defer();
                $http({
                    url: './api/procedure/procedureCreateTransactionType/templates/'+procedureId+'/'+dossierTransactionId+'/'+transactionTypeId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.getProcedureCreateTransactionTypeTemplatesByDossierTransactionId = function (dossierTransactionId) {
                var deferred = $q.defer();
                $http({
                    url: './api/procedure/procedureCreateTransactionType/templates/dossierTransaction/'+dossierTransactionId,
                    method: 'GET'
                }).then(function (data) {
                	deferred.resolve(data.data);
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            };

			factory.updateDocumentationToProvideUpate = function (item, dossierId) {
                return $http({
                    url: './api/tram/new/dossier/documentationToProvide/changeStatus/'+dossierId,
                    method: 'POST',
                    data: JSOG.encode(item)
                });
            }

			factory.getDocumentationToProvideThirds = function (dossierId, thirdIds) {
				var deferrend = $q.defer();
	            $http({
	                method: 'GET',
	                url: './api/tram/new/dossier/documentationToProvide/thirds/'+dossierId+'/'+CommonService.getParameterList(thirdIds)
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
            }

			factory.getSubprocedures = function(){
				var deferrend = $q.defer();
	            $http({
	                method: 'GET',
	                url: './api/procedures/subprocedures'
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
			}
			
			factory.getSubproceduresByProcedure = function(procedureId){
				var deferrend = $q.defer();
	            $http({
	                method: 'GET',
	                url: './api/procedures/subprocedures/' + procedureId
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
			}


			factory.openSubprocedureModal = function (dossier, transaction) {
				var openSubprocedure = function(){
	                var self = this;
					if(self && self.annexaFormly && self.annexaFormly.model && self.annexaFormly.model.modal_body && self.annexaFormly.model.modal_body.subprocedure && self.annexaFormly.model.modal_body.subprocedure.id){
						var dti = {
							profiles: [],
							description:undefined,
							responsibleUser: undefined
						};
						var transSelected = self.annexaFormly.fields[0].fieldGroup[1].data.transaction;
						if(transSelected){
							if(transSelected.profile && transSelected.profile.id ){
								dti.profiles.push({id: transSelected.profile.id});	
							}
							if(transSelected.responsibleUser && transSelected.responsibleUser){
								dti.responsibleUser = transSelected.responsibleUser;	
							}
							dti.description = transSelected.description;	
						}
	                    $http({
	                        url: './api/tram/new/dossier/openSubprocedure/'+dossier.id+'/'+self.annexaFormly.model.modal_body.subprocedure.id,
	                        method: 'POST',
							data: JSOG.encode(dti)
	                    }).then(function (data) {
	                        self.close();
	                        $state.reload();
	                    }).catch(function (error) {
	                    	self.alerts.length = 0;
							if(error && error.data && error.data.message){
								if(error.data.message === 'Dossier or subprocedure not exist'){
								    self.alerts.push({msg: 'global.literals.dossierOrSubprocedureNotExist'});
								}else if(error.data.message === 'Subprocedure type is not correct'){	
								    self.alerts.push({msg: 'global.literals.incorrectSubprocedureType'});
								}else if(error.data.message === 'Dossier is guided'){	
								    self.alerts.push({msg: 'global.literals.dossierIsGuided'});
								}else if(error.data.message === 'User not logged'){	
								    self.alerts.push({msg: 'global.literals.userNotLogged'});
								}else if(error.data.message === 'User without permissions'){	
								    self.alerts.push({msg: 'global.literals.notPermission'});
								}else if(error.data.message.includes("No open transaction type")){
									var splitMessage = error.data.message.split("+");
								    self.alerts.push({msg: $filter('translate')('global.literals.noOpenTransactionType') + " " + splitMessage[1]});
								}else{
								    self.alerts.push({msg: 'global.errors.unknownUpdate'});
								}
							}else{
							    self.alerts.push({msg: 'global.errors.unknownUpdate'});
							}
	                    })
					}else{
						self.alerts.length = 0;
						self.alerts.push({msg: 'global.tram.errors.noSubprocedure'});
					}
				}
				factory.getSubproceduresByProcedure(dossier.procedure.id).then(function (dataSubprocedures) {
					var data = {
	                    row: true,
	                    colClass: ' col-sm-12',
	                    labelClass: ''
	                };
	                var modal = angular.copy(globalModals.openSubprocedureModal);
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.modal_body = {};
	                modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false};
					modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = ((dataSubprocedures)?$linq(dataSubprocedures).select("x => x.procedure").toArray():[]);
					modal.annexaFormly.fields[0].fieldGroup[1].data = {subprocedures:dataSubprocedures};
					modal.alerts = [];
	                AnnexaFormlyFactory.showModalPromise("openSubprocedure", modal, openSubprocedure, false, false, 'global.literals.openSubprocedure').then(function (modalAux) {
	                    modal.modal = modalAux;
	                    modal.close = modalAux.element.scope().close;
	                    modalAux.element.modal();
	                    angular.element(".modal-title").focus();
	                }).catch(function () {
	                    var a = 0;
	                });				
				}).catch(function (error) {
                });
            };

            factory.configureProposalSignCircuit = function(model, type, id, addTram, notEditable){
				var saveIfNew = function(){
					var modalExpAux = this;
					if(modalExpAux && modalExpAux.annexaFormly){
						var conditionedSignCircuitListField = $linq(modalExpAux.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
			            if(conditionedSignCircuitListField && conditionedSignCircuitListField.data && 
			            		conditionedSignCircuitListField.data.conditionedSignCircuitList && conditionedSignCircuitListField.data.conditionedSignCircuitList.length > 0) {
							model.proposalSignCircuitConfiguration = angular.copy(conditionedSignCircuitListField.data.conditionedSignCircuitList);
			            } else {
			            	model.proposalSignCircuitConfiguration = [];
			            }
					}
					modalExpAux.close();
				}
				var saveIfNotNew = function(){
					var modalExpAux = this;
					if(modalExpAux && modalExpAux.annexaFormly){
						var proposalSignCircuitConfiguration = [];
						var conditionedSignCircuitListField = $linq(modalExpAux.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
			            if(conditionedSignCircuitListField && conditionedSignCircuitListField.data && 
			            		conditionedSignCircuitListField.data.conditionedSignCircuitList && conditionedSignCircuitListField.data.conditionedSignCircuitList.length > 0) {
							proposalSignCircuitConfiguration = angular.copy(conditionedSignCircuitListField.data.conditionedSignCircuitList);
			            }
	            		RestService.update('./api/tram/proposal_sign_circuit/updateListProcedure/' + id, proposalSignCircuitConfiguration).then(function (data) {
	            			modalExpAux.close();
                        }).catch(function (error) {
                    		modalExpAux.alerts.push({ msg: "Error"});
                        });
					}
				}
				var openPropSignCircuitPopup = function(modelToOpen, callOnSaveFunction){
					var modalExp = angular.copy(globalModals.proposalSignCircuitModal);
					modalExp.annexaFormly.options = {};
		            var conditionedSignCircuitListField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'conditionedSignCircuitList'");
		            if(conditionedSignCircuitListField) {
		            	conditionedSignCircuitListField.data.conditionedSignCircuitList = [];
		            	conditionedSignCircuitListField.data.removeAll = true;
		            }
					if(modelToOpen){
						conditionedSignCircuitListField.data.conditionedSignCircuitList = modelToOpen;
					}
					modalExp.annexaFormly.model = {modal_body:((modelToOpen)?modelToOpen:{})};
					if(notEditable){
						var closeFunction = function(){
							var modalExpAux = this;
							modalExpAux.close();
						}
						AnnexaFormlyFactory.showModal('modalProposalSignCircuit', modalExp, closeFunction, false);
					}else{
						AnnexaFormlyFactory.showModal('modalProposalSignCircuit', modalExp, callOnSaveFunction, false);
					}
				}
				//si model != null is new
				if(model){
					openPropSignCircuitPopup(angular.copy(model.proposalSignCircuitConfiguration), saveIfNew);
				}else if(type && id){
					$http({method: 'GET',url: './api/tram/proposal_sign_circuit/byProcedure/'+id}).success(function(data, status) {
						var dataAux = ((data)?JSOG.decode(data):{});
						openPropSignCircuitPopup(dataAux, saveIfNotNew);
		            }).error(function(msg,code) {
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
			        });
				}else{
					DialogsFactory.error($filter('translate')('global.sec.literals.errormodel'));
				}
			}
            
			factory.configureSecNotifications = function(model, type, id, addTram, notEditable){
				var saveIfNew = function(){
					var modalExpAux = this;
					if(modalExpAux && modalExpAux.annexaFormly && modalExpAux.annexaFormly.model && modalExpAux.annexaFormly.model.modal_body){
						model.secretaryNotificationConfiguration = angular.copy(modalExpAux.annexaFormly.model.modal_body);
						if(model.secretaryNotificationConfiguration.type && model.secretaryNotificationConfiguration.type.id === ''){
							model.secretaryNotificationConfiguration = {};
						}else{
							if(model.secretaryNotificationConfiguration.urgent && model.secretaryNotificationConfiguration.urgent.true){
								model.secretaryNotificationConfiguration.urgent = true;
							}else{
								model.secretaryNotificationConfiguration.urgent = false;
							}
							if(model.secretaryNotificationConfiguration.type){
								model.secretaryNotificationConfiguration.type = model.secretaryNotificationConfiguration.type.id;	
							}
							if(model.secretaryNotificationConfiguration.level){
								model.secretaryNotificationConfiguration.level = model.secretaryNotificationConfiguration.level.id;	
							}
							model.secretaryNotificationConfiguration.archiveClassification = ((model.secretaryNotificationConfiguration.archiveClassification && model.secretaryNotificationConfiguration.archiveClassification.$selected)?model.secretaryNotificationConfiguration.archiveClassification.$selected:undefined);
							var docsField = $linq(modalExpAux.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documents'");
							if(docsField && docsField.data.docs){
								model.secretaryNotificationConfiguration.docs = docsField.data.docs;
							}else{
								model.secretaryNotificationConfiguration.docs = [];
							}
							var transField = $linq(modalExpAux.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'transactions'");
							if(transField && transField.data.transactions){
								model.secretaryNotificationConfiguration.transactions = transField.data.transactions;
							}else{
								model.secretaryNotificationConfiguration.transactions = [];
							}
						}
					}
					modalExpAux.close();
				}
				var saveIfNotNew = function(){
					var modalExpAux = this;
					if(modalExpAux && modalExpAux.annexaFormly && modalExpAux.annexaFormly.model && modalExpAux.annexaFormly.model.modal_body){
						var secretaryNotificationConfiguration = angular.copy(modalExpAux.annexaFormly.model.modal_body);
						if(secretaryNotificationConfiguration.urgent && secretaryNotificationConfiguration.urgent.true){
							secretaryNotificationConfiguration.urgent = true;
						}else{
							secretaryNotificationConfiguration.urgent = false;
						}
						if(secretaryNotificationConfiguration.type){
							secretaryNotificationConfiguration.type = secretaryNotificationConfiguration.type.id;
							if(secretaryNotificationConfiguration.type === ''){
								secretaryNotificationConfiguration.type = undefined;
							}
						}
						if(secretaryNotificationConfiguration.level){
							secretaryNotificationConfiguration.level = secretaryNotificationConfiguration.level.id;
						}
						secretaryNotificationConfiguration.archiveClassification = ((secretaryNotificationConfiguration.archiveClassification && secretaryNotificationConfiguration.archiveClassification.$selected)?secretaryNotificationConfiguration.archiveClassification.$selected:undefined);
						var docsField = $linq(modalExpAux.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documents'");
						if(docsField && docsField.data && docsField.data.docs){
							secretaryNotificationConfiguration.docs = docsField.data.docs;
						}else{
							secretaryNotificationConfiguration.docs = [];
						}
						var transField = $linq(modalExpAux.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'transactions'");
						if(transField && transField.data.transactions){
							secretaryNotificationConfiguration.transactions = transField.data.transactions;
						}else{
							secretaryNotificationConfiguration.transactions = [];
						}
						if(secretaryNotificationConfiguration.registerOffice && secretaryNotificationConfiguration.registerOffice.id){
							secretaryNotificationConfiguration.registerOffice = {id:secretaryNotificationConfiguration.registerOffice.id};
						}
						if(secretaryNotificationConfiguration.profile && secretaryNotificationConfiguration.profile.id){
							secretaryNotificationConfiguration.profile = {id:secretaryNotificationConfiguration.profile.id};
						}
						if(secretaryNotificationConfiguration.archiveClassification && secretaryNotificationConfiguration.archiveClassification.id){
							secretaryNotificationConfiguration.archiveClassification = {id:secretaryNotificationConfiguration.archiveClassification.id};
						}
						if(secretaryNotificationConfiguration.docs && secretaryNotificationConfiguration.docs.length > 0){
							_.forEach(secretaryNotificationConfiguration.docs, function(doc){
								if(doc.type && doc.type.id){
									doc.type = {id:doc.type.id};
								}
								if(doc.template && doc.template.id){
									doc.template = {id:doc.template.id};
								}
								if(doc.archiveClassification && doc.archiveClassification.id){
									doc.archiveClassification = {id:doc.archiveClassification.id};
								}
							});
						}
						if(secretaryNotificationConfiguration.transactions && secretaryNotificationConfiguration.transactions.length > 0){
							_.forEach(secretaryNotificationConfiguration.transactions, function(trans){
								if(trans.type && trans.type.id){
									trans.type = {id:trans.type.id};
								}
								if(trans.profile && trans.profile.id){
									trans.profile = {id:trans.profile.id};
								}
							});
						}
						if(!secretaryNotificationConfiguration.type){
							DialogsFactory.confirm('global.sec.literals.deleteSecNotificationTitle', 'global.sec.literals.deleteSecNotificationTitleBody').then(function (dataAux) {
           						if(secretaryNotificationConfiguration.id){
				            		RestService.update('./api/sec/sec_notification/' + secretaryNotificationConfiguration.id, secretaryNotificationConfiguration).then(function (data) {
				            			modalExpAux.close();
				            			if(secretaryNotificationConfiguration.relatedArchiveClassification && secretaryNotificationConfiguration.relatedArchiveClassification.id){
				        					 if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
				                             	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=ArchiveClassification')
				                             }
				                             GlobalDataFactory.loadGlobalData();
				            			}else if(secretaryNotificationConfiguration.relatedProcedure && secretaryNotificationConfiguration.relatedProcedure.id){
				            				if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
				                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
				                            }
				                            GlobalDataFactory.loadGlobalData();
				            			}
		                            }).catch(function (error) {
		                        		modalExpAux.alerts.push({ msg: "Error"});
		                            });
								}else{
				        			modalExpAux.close();
			                    }
			 				}).catch(function (data) {
				                    //Empty
				            });
						}else{
							if(secretaryNotificationConfiguration.id){
			            		RestService.update('./api/sec/sec_notification/' + secretaryNotificationConfiguration.id, secretaryNotificationConfiguration).then(function (data) {
			            			modalExpAux.close();
			            			if(secretaryNotificationConfiguration.relatedArchiveClassification && secretaryNotificationConfiguration.relatedArchiveClassification.id){
			        					 if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
			                             	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=ArchiveClassification')
			                             }
			                             GlobalDataFactory.loadGlobalData();
			            			}else if(secretaryNotificationConfiguration.relatedProcedure && secretaryNotificationConfiguration.relatedProcedure.id){
			            				if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
			                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
			                            }
			                            GlobalDataFactory.loadGlobalData();
			            			}
	                            }).catch(function (error) {
	                        		modalExpAux.alerts.push({ msg: "Error"});
	                            });
							}else{
			        			RestService.insert('./api/sec/sec_notification', secretaryNotificationConfiguration).then(function(data) {
			        				modalExpAux.close();
			        				if(secretaryNotificationConfiguration.relatedArchiveClassification && secretaryNotificationConfiguration.relatedArchiveClassification.id){
			        					 if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=ArchiveClassification')){
			                             	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=ArchiveClassification')
			                             }
			                             GlobalDataFactory.loadGlobalData();
			            			}else if(secretaryNotificationConfiguration.relatedProcedure && secretaryNotificationConfiguration.relatedProcedure.id){
			            				if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=Procedure&origin=globalData')){
			                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=Procedure&origin=globalData')
			                            }
			                            GlobalDataFactory.loadGlobalData();
			            			}
		                        }).catch(function(error) {
		                    		modalExpAux.alerts.push({ msg: "Error"});
		                        });
							}
						}
					}
				}
				var openSecConfigurePopup = function(modelToOpen, callOnSaveFunction){
					var modalExp = angular.copy(globalModals.secNotificationModal);
					modalExp.annexaFormly.options = {};
					if(modelToOpen){
						if(modelToOpen.urgent){
							modelToOpen.urgent = {true: true};
						}else{
							modelToOpen.urgent = {true: false};
						}
						modelToOpen.type = ((modelToOpen.type)?angular.copy($linq(GlobalDataFactory.secNotificationTypes).firstOrDefault({id:modelToOpen.type}, "x => x.id == '"+modelToOpen.type+"'")):undefined);
						modelToOpen.level = ((modelToOpen.level)?angular.copy($linq(GlobalDataFactory.secNotificationLevels).firstOrDefault({id:modelToOpen.level}, "x => x.id == '"+modelToOpen.level+"'")):undefined);
						modelToOpen.archiveClassification = {$selected:modelToOpen.archiveClassification};
						var docsField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'documents'");
						if(docsField && docsField.data){
							docsField.data.docs = ((modelToOpen && modelToOpen.docs)?modelToOpen.docs:[]);
							docsField.data.isNew = ((model)?true:((modelToOpen && modelToOpen.id)?false:true));
							docsField.data.secNotificationId=((modelToOpen)?modelToOpen.id:undefined),
							docsField.data.canEdit= true
						}
						var transField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'transactions'");
						if(transField && transField.data){
							transField.data.transactions = ((modelToOpen && modelToOpen.transactions)?modelToOpen.transactions:[]);
							transField.data.isNew = ((model)?true:((modelToOpen && modelToOpen.id)?false:true));
							transField.data.secNotificationId=((modelToOpen)?modelToOpen.id:undefined),
							transField.data.canEdit= true
						}
					}
					modalExp.annexaFormly.model = {modal_body:((modelToOpen)?modelToOpen:{})};
					if(notEditable){
						var closeFunction = function(){
							var modalExpAux = this;
							modalExpAux.close();
						}
						AnnexaFormlyFactory.showModal('modalSecNotificationModal', modalExp, closeFunction, false);
					}else{
						AnnexaFormlyFactory.showModal('modalSecNotificationModal', modalExp, callOnSaveFunction, false);
					}
				}
				//si model != null is new
				if(model){
					if(!model.secretaryNotificationConfiguration){
						model.secretaryNotificationConfiguration = {addTram:((addTram)?true:false)};
					}else{
						model.secretaryNotificationConfiguration.addTram = ((addTram)?true:false);
					}
					if(type === 'Proposal'){
						model.secretaryNotificationConfiguration.typeNotEditable = true;
					}
					if(notEditable){
						model.secretaryNotificationConfiguration.notEditable = true;
					}
					openSecConfigurePopup(angular.copy(model.secretaryNotificationConfiguration), saveIfNew);
				}else if(type && id ){
					$http({method: 'GET',url: './api/sec/sec_notification/'+type+'/'+id}).success(function(data, status) {
						var dataAux = ((data)?JSOG.decode(data):{});
						if(!data){
							if(type === 'QDC'){
								dataAux.relatedArchiveClassification = {id:id};
							}else if(type === 'Procedure'){
								dataAux.relatedProcedure = {id:id};
							}else if(type === 'Proposal'){
								dataAux.relatedProposal = {id:id};
							}else if(type === 'ProcedureProposalType'){
								dataAux.relatedProcedureProposalType = {id:id};
							}
						}
						if(dataAux && dataAux.archiveClassification && dataAux.archiveClassification.id){
							dataAux.archiveClassification.title = dataAux.archiveClassification[Language.getActiveColumn()];
						}
						dataAux.addTram = ((addTram)?true:false);
						if(type === 'Proposal'){
							dataAux.typeNotEditable = true;
						}
						if(notEditable){
							dataAux.notEditable = true;
						}
						openSecConfigurePopup(dataAux, saveIfNotNew);
		            }).error(function(msg,code) {
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
			        });
				}else{
					DialogsFactory.error($filter('translate')('global.sec.literals.errormodel'));
				}
			}
			factory.seeInfoNotificationPublish = function(workWithDossierSons, proposalId, dossierId) {
				var promises = [];
                if(workWithDossierSons) {
                    promises.push(SecFactory.getDossierThirdsAndSons([proposalId], 'SON'));
                }else {
                    promises.push(factory.getDossierThirds(dossierId));
                }
                promises.push(factory.getDossierTransactionDocuments(dossierId));
				promises.push($http({method: 'GET',url: './api/sec/proposal/getProposalNotificationThirdConfiguration/'+proposalId}));
				var deferrend = $q.defer();
				$q.all(promises).then(function(data) {
					deferrend.resolve(JSOG.decode(data));
	            }).catch(function(error) {
	            	deferrend.reject(error);
	            });
	            return deferrend.promise;
			}
			factory.canAddedSecConfiguration = function(model, type, id, addTram){
				var deferrend = $q.defer();
				$http({method: 'GET',url: './api/sec/sec_notification/canAddedSecConfiguration/'+id}).success(function(data, status) {
				    deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
				    deferrend.reject(msg);
		        });
	            return deferrend.promise;
			}
			factory.configureTransactionTemplates = function(procedure, transaction){
				var closeFunction = function(){
					var modalExpAux = this;
					modalExpAux.close();
				}
				var openModalCreateTransactionTemplate = function(actualTemplates){
					var modal = angular.copy(CommonAdminModals.modalAddCreateTransactionTemplateBox);
					modal.annexaFormly.options = {};
					modal.annexaFormly.model = {modal_body:{}};
					var tramTemplateField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'tramTemplate'");
					if(tramTemplateField) {
						var tram_templates = angular.copy(factory.TramTemplates);
						_.forEach(tram_templates, function (value, key) {
			                if(procedure && procedure.id && $linq(value.procedures).contains(procedure, "(x, y) => x.id == y.id")) {
			                	value.literal = '<strong>' + value[Language.getActiveColumn()] + '</strong>';
			                } else {
			                	value.literal = value[Language.getActiveColumn()];
			                }
			            });
						tramTemplateField.data.templatesOptions = tram_templates;
						tramTemplateField.data.isNew = true;
						tramTemplateField.data.templates = actualTemplates;
					}
					AnnexaFormlyFactory.showModal('modalTransactionTemplates', modal, closeFunction, false);					
				}
				if(transaction){
					factory.getTramTemplates(-1, ((procedure && procedure.id)?procedure.id:-1)).then(function (data) {
						openModalCreateTransactionTemplate(transaction.transactionTemplates);
					});
				}
			}
			
			factory.filterListTransactionTypeByPreSpel = function(dossierId, dtId){
				var deferrend = $q.defer();
				$http({method: 'GET',url: './api/tram/new/dossier/filterListTransactionTypeByPreSpel/'+dossierId+'/'+dtId}).success(function(data, status) {
				    deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
				    deferrend.reject(msg);
		        });
	            return deferrend.promise;
			}
			
			factory.filterListTransactionTypeByPostSpel = function(dossierId, dtId){
				var deferrend = $q.defer();
				$http({method: 'GET',url: './api/tram/new/dossier/filterListTransactionTypeByPostSpel/'+dossierId+'/'+dtId}).success(function(data, status) {
				    deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
				    deferrend.reject(msg);
		        });
	            return deferrend.promise;
			}
			factory.editTramitationPhase = function (idDossierTransaction, idTramitationPhase) {
                var deferred = $q.defer();
                $http({
                    url: './api/tram/new/dossierTransaction/editTramitationPhase',
                    method: 'PUT',
                    params: {idDossierTransaction: idDossierTransaction, idTramitationPhase: idTramitationPhase}
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }
			factory.importThirds = function (idDossier, dossierThirds) {
                var deferred = $q.defer();
                $http({
                    url: './api/dossier_third/importThirds/' + idDossier,
                    method: 'PUT',
                    data:JSOG.encode(dossierThirds)
                }).then(function (data) {
                    deferred.resolve(data.data)
                }).catch(function (error) {
                    deferred.reject(error);
                })

                return deferred.promise;
            }
			factory.configureProcedureProposalTypes = function(model, procedureId, procedure){
				var saveIfNew = function(){
					var modalExpAux = this;
					if(modalExpAux && modalExpAux.annexaFormly && modalExpAux.annexaFormly.model && modalExpAux.annexaFormly.model.modal_body){
						model.procedureProposalTypes = angular.copy(modalExpAux.annexaFormly.model.modal_body);
					}
					modalExpAux.close();
				}
				var saveIfNotNew = function(){
					var modalExpAux = this;
					modalExpAux.close();
				}
				var openConfigureProcedureProposalTypePopup = function(modelToOpen, callOnSaveFunction){
					var modalExp = angular.copy(globalModals.procedureProposalTypesModal);
					modalExp.annexaFormly.options = {};
					if(modelToOpen){
						var procedureProposalTypesField = $linq(modalExp.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'procedureProposalTypes'");
						if(procedureProposalTypesField && procedureProposalTypesField.data){
							procedureProposalTypesField.data.procedureProposalTypes = ((modelToOpen)?modelToOpen:[]);
							procedureProposalTypesField.data.isNew = ((model)?true:false);
							procedureProposalTypesField.data.procedureId=((procedureId)?procedureId:undefined);
							procedureProposalTypesField.data.procedure=((procedure)?procedure:undefined);
						}
					}
					modalExp.annexaFormly.model = {modal_body:((modelToOpen)?modelToOpen:{})};
					AnnexaFormlyFactory.showModal('modalProcedureProposalTypesModal', modalExp, callOnSaveFunction, false);
				}
				//si model != null is new
				if(model){
					if(!model.procedureProposalTypes){
						model.procedureProposalTypes = [];
					}
					openConfigureProcedureProposalTypePopup(angular.copy(model.procedureProposalTypes), saveIfNew);
				}else if(procedureId){
					$http({method: 'GET',url: './api/tram/procedure_proposal_types/by_procedure/'+procedureId}).success(function(data, status) {
						var dataAux = ((data)?JSOG.decode(data):[]);
						openConfigureProcedureProposalTypePopup(dataAux, saveIfNotNew);
		            }).error(function(msg,code) {
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
			        });
				}else{
					DialogsFactory.error($filter('translate')('global.sec.literals.errormodel'));
				}
			}
			factory.getProcedureProposalTypes = function(procedureId){
				var deferrend = $q.defer();
	            $http({
	                method: 'GET',
	                url: './api/tram/procedure_proposal_types/by_procedure/select/' + procedureId
	            }).success(function(data, status) {
	                deferrend.resolve(JSOG.decode(data));
	            }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
			}
			factory.updateProcedureProposalTypeInProposal = function (proposalId, procedureProposalTypeId, dossierId) {
                var deferrend = $q.defer();
                $http({method: 'PUT',url: './api/tram/procedure_proposal_types/update/'+proposalId+'/'+procedureProposalTypeId}).success(function(data, status) {
               		deferrend.resolve('OK');
                }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
            };
			factory.isProcedureProposalTypeinspectionInterventionActive = function (dossierTransactionId) {
                var deferrend = $q.defer();
                $http({url: './api/tram/procedure_proposal_types/inspectionInterventionActive/'+dossierTransactionId, method: 'GET'}).success(function(data, status) {
               		deferrend.resolve(((data)?JSOG.decode(data):data));
                }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
            };
            
            factory.modalSendProposalSelectThirds = function (dossier, thirds, allDocuments, isEdit, proposal) {
            	var documentsToAddAux = [];
    			var documentsToAdd = [];
    			var thirdsToAdd = [];
            	var thirdsEnabled = undefined;
            	var documentTransaction = [];
            	var documentsIdSelected = {};
            	var validDocStatus = ["SIGNED","COMPLETE"];
            	var validRelationType = ["DOCUMENT","WORK_DOCUMENT"];
            	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_valid_document_status) {
            		validDocStatus = $rootScope.app.configuration.notification_valid_document_status.status;
            		validRelationType = $rootScope.app.configuration.notification_valid_document_status.relation_type;
            	}
            	if (dossier && dossier.dossierTransactions) {
            		if(allDocuments){
    					documentsToAdd = $linq(allDocuments).distinctBy("x => x.document.id").where(function(x){
            				if(_.contains(validDocStatus, x.document.docStatus) && _.contains(validRelationType, x.relationType) && x.document.type.includeNotification && !x.document.physical){
            					return true;
            				}else {
            					return false;
            				}
            			}).select("x => x.document").toArray();
    	        		_.forEach(dossier.dossierTransactions, function(dosTran){
    	        			var docs = $linq(documentsToAdd).intersect($linq(allDocuments).where("x => x.dossierTransaction && x.dossierTransaction.id == "+dosTran.id).select("x => x.document").toArray(), "(x, y) => x.id == y.id").toArray()
    	        			if(docs.length > 0) {
    	        				documentTransaction.push({
    	    						value: dosTran.id,
    	    						label: dosTran.language1,
    	    						documents: docs
    	    					})
    	        			}
    	        		});
    	        	}else{
    					documentsToAdd = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where(function(x){
            				if(_.contains(validDocStatus, x.document.docStatus) && _.contains(validRelationType, x.relationType) && x.document.type.includeNotification && !x.document.physical){
            					return true;
            				}else {
            					return false;
            				}
            			}).select("x => x.document").toArray();
    	        		_.forEach(dossier.dossierTransactions, function(dosTran){
    	        			var docs = $linq(documentsToAdd).intersect($linq(dosTran.documents).select("x => x.document").toArray(), "(x, y) => x.id == y.id").toArray();
    	        			if(docs.length > 0) {
    	        				documentTransaction.push({
    	    						value: dosTran.id,
    	    						label: dosTran.language1,
    	    						documents: docs
    	    					})
    	        			}
    	        		});
    				}
    				thirdsEnabled = {};
    				if(thirds){
    					_.forEach(thirds, function(third){
    						var docs = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
    						thirdsEnabled[third.id] = {enabled:isEdit, docs:docs};
    					});
    				}else if(dossier && dossier.thirds){
    					_.forEach(dossier.thirds, function(third){
    						var docs = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
    						thirdsEnabled[third.id] = {enabled:isEdit, docs:docs};
    					});
    				}
            	}
            	
            	var newModal = {
                    title: 'global.literals.infoNotificationPublish',
                    size: 'modal-lg',
                    fieldsDefinition: [],
                    dossierTransactionId: undefined,
                    documentTransaction: documentTransaction,
                	alerts: [],
                	model: {},
                    submitModal: function() {
                    }
                };
            	
            	if(proposal && proposal.proposalNotificationThirdConfiguration) {
            		_.forEach(proposal.proposalNotificationThirdConfiguration, function(pntc){
						documentsIdSelected[pntc.dossierThird.id] = pntc.proposalNotificationThirdDocumentConfiguration;
					});
            	}
            	
				if(thirds){
					_.forEach(thirds, function(third){
						if(third.notifiable) {
							third.selected = false;
							if(documentsIdSelected && documentsIdSelected[third.id]) {
								third.selected = true;
							}
							thirdsToAdd.push(third);
						}
					});
				}else if(dossier && dossier.thirds){
					_.forEach(dossier.thirds, function(third){
						if(third.notifiable) {
							third.selected = false;
							if(documentsIdSelected && documentsIdSelected[third.id]) {
								third.selected = true;
							}
							thirdsToAdd.push(third);
						}
					});
				}
				if(isEdit) {
					newModal.fieldsDefinition.push({type: 'field', id: 'isPublish', fieldType: 'radio_checkbox', data: [{value: true, label: $filter('translate')('global.literals.infoNotificationPublishIsPublish')}], colClass: 'col-sm-12', required: false, disabled: isEdit});
					newModal.fieldsDefinition.push({ type: 'field', id: 'publishSite', fieldType: 'select_multiple', colClass: 'daughter-label-strong', data: GlobalDataFactory.publishSite, label: 'global.literals.infoNotificationPublishPublishSite', disabled: isEdit,
						hideExpression: function($viewValue, $modelValue, scope) {
	                    	if(scope.model.isPublish_true) {
	                            return false;
	                        } else {
	                        	return true;
	                        }
	                    }
	            	});
				} else if(proposal && proposal.isPublish && proposal.proposalPublishSite && proposal.proposalPublishSite.length > 0) {
					var language = Language.getActiveColumn();
					var dataSites = [];
					_.forEach(proposal.proposalPublishSite, function(pps){
						dataSites.push({value: pps.id, label: pps.publishSite[language]});
						newModal.model['publishSitesDone_' + pps.id] = pps.published;
					});
					newModal.fieldsDefinition.push({type: 'field', id: 'publishSitesDone', fieldType: 'radio_checkbox', data: dataSites, label: 'global.publishSite.literals.publishToDo' , labelProp: 'label', colClass: 'col-sm-12', required: false,
                		controller: ['$scope', function($scope) {
                			_.forEach(proposal.proposalPublishSite, function(pps){
                				$scope.model['publishSitesDone_' + pps.id] = pps.published;
                			});
                        }]
					});
				}
				newModal.fieldsDefinition.push({ type: 'field', id: 'thirds', fieldType: 'component', componentType: 'annexa-select-thirds-documents', disabled: isEdit,
                    data: {
                        title: 'global.literals.receivers',
                        thirds: thirdsToAdd.length > 0 ? thirdsToAdd : [],
                        dossier: dossier,
						removeAddressesWithoutSelectedNotificationType: true,
                        registerEntry: undefined,
                        titleDocuments: 'global.publishSite.literals.attachedDocuments',
                        documents: documentsToAdd,
                        documentsIdSelected: documentsIdSelected,
						thirdsEnabled: thirdsEnabled,
						disableSelecctions: !isEdit
                    }    
                });

                return newModal;
            };
            

			factory.updateNotificationPublish = function (proposalId, proposalPublishSites) {
                var deferrend = $q.defer();
                $http({
                    url: './api/sec/sec_notification/updateNotificationPublish/' + proposalId,
                    method: 'PUT',
                    data: proposalPublishSites 
                }).success(function(data, status) {
               		deferrend.resolve('OK');
                }).error(function(msg,code) {
	                deferrend.reject(msg);
	            });
	            return deferrend.promise;
            };
            
		    return factory;
    }]);
